import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { memo, useEffect, useMemo } from 'react'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../graphql/generated/autogenerated'
import { useGetChallengesHomeData } from '../../../../hooks/useGetChallengesHomeData/useGetChallengesHomeData'
import { useCompanyChallengeStates } from '../../../../shared/hooks/useCompanyChallengeStates/useCompanyChallengeStates'
import { ReactNullValue } from '../../../../shared/utils/Nulls'
import ViewChallengeRecapCard from '../../../../shared/components/features/ChallengeCard/ViewChallengeRecapCard'

export type ViewChallengeRecapProps = {
    small?: boolean
    onActiveRecap?: (challenge: UnifiedChallenge) => void
}

const ViewChallengeRecap: React.FC<ViewChallengeRecapProps> = ({
    small,
    onActiveRecap
}) => {
    const { loading, activeChallenges } = useGetChallengesHomeData()
    const calculateChallengeStates = useCompanyChallengeStates()

    const firstChallenge = useMemo(
        () =>
            activeChallenges?.find((activeChallenge) => {
                const { challengeExpired, remainingDays } =
                    calculateChallengeStates(
                        activeChallenge?.challenge as UnifiedChallenge,
                        activeChallenge?.participation as UnifiedChallengeActivity[]
                    )

                return (
                    challengeExpired &&
                    remainingDays <= 0 &&
                    !activeChallenge?.participation?.[0]?.completedOn
                )
            })?.challenge,
        [activeChallenges, calculateChallengeStates]
    )

    useEffect(() => {
        if (firstChallenge) {
            onActiveRecap?.(firstChallenge)
        }
    }, [firstChallenge, onActiveRecap])

    const challengeV3Enabled = useIsFeatureEnabled('Challengev3', false, true, {
        challengeType: firstChallenge?.challenge_type,
        theme: firstChallenge?.theme
    })

    if (!challengeV3Enabled || !firstChallenge || loading) return ReactNullValue

    return <ViewChallengeRecapCard small={small} challenge={firstChallenge} />
}

export default memo(ViewChallengeRecap)
