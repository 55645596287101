import { useCallback } from 'react'

const focusableElementSelector =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

export type QueryFocusableElementResult = (
    container?: HTMLElement,
    positionInCollection?: number
) => HTMLElement | null

export function useQueryFocusableElement(): QueryFocusableElementResult {
    const queryFocusableElement = useCallback<QueryFocusableElementResult>(
        (container, positionInCollection) => {
            if (!container) {
                return null
            }

            const focusableElements = Array.from(
                container.querySelectorAll<HTMLElement>(
                    focusableElementSelector
                )
            )

            let index = 0
            if (positionInCollection) {
                if (positionInCollection >= 0) {
                    index = positionInCollection
                } else {
                    index =
                        focusableElements.length -
                        Math.abs(positionInCollection)
                }
            }

            return focusableElements[index]
        },
        []
    )

    return queryFocusableElement
}
