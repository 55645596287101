import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'

const useTimeline = (startDate?: Date, endDate?: Date): string => {
    const { locale } = useAppSelector((state) => state.settings)

    return useMemo(() => {
        if (!startDate || !endDate) return ''

        const formatDate = (date: Date, includeYear = false) => {
            const options: Intl.DateTimeFormatOptions = {
                month: 'short' as const,
                day: '2-digit' as const,
                ...(includeYear && { year: 'numeric' as const })
            }
            try {
                return new Date(date).toLocaleDateString(locale, options)
            } catch {
                return new Date(date).toLocaleDateString('en-US', options)
            }
        }

        try {
            return `${formatDate(startDate)} - ${formatDate(endDate, true)}`
        } catch (e) {
            return `${startDate.toISOString().split('T')[0]} - ${
                endDate.toISOString().split('T')[0]
            }`
        }
    }, [startDate, endDate, locale])
}

export default useTimeline
