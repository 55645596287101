import { Card, Skeleton, Stack } from '@mui/material'
import { memo } from 'react'

const ChallengeCardSkeleton: React.FC = () => {
    return (
        <Card>
            <Skeleton variant="rectangular" height={190} animation="wave" />
            <Stack my={2} px={2} width="100%">
                <Skeleton variant="text" width="80%" height={30} />
                <Skeleton variant="text" width="80%" height={30} />
            </Stack>
        </Card>
    )
}

export default memo(ChallengeCardSkeleton)
