import { Stack } from '@mui/material'
import React, { memo, useEffect } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import StatTitle from '../../../../../components/elements/Stats/StatTitle/StatTitle'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { useCompanyDailyGoalsProviderContext } from '../../../../../hooks/withCompanyDailyGoalsProvider'
import withCompanyDailyGoalsProvider from '../../../../../hooks/withCompanyDailyGoalsProvider/withCompanyDailyGoalsProvider'
import { useTotalDailyGoalsCompletedProviderContext } from '../../../../../hooks/withTotalDailyGoalsCompletedProvider/useTotalDailyGoalsCompletedProviderContext'
import withTotalDailyGoalsCompletedProvider from '../../../../../hooks/withTotalDailyGoalsCompletedProvider/withTotalDailyGoalsCompletedProvider'

export interface CompletedStatsProps extends JSX.IntrinsicAttributes {
    challenge: UnifiedChallenge
    isVertical?: boolean
}

const messages = defineMessages({
    totalGoalsCompleted: {
        defaultMessage: 'Goals Completed',
        description: 'Your total goals completed title'
    },
    totalPlants: {
        defaultMessage: 'Total plants',
        description: 'Total plants stat title (appears above the number)'
    }
})

const CompletedStats: React.FC<CompletedStatsProps> =
    withTotalDailyGoalsCompletedProvider(
        withCompanyDailyGoalsProvider(({ challenge, isVertical }) => {
            const { formatMessage, formatNumber } = useIntl()

            const {
                loading: dailyGoalsCompletedLoading,
                error: dailyGoalsCompletedError,
                totalGoalsCompleted,
                setChallengeId: setDailyGoalsChallengeId
            } = useTotalDailyGoalsCompletedProviderContext()
            const {
                loading: totalPlantsLoading,
                error: totalPlantsError,
                totalUserPlants,
                setChallengeId: setPlantsChallengeId
            } = useCompanyDailyGoalsProviderContext()

            useEffect(() => {
                if (challenge) {
                    setDailyGoalsChallengeId(challenge.id)
                    setPlantsChallengeId(challenge.id)
                }
            }, [challenge, setDailyGoalsChallengeId, setPlantsChallengeId])

            return (
                <Stack direction="row" gap={2}>
                    {!dailyGoalsCompletedError && (
                        <StatTitle
                            loading={dailyGoalsCompletedLoading}
                            stat={formatNumber(Number(totalGoalsCompleted))}
                            title={formatMessage(messages.totalGoalsCompleted)}
                        />
                    )}
                    {!totalPlantsError && (
                        <StatTitle
                            loading={totalPlantsLoading}
                            stat={formatNumber(totalUserPlants)}
                            title={formatMessage(messages.totalPlants)}
                        />
                    )}
                </Stack>
            )
        })
    )

export default memo(CompletedStats)
