import { DialogContent, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PostReaction } from '../../../../../graphql/generated/autogenerated'
import { getInitials } from '../../util/getInitials'
import SocialAvatar from '../SocialAvatar/SocialAvatar'

export type ReactionsListModalProps = {
    isOpen: boolean
    handleOnClose: () => void
    reactions: PostReaction[]
}

const ReactionsListModal: React.FC<ReactionsListModalProps> = ({
    isOpen,
    handleOnClose,
    reactions
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <LeafDialog
            open={isOpen}
            onClose={handleOnClose}
            fullScreen={isMobile}
            dialogTitle={
                <Stack direction="row" gap={2}>
                    <LeafIcon icon={'hands-clapping'} fontSize={'large'} />
                    <FormattedMessage
                        defaultMessage="{cheers, plural, one {# cheer} other {# cheers}}"
                        description="count of total cheers reactions in the list"
                        values={{ cheers: reactions?.length }}
                    />
                </Stack>
            }
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: theme.spacing(62),
                    minHeight: theme.spacing(51)
                }
            }}
        >
            <DialogContent>
                <Stack overflow="auto" mt={1}>
                    <Stack gap={1.5}>
                        {reactions?.map((reaction, index: number) => (
                            <Stack
                                key={index}
                                direction="row"
                                data-testid="reaction-item"
                                gap={2}
                            >
                                <SocialAvatar
                                    imageUrl={
                                        reaction?.profilePhotoUrl ?? undefined
                                    }
                                    initials={getInitials(
                                        String(reaction.userDisplayName)
                                    )}
                                />
                                <Stack direction="row" alignItems="center">
                                    <CoreTypography variant="body1">
                                        {reaction.userDisplayName}
                                    </CoreTypography>
                                </Stack>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </DialogContent>
        </LeafDialog>
    )
}

export default ReactionsListModal
