import {
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    closeModal: {
        defaultMessage: `Close popup`,
        description: 'close popup aria'
    }
})

export type CloseModalIconProps = {
    onClick: () => void
    ariaLabel?: string
}

const CloseModalIcon: React.FC<CloseModalIconProps> = ({
    onClick,
    ariaLabel
}) => {
    const intl = useIntl()
    const theme = useTheme()

    return (
        <IconButton
            sx={{
                position: 'absolute',
                right: theme.spacing(2),
                top: theme.spacing(2),
                zIndex: 2
            }}
            onClick={onClick}
            data-testid="close-modal-icon"
            aria-label={
                ariaLabel ? ariaLabel : intl.formatMessage(messages.closeModal)
            }
            variant="contained"
            color="secondary"
        >
            <LeafIcon icon={'xmark'} />
        </IconButton>
    )
}

export default React.memo(CloseModalIcon)
