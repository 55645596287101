import { createContext } from 'react'
import {
    ChallengeUserDailyGoal,
    UserPlantInfo
} from '../../graphql/generated/autogenerated'

export interface CompanyDailyGoalsValue {
    loading: boolean
    activityGoal?: number
    goals: ChallengeUserDailyGoal[]
    totalUserPlants: number
    plant?: UserPlantInfo
    setChallengeId: (challengeId: string) => void
    refetch: () => Promise<any>
    error: boolean
}

export const defaultContextValue: CompanyDailyGoalsValue = {
    loading: false,
    activityGoal: undefined,
    goals: [],
    totalUserPlants: 0,
    plant: undefined,
    setChallengeId: () => ({}),
    refetch: () => Promise.resolve(),
    error: false
}

const CompanyDailyGoalsContext = createContext(defaultContextValue)

export default CompanyDailyGoalsContext
