import { Collapse } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { PostReaction } from '../../../../../graphql/generated/autogenerated'
import ReactionsListModal from '../ReactionsListModal/ReactionsListModal'

export type ReactionCountButtonProps = {
    reactionCount: number
    currentReactions: PostReaction[]
    postId: string
    reactionName: string
    userId: string
    activityName: string
    onViewReactions?: (
        postId: string,
        userId: string,
        totalReactions: number
    ) => void
}

const messages = defineMessages({
    reactionCountButton: {
        defaultMessage: 'open reaction count list popup for {activityName}',
        description:
            'open list continuing the list of all users that have reacted to this post, e.g. open reaction count list popup for "Ben completed a challenge"'
    }
})

const ReactionCountButton: React.FC<ReactionCountButtonProps> = ({
    reactionCount,
    currentReactions,
    postId,
    reactionName,
    userId,
    activityName,
    onViewReactions
}) => {
    const { spacing } = useTheme()
    const intl = useIntl()
    const [showReactionsModal, setShowReactionsModal] = useState(false)

    return (
        <>
            <ReactionsListModal
                isOpen={showReactionsModal}
                handleOnClose={() => setShowReactionsModal(false)}
                reactions={currentReactions}
            />
            <Collapse in={reactionCount > 0} orientation="horizontal">
                <IconButton
                    variant={'text'}
                    color={'primary'}
                    sx={{
                        minWidth: spacing(5),
                        minHeight: spacing(5),
                        ml: spacing(0.5),
                        boxShadow: 'none'
                    }}
                    aria-label={intl.formatMessage(
                        messages.reactionCountButton,
                        {
                            activityName
                        }
                    )}
                    onClick={() => {
                        setShowReactionsModal(true)
                        onViewReactions?.(postId, userId, reactionCount)
                    }}
                    data-testid="view-reactions-button"
                >
                    <CoreTypography
                        customVariant="buttonNormal"
                        data-testid={`${reactionName}-reaction-count`}
                    >
                        {reactionCount <= 0 ? 1 : reactionCount}
                    </CoreTypography>
                </IconButton>
            </Collapse>
        </>
    )
}

export default memo(ReactionCountButton)
