import { Card, Skeleton, Stack, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { memo } from 'react'

const ChallengeCardSkeleton: React.FC = () => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    return (
        <Stack position="relative">
            <Card>
                <Stack
                    height={isMobile ? 158 : 313}
                    direction={isMobile ? 'row-reverse' : 'column'}
                >
                    <Skeleton
                        variant="rounded"
                        sx={{
                            minHeight: isMobile ? '100%' : 119,
                            ...(isMobile && {
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0
                            }),
                            ...(!isMobile && {
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0
                            }),
                            minWidth: isMobile ? 83 : 'auto'
                        }}
                    />
                    <Stack p={isMobile ? 1 : 2} height="100%" width="100%">
                        <Stack justifyContent="space-between" height="100%">
                            <Stack gap={1}>
                                <Stack>
                                    <Skeleton width="20%" height={20} />
                                    <Skeleton width="65%" height={24} />
                                </Stack>
                                <Stack>
                                    <Skeleton width="100%" height={20} />
                                    <Skeleton width="80%" height={20} />
                                </Stack>
                            </Stack>
                            <Stack direction="row" alignItems="center" gap={1}>
                                <Skeleton height={23} width={23} />
                                <Skeleton width={122} height={23} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    )
}

export default memo(ChallengeCardSkeleton)
