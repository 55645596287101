import { useContext } from 'react'
import ChallengeUserStepsProviderContext, {
    ChallengeUserStepsProviderValue
} from './challengeUserStepsContext'

export const useChallengeUserStepsProviderContext =
    (): ChallengeUserStepsProviderValue => {
        const context = useContext(ChallengeUserStepsProviderContext)

        if (context === undefined) {
            throw new Error(
                'useChallengeUserStepsContext must be used within a ChallengeUserStepsContext'
            )
        }

        return context
    }
