import React, { useEffect, useState } from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { getFeatureConfig } from '@thriveglobal/thrive-web-core'

import { CHALLENGES_ROUTES, FEATURE_FLAGS } from '../constants'
import Challenges from '../pages/Challenges'
import Challenge from '../pages/Challenge/Challenge'

/**
 * FeatureAccessWrapper component checks if the user has access to Challenges V2 feature.
 * If the user has access, it renders the provided component.
 * Otherwise, it redirects the user to the old challenges route.
 *
 * @param {Object} props - The component props.
 * @param {React.ComponentType} props.children - The children to render if the user has access.
 * @returns {JSX.Element} The rendered component or a redirect.
 */

const FeatureAccessWrapper = ({ children }: { children: React.ReactNode }) => {
    const [hasChallengesV2Access, setHasChallengesV2Access] = useState<
        boolean | null
    >(true)
    useEffect(() => {
        const fetchFeatureConfig = async () => {
            const access = await getFeatureConfig(
                FEATURE_FLAGS.HAS_CHALLENGES_V2_ACCESS,
                false,
                true
            )
            setHasChallengesV2Access(access as boolean)
        }

        fetchFeatureConfig()
    }, [])

    if (hasChallengesV2Access === null) {
        return null
    }

    return hasChallengesV2Access ? (
        <>{children}</>
    ) : (
        <Redirect to={CHALLENGES_ROUTES.OLD_CHALLENGES} />
    )
}

const ChallengesRoutes = () => {
    return (
        <FeatureAccessWrapper>
            <Switch>
                <ProtectedRoute
                    exact
                    path={CHALLENGES_ROUTES.MY_CHALLENGES}
                    component={Challenges}
                />
                <ProtectedRoute
                    exact
                    path={CHALLENGES_ROUTES.COMPLETED_CHALLENGES}
                    component={Challenges}
                />
                <ProtectedRoute
                    exact
                    path={CHALLENGES_ROUTES.DISCOVER_CHALLENGES}
                    component={Challenges}
                />
                <ProtectedRoute
                    exact
                    path={CHALLENGES_ROUTES.CHALLENGES}
                    component={Challenges}
                />
                <ProtectedRoute
                    exact
                    path={CHALLENGES_ROUTES.CHALLENGE}
                    component={Challenge}
                />
            </Switch>
        </FeatureAccessWrapper>
    )
}

export default ChallengesRoutes
