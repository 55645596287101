import { CardContent, CardMedia, Stack, useMediaQuery } from '@mui/material'
import { diffDays, useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafChip,
    LeafFixedMediaCard,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChallengeType } from '../../../'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../graphql/generated/autogenerated'
import { useGetCategory } from '../../../../hooks/useGetCategory/useGetCategory'
import { useNavigateToChallenge } from '../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import { COMPANY_CHALLENGE_REMAINS_ACTIVE } from '../../../../utils'
import { useCompanyChallengeStates } from '../../../hooks'
import ChallengeCardDetails from './ChallengeCardDetails/ChallengeCardDetails'
import ChallengeCardPastDetails from './ChallengeCardPastDetails/ChallengeCardPastDetails'
import CompanyChallengeCardDetails from './CompanyChallengeCardDetails/CompanyChallengeCardDetails'
import ViewChallengeRecapCard from './ViewChallengeRecapCard'

const messages = defineMessages({
    totalParticipants: {
        defaultMessage: `{totalParticipants, plural, one {# Participant} other {# Participants}}`,
        description: 'total number of participants in this challenge'
    },
    challengeCardAriaLabel: {
        defaultMessage: '{challengeName}',
        description: 'Label for button to navigate to challenge'
    },
    completedChallenge: {
        defaultMessage: 'completed challenge',
        description: 'Navigate to Completed Challenge Button'
    },
    currentChallenge: {
        defaultMessage: 'current challenge',
        description: 'Navigate to Active Challenge Button'
    },
    upcomingChallenge: {
        defaultMessage: 'upcoming Challenge',
        description: 'challenge info card sub title for the type of challenge'
    },
    companyChallenge: {
        defaultMessage: 'Company Challenge',
        description: 'label for company challenge'
    },
    featuredChallenge: {
        defaultMessage: 'Featured Challenge',
        description: 'label for featured challenge'
    },
    group: {
        defaultMessage: 'Group',
        description: 'label for group challenge'
    },
    biotype: {
        defaultMessage: 'Biotype',
        description: 'label for biotype challenge'
    },
    journey: {
        defaultMessage: 'Journey',
        description: 'label for journey challenge'
    }
})

export interface ChallengeCardProps {
    challenge: UnifiedChallenge
    participation?: UnifiedChallengeActivity
    hideDescription?: boolean
    isVertical?: boolean
    isPast?: boolean
    trackingEvent?: () => void
}

const ChallengeCard: React.FC<ChallengeCardProps> = ({
    challenge,
    participation,
    hideDescription,
    isVertical,
    isPast = false,
    trackingEvent
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const { formatMessage, formatDateTimeRange } = useIntl()
    const calculateChallengeStates = useCompanyChallengeStates()
    const imagePlaceholder =
        'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'
    const categoryName = useGetCategory(challenge)

    const challengeV3Enabled = useIsFeatureEnabled('Challengev3', false, true, {
        challengeType: challenge?.challenge_type,
        theme: challenge?.theme
    })

    const {
        isCompanyChallenge,
        isGroupChallenge,
        isFeatured,
        challengeJourney
    } = useMemo(() => {
        return {
            isCompanyChallenge:
                challenge?.challenge_type === ChallengeType.company,
            isGroupChallenge: challenge?.challenge_type === ChallengeType.group,
            isFeatured: challenge?.isFeatured,
            challengeJourney: categoryName
        }
    }, [challenge, categoryName])

    const isActive = useMemo(() => {
        return (
            (participation?.join && !participation?.completedOn) ||
            diffDays(new Date(), new Date(participation?.completedOn)) <
                COMPANY_CHALLENGE_REMAINS_ACTIVE
        )
    }, [participation])

    const clickable = useMemo(() => {
        return (!isCompanyChallenge && !isActive) || isPast
    }, [isCompanyChallenge, isActive, isPast])

    const { challengeStarted, challengeExpired } = useMemo(
        () => calculateChallengeStates(challenge),
        [calculateChallengeStates, challenge]
    )

    const { navigate } = useNavigateToChallenge({
        challengeId: challenge?.id,
        participationId: participation?.id,
        challengeName: challenge?.name,
        challengeType: challenge?.challenge_type as ChallengeType,
        isActive,
        isPast
    })

    const useVertical = useMemo(() => {
        return isVertical || isMobile
    }, [isVertical, isMobile])

    const logTrackingEvent = useCallback(() => {
        trackingEvent
            ? trackingEvent()
            : Avo.challengeSelected({
                  activityType: 'challenge_selected',
                  challengeId: challenge?.id,
                  challengeTheme: challenge?.theme,
                  challengeType: challenge?.challenge_type,
                  dayNumber: null,
                  featureType: 'challenge',
                  tabName: 'Home',
                  teamId: null,
                  teamType: null
              })
    }, [challenge, trackingEvent])

    const onNavigate = useCallback(() => {
        if (challenge) {
            logTrackingEvent()
            navigate()
        }
    }, [logTrackingEvent, challenge, navigate])

    const chipLabel = useMemo(() => {
        if (isPast) {
            return formatDateTimeRange(
                new Date(participation?.join),
                new Date(participation?.completedOn),
                {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }
            )
        }

        if (isCompanyChallenge && challengeExpired) {
            return formatMessage(messages.completedChallenge)
        } else if (isActive && isCompanyChallenge && !challengeStarted) {
            return formatMessage(messages.upcomingChallenge)
        } else if (isActive) {
            return formatMessage(messages.currentChallenge)
        } else if (isFeatured) {
            return formatMessage(messages.featuredChallenge)
        } else if (isCompanyChallenge) {
            return formatMessage(messages.companyChallenge)
        } else if (challengeJourney) {
            return challengeJourney
        }
    }, [
        challengeJourney,
        challengeExpired,
        challengeStarted,
        isCompanyChallenge,
        formatDateTimeRange,
        formatMessage,
        participation,
        isActive,
        isPast,
        isFeatured
    ])

    const challengeType = useMemo(() => {
        switch (challenge?.challenge_type) {
            case ChallengeType.company:
                return formatMessage(messages.companyChallenge)
            default:
                switch (challenge?.challenge_type) {
                    case ChallengeType.group:
                        return formatMessage(messages.group)
                    case ChallengeType.biotype:
                        return formatMessage(messages.biotype)
                    case ChallengeType.journey:
                        return formatMessage(messages.journey)
                }
        }
    }, [challenge?.challenge_type, formatMessage])

    const showDescription = useMemo(() => {
        if ((isCompanyChallenge && isVertical) || isPast || hideDescription)
            return
        return challenge?.description
    }, [
        isCompanyChallenge,
        isVertical,
        isPast,
        challenge?.description,
        hideDescription
    ])

    const challengeDetails = useMemo(() => {
        if (isPast) {
            return (
                <ChallengeCardPastDetails
                    participation={participation}
                    challenge={challenge}
                    isCompanyChallenge={isCompanyChallenge}
                    isGroupChallenge={isGroupChallenge}
                />
            )
        } else if (isCompanyChallenge || isGroupChallenge) {
            return (
                <CompanyChallengeCardDetails
                    challenge={challenge}
                    isVertical={useVertical}
                    isActive={isActive}
                />
            )
        } else {
            return (
                <ChallengeCardDetails
                    challenge={challenge}
                    participation={participation}
                    isActive={isActive}
                    isTemplate={
                        challenge?.challenge_type === ChallengeType.group
                    }
                />
            )
        }
    }, [
        isPast,
        challenge,
        participation,
        isGroupChallenge,
        isCompanyChallenge,
        useVertical,
        isActive
    ])

    const recapEnabledChallenge = useMemo(
        () => challengeV3Enabled && challengeExpired,
        [challengeV3Enabled, challengeExpired]
    )

    return recapEnabledChallenge ? (
        <ViewChallengeRecapCard small={true} challenge={challenge} />
    ) : (
        <LeafFixedMediaCard
            media={
                <CardMedia
                    component="img"
                    image={
                        challenge?.header ? challenge?.header : imagePlaceholder
                    }
                    alt=""
                />
            }
            alignMedia={useVertical ? 'top' : 'left'}
            mediaWidth={'lg'}
            elevation={1}
            sx={{ height: '100%', position: 'relative' }}
            {...(clickable && {
                actionAreaProps: {
                    onClick: () => onNavigate(),
                    'aria-label': formatMessage(
                        messages.challengeCardAriaLabel,
                        {
                            challengeName: challenge?.name
                        }
                    )
                }
            })}
            data-testid="challenge-card"
        >
            <CardContent
                sx={{
                    p: useVertical ? 2 : 3,
                    pb: isCompanyChallenge ? 2 : 3
                }}
            >
                {chipLabel && (
                    <LeafChip
                        sx={{ position: 'absolute', top: 16, left: 16 }}
                        color="secondary"
                        label={chipLabel}
                        variant="filled"
                    />
                )}
                <Stack gap={2} height="100%">
                    <Stack gap={1} textAlign="left">
                        <CoreTypography variant="overline" color="accent.main">
                            {challengeType}
                        </CoreTypography>
                        <CoreTypography variant={useVertical ? 'h4' : 'h2'}>
                            {challenge?.name}
                        </CoreTypography>
                        {showDescription && (
                            <Stack>
                                <CoreTypography
                                    variant="body1"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    display="-webkit-box"
                                    sx={{
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 3
                                    }}
                                >
                                    {challenge?.description}
                                </CoreTypography>
                            </Stack>
                        )}
                    </Stack>
                    {challengeDetails}
                </Stack>
            </CardContent>
        </LeafFixedMediaCard>
    )
}

export default ChallengeCard
