import { Box, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useCompleteChallengeMutation } from '../../../../graphql/generated/autogenerated'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { ConfettiExplosion } from '@thriveglobal/thrive-web-leafkit'
import CelebrationScreen from '../CelebrationScreen/CelebrationScreen'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const messages = defineMessages({
    youDidIt: {
        defaultMessage: 'You did it! You’ve completed this Challenge.',
        description: 'Description on completing a challenge'
    },
    anotherStep: {
        defaultMessage:
            'Take another step forward by starting a new Challenge.',
        description:
            'Description on completing a challenge to encourage a user to start another'
    },
    closeCompleteAria: {
        defaultMessage: 'Close complete challenge dialog',
        description: 'aria label for a button that will close the dialog'
    },
    congratulations: {
        defaultMessage: 'Congratulations',
        description: 'title for the complete challenge dialog'
    },
    completeChallenge: {
        defaultMessage: 'Complete challenge',
        description: 'button text to confirm a complete challenge'
    }
})

export type CompleteChallengeModalProps = {
    open: boolean
    challengeId: string
    userChallengeActivityId: string
    onClose: () => void
}

const CompleteChallengeModal: React.FC<CompleteChallengeModalProps> = ({
    open,
    challengeId,
    userChallengeActivityId,
    onClose
}) => {
    const navigation = useCrossAppNavigation()
    const intl = useIntl()
    const [showConfetti, setShowConfetti] = useState(true)

    const [completeChallengeMutation] = useCompleteChallengeMutation({
        variables: {
            userChallengeActivityId: userChallengeActivityId
        }
    })

    return (
        <Dialog
            open={open}
            onClose={onClose}
            data-testid="complete-challenge-modal"
        >
            <CelebrationScreen
                title={intl.formatMessage(messages.congratulations)}
                description={`${intl.formatMessage(
                    messages.youDidIt
                )}\n${intl.formatMessage(messages.anotherStep)}`}
                primaryActionText={intl.formatMessage(
                    messages.completeChallenge
                )}
                onPrimaryAction={() =>
                    completeChallengeMutation().then(() => {
                        Avo.submissionCompleted({
                            activityType: 'story_submission_completed',
                            challengeId: challengeId,
                            challengeTheme: null,
                            challengeType: null,
                            contentFormatType: null,
                            contentType: null,
                            contentUrl: null,
                            contentId: userChallengeActivityId,
                            contentSource: null,
                            contentSubtype: null,
                            contentTitle: 'content_title',
                            dayNumber: null,
                            featureType: 'challenge',
                            teamId: null,
                            teamType: null
                        })
                        navigation(
                            `/challenges/past/${challengeId}/${userChallengeActivityId}/storySubmission`
                        )
                    })
                }
                handleOnClose={onClose}
                celebration={
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '50%'
                        }}
                    >
                        <ConfettiExplosion
                            show={showConfetti}
                            onComplete={() => setShowConfetti(false)}
                        />
                    </Box>
                }
            />
        </Dialog>
    )
}

export default CompleteChallengeModal
