import { Link, Stack, TypographyProps } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    stat: {
        defaultMessage:
            '<stat>{currentProgress}</stat><statGoal>/{goal}</statGoal>',
        description:
            'description of the progress met towards a goal e.g 1000/4000'
    }
})
interface StatFractionProps
    extends TypographyProps<
        React.ElementType,
        { component?: React.ElementType }
    > {
    stat: number
    activityGoal: number
    color?: string
    completedColor?: string
    onStatClicked?: () => void
}

const StatFraction: React.FC<StatFractionProps> = ({
    stat,
    activityGoal = 0,
    color,
    completedColor,
    onStatClicked,
    variant,
    ...props
}) => {
    const theme = useTheme()
    const { formatMessage, formatNumber } = useIntl()

    const { activityCompleted, activityColor } = useMemo(() => {
        const activityCompleted = stat >= activityGoal
        return {
            activityCompleted,
            activityColor: activityCompleted
                ? completedColor ?? theme.palette.accent.main
                : color ?? theme.palette.text.disabled
        }
    }, [stat, activityGoal, completedColor, color, theme])

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" {...props}>
            {formatMessage(messages.stat, {
                currentProgress: formatNumber(stat),
                goal: formatNumber(activityGoal),
                stat: (chunks: React.ReactNode[]) => (
                    <CoreTypography
                        customVariant={variant ? undefined : 'display3'}
                        variant={variant}
                        color="text.primary"
                        data-testid="stat-action"
                        onClick={onStatClicked}
                        as={onStatClicked && Link}
                        sx={{ cursor: onStatClicked && 'pointer' }}
                    >
                        {chunks}
                    </CoreTypography>
                ),
                statGoal: (chunks: React.ReactNode[]) => (
                    <CoreTypography
                        color={activityColor}
                        customVariant={variant ? undefined : 'display3'}
                        variant={variant}
                    >
                        {chunks}
                    </CoreTypography>
                )
            })}
            {activityCompleted && <LeafIcon icon={'check'} color={'accent'} />}
        </Stack>
    )
}

export default memo(StatFraction)
