import { Box, Button, Card, Grid, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'

const bannerImageUrl =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/730c4919-3928-46d1-f500-333e9de45700/cms'

export interface ChallengeBannerProps {
    onCtaClick?: () => void
}

const ChallengeBanner: React.FC<ChallengeBannerProps> = ({ onCtaClick }) => {
    const { breakpoints, palette, ...theme } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    return (
        <Card elevation={0} sx={{ background: theme.gradient.main }}>
            <Stack>
                <Grid container spacing={0} height="100%">
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Stack
                            height={isMobile ? 105 : '100%'}
                            width="100%"
                            sx={{
                                backgroundImage: `url(${bannerImageUrl})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}
                        ></Stack>
                    </Grid>
                    <Grid item xs={isMobile ? 12 : 6}>
                        <Stack
                            py={isMobile ? 2 : 2.625}
                            px={isMobile ? 2 : 3.625}
                            maxWidth={466}
                            gap={2}
                        >
                            <Stack gap={1}>
                                <CoreTypography
                                    variant={isMobile ? 'h4' : 'h3'}
                                >
                                    <FormattedMessage
                                        defaultMessage="Make changes and grow with Thrive Challenges"
                                        description="Challenges home page information text"
                                    />
                                </CoreTypography>
                                <CoreTypography variant="body1">
                                    <FormattedMessage
                                        defaultMessage="Take on Challenges for yourself or with coworkers. Each day you’ll get science-backed Microsteps, make changes with actionable tips, and earn exclusive plants and rewards for your Thrive garden."
                                        description="description"
                                    />
                                </CoreTypography>
                            </Stack>
                            <Box>
                                <Button
                                    variant="outlined"
                                    onClick={onCtaClick}
                                    sx={{ bgcolor: palette.background.paper }}
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        <FormattedMessage
                                            defaultMessage="Start a Challenge"
                                            description="description"
                                        />
                                    </CoreTypography>
                                </Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeBanner)
