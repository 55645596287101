import { Box, Card, CardMedia, Stack } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafChip,
    LeafIcon,
    withTruncatedText
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import useNavigateToChallenge from '../../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import useTextTransform from '../../../../../hooks/useTextTransform/useTextTransform'
import CompletedStats from '../../../../../shared/components/features/ChallengeCard/ChallengeCardStats/CompletedStats'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import ChallengeTypeHeader from '../../../../elements/ChallengeTypeHeader/ChallengeTypeHeader'
import { StatTitle } from '../../../../elements/Stats'

const imagePlaceholder =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

const messages = defineMessages({
    greatJob: {
        defaultMessage:
            'Great work! Be proud of all you accomplished in this challenge.',
        description: 'description for when challenge is finished'
    },
    writeStory: {
        defaultMessage: 'Write your story',
        description: 'description for write story button'
    },
    navigateAriaLabel: {
        defaultMessage: 'Navigate to challenge',
        description: 'description for navigate to challenge button'
    },
    totalGoalsCompleted: {
        defaultMessage: 'Goals Completed',
        description: 'Your total goals completed title'
    },
    totalPlants: {
        defaultMessage: 'Total plants',
        description: 'Total plants stat title (appears above the number)'
    },
    totalActive: {
        defaultMessage: 'total active days',
        description:
            'description text for the total number of days the user has been active in a challenge, the number is shown above the text'
    },
    totalCheckIns: {
        defaultMessage: 'total check-ins',
        description:
            'description text for the total number of microstep check-ins the user has completed in a challenge, the number is shown above the text'
    }
})

const TruncatedCoreTypography = withTruncatedText(CoreTypography)
export interface ChallengePastCardProps {
    id: string
    participationId?: string
    name: string
    image: string
    category?: string
    challengeType: ChallengeType
    dateRange?: string
    totalActiveDays?: number
    totalCheckIns?: number
}

const ChallengePastCard: React.FC<ChallengePastCardProps> = ({
    id,
    participationId,
    name,
    image,
    category,
    challengeType,
    dateRange,
    totalActiveDays,
    totalCheckIns
}) => {
    const { navigate } = useNavigateToChallenge({
        challengeId: id,
        challengeName: name,
        participationId,
        challengeType,
        isActive: true,
        isPast: true
    })
    const { formatMessage, formatNumber } = useIntl()
    const { toTitleCase } = useTextTransform()

    return (
        <Card>
            <Stack height={400}>
                <ChallengeTypeHeader challengeType={challengeType} />
                <Stack position="relative">
                    <Stack position="absolute" bottom={10} left={10}>
                        <LeafChip
                            color="secondary"
                            label={dateRange}
                            variant="filled"
                        />
                    </Stack>
                    <CardMedia
                        component="img"
                        height={150}
                        image={image ? image : imagePlaceholder}
                        alt=""
                    />
                </Stack>
                <Stack p={2} gap={1} flex={1} justifyContent="space-between">
                    <Stack gap={1}>
                        <Stack>
                            {category && (
                                <CoreTypography variant="body2">
                                    {toTitleCase(category)}
                                </CoreTypography>
                            )}
                            <TruncatedCoreTypography
                                variant="h4"
                                textLines={2}
                                color="text.secondary"
                            >
                                {name}
                            </TruncatedCoreTypography>
                        </Stack>
                    </Stack>
                    <Stack gap={0.5}>
                        {challengeType === ChallengeType.company ||
                        challengeType === ChallengeType.group ? (
                            <CompletedStats
                                challenge={{ id: id } as UnifiedChallenge}
                            />
                        ) : (
                            <Stack direction="row" gap={2}>
                                <StatTitle
                                    loading={false}
                                    stat={formatNumber(Number(totalActiveDays))}
                                    title={formatMessage(messages.totalActive)}
                                />
                                <StatTitle
                                    loading={false}
                                    stat={formatNumber(Number(totalCheckIns))}
                                    title={formatMessage(
                                        messages.totalCheckIns
                                    )}
                                />
                            </Stack>
                        )}
                        <Stack alignItems="flex-end">
                            <Box>
                                <IconButton
                                    onClick={navigate}
                                    variant="contained"
                                    color="secondary"
                                    aria-label={formatMessage(
                                        messages.navigateAriaLabel
                                    )}
                                >
                                    <LeafIcon
                                        fontSize="medium"
                                        icon="arrow-right"
                                    />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengePastCard)
