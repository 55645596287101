import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    GetIntentionQuery,
    useGetIntentionQuery
} from '../../graphql/generated/autogenerated'
import { GQLNull } from '../../shared/utils/Nulls'

export interface ChallengeRecommendationData {
    intention: string | GQLNull
    loading: boolean
    error: ApolloError | undefined
    refetch: () => Promise<Apollo.ApolloQueryResult<GetIntentionQuery>>
}

export const useGetIntention: () => ChallengeRecommendationData = () => {
    const { data, loading, error, refetch } = useGetIntentionQuery()

    const intention = useMemo(
        () => data?.dailyExperience?.goals?.fetchIntention?.intention,
        [data]
    )

    return useMemo<ChallengeRecommendationData>(
        () => ({
            intention,
            loading,
            error,
            refetch
        }),
        [intention, loading, error, refetch]
    )
}

export default useGetIntention
