import { Stack } from '@mui/material'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import IconStat from '../../../../../components/elements/Stats/IconStat/IconStat'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeStates } from '../../../../hooks/useCompanyChallengeStates/useCompanyChallengeStates'
import useTimeline from '../../../../../hooks/useTimeline/useTimeline'

export type SignUpStatsProps = {
    challenge: UnifiedChallenge
}

const messages = defineMessages({
    totalParticipants: {
        defaultMessage: `{totalParticipants, plural, one {# Participant} other {# Participants}}`,
        description: 'total number of participants in this challenge'
    },
    challengeLength: {
        defaultMessage: `{challengeLength, plural, one {# day} other {# days}}`,
        description: 'total number of days in the challenge'
    },
    featured: {
        defaultMessage: `Featured Challenge`,
        description:
            'description of the current challenge card shown on the challenges home page'
    }
})

const SignUpStats: React.FC<SignUpStatsProps> = ({ challenge }) => {
    const { formatMessage, formatDate } = useIntl()
    const calculateChallengeStates = useCompanyChallengeStates()

    const { startDate, endDate } = useMemo(
        () => calculateChallengeStates(challenge),
        [calculateChallengeStates, challenge]
    )

    const timeline = useTimeline(startDate, endDate)

    return (
        <Stack gap={1}>
            <IconStat stat={timeline} icon={'calendar'} />
            <IconStat
                stat={formatMessage(messages.totalParticipants, {
                    totalParticipants: challenge.totalParticipants
                })}
                icon={'user'}
            />
        </Stack>
    )
}

export default memo(SignUpStats)
