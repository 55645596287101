import { useContext } from 'react'
import SocialActivityProviderContext, {
    SocialActivityProviderValue
} from './socialActivityContext'

export const useSocialActivityContext = (): SocialActivityProviderValue => {
    const context = useContext(SocialActivityProviderContext)

    if (context === undefined) {
        throw new Error(
            'useSocialActivityContext must be used within a SocialActivityContext'
        )
    }

    return context
}
