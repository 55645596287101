import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import parseISO from 'date-fns/parseISO'
import { useEffect, useMemo, useState } from 'react'
import {
    GetSpotlightChallengesQuery,
    UnifiedChallengeParticipation,
    UnifiedChallengesQuery,
    useGetSpotlightChallengesQuery
} from '../../graphql/generated/autogenerated'
import { selectChallengeSpotlight } from '../../shared/components/features/ChallengeSpotlight/util'
import useCurrentDateTime from '../useCurrentDateTime/useCurrentDateTime'

type SpotlightChallengeData = {
    spotlightChallenge: UnifiedChallengeParticipation | undefined
    error: ApolloError | undefined
    loading: boolean
    refetch: () => Promise<
        Apollo.ApolloQueryResult<GetSpotlightChallengesQuery>
    >
}

export const useGetSpotlightChallenge = () => {
    const { data, loading, error, refetch } = useGetSpotlightChallengesQuery()
    const currentDateTime = parseISO(useCurrentDateTime().toISOString())
    const [spotlightChallenge, setSpotlightChallenge] =
        useState<UnifiedChallengeParticipation>()

    useEffect(() => {
        if (!loading && !error && data && !spotlightChallenge) {
            setSpotlightChallenge(
                selectChallengeSpotlight(
                    data?.unifiedChallenges as UnifiedChallengesQuery,
                    currentDateTime
                )
            )
        }
    }, [data, loading, error, spotlightChallenge, currentDateTime])

    return useMemo<SpotlightChallengeData>(() => {
        return {
            spotlightChallenge,
            error,
            loading,
            refetch
        }
    }, [spotlightChallenge, error, loading, refetch])
}

export default useGetSpotlightChallenge
