import {
    Button,
    DialogActions,
    DialogContent,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import CreateDisplayName from './CreateDisplayName/CreateDisplayName'

export type CreateDisplayNameModalProps = {
    open: boolean
    handleOnClose: () => void
    onCreateDisplayName: (displayName: string) => void
}

const CreateDisplayNameModal: React.FC<CreateDisplayNameModalProps> = ({
    open,
    handleOnClose,
    onCreateDisplayName
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [loading, setLoading] = React.useState<boolean>(false)
    const [formValid, setFormValid] = React.useState<boolean>(true)
    const [submit, setSubmit] = React.useState<boolean>(false)
    return (
        <LeafDialog
            open={open}
            onClose={handleOnClose}
            dialogTitle={
                <FormattedMessage
                    defaultMessage="Join the Community"
                    description="title of the create display name popup"
                />
            }
        >
            <DialogContent>
                <CreateDisplayName
                    onCreateDisplayName={(displayName) => {
                        onCreateDisplayName(displayName)
                        handleOnClose()
                    }}
                    onFormValid={setFormValid}
                    onLoading={setLoading}
                    submit={submit}
                />
            </DialogContent>
            <DialogActions>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="flex-end"
                    gap={isMobile ? 1 : 3}
                    width="100%"
                    mt={1}
                >
                    <Button
                        variant="contained"
                        size={'medium'}
                        fullWidth={isMobile}
                        color="secondary"
                        onClick={handleOnClose}
                    >
                        <CoreTypography customVariant={'buttonNormal'}>
                            <FormattedMessage
                                defaultMessage="Nevermind"
                                description="button text for closing the display name modal"
                            />
                        </CoreTypography>
                    </Button>
                    <LoadingButton
                        loading={loading}
                        disabled={loading || !formValid}
                        fixWidth={true}
                        size={'large'}
                        fullWidth={isMobile}
                        variant="contained"
                        data-testid="submit-displayName-button"
                        onClick={() => setSubmit(true)}
                    >
                        <CoreTypography customVariant="buttonLarge">
                            <FormattedMessage
                                defaultMessage="Start sharing"
                                description="button text for submit display Name"
                            />
                        </CoreTypography>
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}

export default CreateDisplayNameModal
