import { Stack } from '@mui/material'
import React, { memo, useEffect, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import StatTitle from '../../../../../components/elements/Stats/StatTitle/StatTitle'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { useChallengeUserStepsProviderContext } from '../../../../../hooks/activityProviders/withChallengeUserStepsProvider'
import { useCompanyDailyGoalsProviderContext } from '../../../../../hooks/withCompanyDailyGoalsProvider'
import withChallengeUserStepsProvider from '../../../../../hooks/activityProviders/withChallengeUserStepsProvider/withChallengeUserStepsProvider'
import withCompanyDailyGoalsProvider from '../../../../../hooks/withCompanyDailyGoalsProvider/withCompanyDailyGoalsProvider'
import useChallengeTheme from '../../../../../hooks/useChallengeTheme/useChallengeTheme'

export interface StartedStatsProps extends JSX.IntrinsicAttributes {
    challenge: UnifiedChallenge
    isVertical?: boolean
}

const messages = defineMessages({
    todayActivity: {
        defaultMessage: 'today’s activity',
        description: 'today’s activity stat title (appears above the number)'
    },
    dailyGoals: {
        defaultMessage: 'Completed Daily goals',
        description:
            'Daily goals completed stat title (appears above the number)'
    },
    dailyGoalsCompleted: {
        defaultMessage: '{dailyGoalsCompleted} of {totalDailyGoals}',
        description: 'total daily goals completed'
    }
})

const StartedStats: React.FC<StartedStatsProps> =
    withChallengeUserStepsProvider(
        withCompanyDailyGoalsProvider(({ challenge, isVertical }) => {
            const { formatMessage, formatNumber } = useIntl()
            const { deviceEnabled } = useChallengeTheme(challenge)

            const {
                loading: userStepsLoading,
                error: userStepsError,
                todaySteps,
                setChallenge
            } = useChallengeUserStepsProviderContext()

            const {
                goals,
                loading: goalsLoading,
                error: goalsError,
                setChallengeId: setDailyGoalsChallengeId
            } = useCompanyDailyGoalsProviderContext()

            const { dailyGoalsCompleted, totalDailyGoals } = useMemo(
                () => ({
                    dailyGoalsCompleted: goals?.filter((goal) => goal.completed)
                        ?.length,
                    totalDailyGoals: goals?.length
                }),
                [goals]
            )

            useEffect(() => {
                if (challenge) {
                    setChallenge?.(challenge)
                    setDailyGoalsChallengeId(challenge.id)
                }
            }, [challenge, setChallenge, setDailyGoalsChallengeId])

            return (
                <Stack direction="row" gap={2}>
                    {!userStepsError && deviceEnabled && (
                        <StatTitle
                            loading={userStepsLoading}
                            stat={formatNumber(todaySteps)}
                            title={formatMessage(messages.todayActivity)}
                        />
                    )}
                    {!goalsError && (
                        <StatTitle
                            loading={goalsLoading}
                            stat={formatMessage(messages.dailyGoalsCompleted, {
                                dailyGoalsCompleted,
                                totalDailyGoals
                            })}
                            title={formatMessage(messages.dailyGoals)}
                        />
                    )}
                </Stack>
            )
        })
    )

export default memo(StartedStats)
