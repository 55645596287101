import { Apollo } from '@thriveglobal/thrive-web-core'
import { ComponentType } from 'react'
import { useGetCompanyChallengeSocialGroupQuery } from '../../graphql/generated/autogenerated'
import CompanyChallengeContext from './companyChallengeContext'
import useCompanyChallengeProviderData from './useCompanyChallengeProviderData'

export default function withCompanyChallengeProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const state = useCompanyChallengeProviderData(
            useGetCompanyChallengeSocialGroupQuery as unknown as (
                variables: Apollo.QueryHookOptions
            ) => Apollo.QueryResult<any, any>,
            (unifiedChallenges) => unifiedChallenges?.communityUserChallenges
        )

        return (
            <CompanyChallengeContext.Provider value={state}>
                <Component {...props} />
            </CompanyChallengeContext.Provider>
        )
    }
}
