import { Skeleton, Stack } from '@mui/material'
import { memo } from 'react'

const ChallengeFilterSkeleton: React.FC = () => {
    return (
        <Stack direction="row" alignItems="center" gap={1.5}>
            <Skeleton height={21.93} width={112.52} />
            <Skeleton variant="rounded" height={36} width={110} />
        </Stack>
    )
}

export default memo(ChallengeFilterSkeleton)
