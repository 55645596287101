import { Button } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

export type LinkArrowRightProps = {
    linkText: string
    pathName: string
    state?: any
    onTrack?: () => void
}

const LinkArrowRight: React.FC<LinkArrowRightProps> = ({
    linkText,
    pathName,
    state,
    onTrack
}) => {
    const history = useHistory()
    const theme = useTheme()

    const onNavigate = useCallback(() => {
        onTrack?.()
        history.push(pathName, state)
    }, [history, pathName, state, onTrack])

    return (
        <Button
            variant="text"
            data-testid="button-arrow-right"
            endIcon={<LeafIcon icon={'arrow-right'} fontSize={'small'} />}
            onClick={onNavigate}
        >
            <CoreTypography
                customVariant="buttonNormal"
                sx={{
                    color: theme.palette.primary.main
                }}
            >
                {linkText}
            </CoreTypography>
        </Button>
    )
}

export default React.memo(LinkArrowRight)
