import { useContext } from 'react'
import ChallengeUserChallengeContext, {
    challengeUserChallengeValue
} from './challengeUserChallengeContext'

export const useChallengeUserChallengeContext =
    (): challengeUserChallengeValue => {
        const context = useContext(ChallengeUserChallengeContext)

        return context
    }
