import { useContext } from 'react'
import CompanyDailyGoalsProviderContext, {
    CompanyDailyGoalsValue
} from './companyDailyGoalsProviderContext'

export const useCompanyDailyGoalsProviderContext =
    (): CompanyDailyGoalsValue => {
        const context = useContext(CompanyDailyGoalsProviderContext)

        return context
    }
