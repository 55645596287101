import { Button, Card, CardMedia, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme,
    withTruncatedText
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import useCheckLineCount from '../../../../../hooks/useCheckLineCount/useCheckLineCount'
import useNavigateToChallenge from '../../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import useTextTransform from '../../../../../hooks/useTextTransform/useTextTransform'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import ChallengeDuration from '../../../../elements/ChallengeDuration/ChallengeDuration'
import ChallengeTypeHeader from '../../../../elements/ChallengeTypeHeader/ChallengeTypeHeader'

const TruncatedCoreTypography = withTruncatedText(CoreTypography)
const imagePlaceholder =
    'https://assets.thriveglobal.com/journeys/journey_onboarding_placeholder.png'

const sizeProperties = {
    xs: {
        cardHeight: 389,
        mediaWidth: '100%',
        mediaHeight: 150,
        maxMediaHeight: 150,
        direction: 'column'
    },
    sm: {
        cardHeight: 245,
        mediaWidth: 144,
        mediaHeight: undefined,
        maxMediaHeight: 205,
        direction: 'row'
    },
    md: {
        cardHeight: 245,
        mediaWidth: 264,
        mediaHeight: '100%',
        maxMediaHeight: 205,
        direction: 'row'
    },
    lg: {
        cardHeight: 245,
        mediaWidth: 264,
        mediaHeight: '100%',
        maxMediaHeight: 205,
        direction: 'row'
    }
}
export interface ChallengeFeaturedCardProps {
    id: string
    image?: string
    category?: string
    name: string
    description?: string
    duration: number
    size?: 'lg' | 'md' | 'sm' | 'xs'
    challengeType: ChallengeType
}

const ChallengeFeaturedCard: React.FC<ChallengeFeaturedCardProps> = ({
    id,
    image,
    category,
    name,
    description,
    duration,
    size,
    challengeType
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const { navigate } = useNavigateToChallenge({
        challengeId: id,
        challengeName: name,
        challengeType: challengeType
    })
    const { toTitleCase } = useTextTransform()

    const textRef = useRef(null)
    const singleLineTextHeight = 24
    const { checkLineCount, nameMultiLine } = useCheckLineCount(
        textRef,
        singleLineTextHeight
    )

    useEffect(() => checkLineCount, [checkLineCount])

    const {
        cardHeight,
        mediaWidth,
        mediaHeight,
        maxMediaHeight,
        direction: rawDirection
    } = useMemo(() => {
        if (isMobile) {
            return {
                cardHeight: 389,
                mediaWidth: '100%',
                mediaHeight: 150,
                maxMediaHeight: 150,
                direction: 'column'
            }
        } else {
            return (
                (size && sizeProperties[size]) || {
                    cardHeight: 245,
                    mediaWidth: '100%',
                    mediaHeight: '100%',
                    maxMediaHeight: 205,
                    direction: 'row'
                }
            )
        }
    }, [isMobile, size])

    const direction = rawDirection as
        | 'column'
        | 'row'
        | 'column-reverse'
        | 'row-reverse'

    return (
        <Card>
            <Stack height={cardHeight}>
                <ChallengeTypeHeader challengeType={challengeType} />
                <Stack direction={direction} flex="1">
                    <CardMedia
                        component="img"
                        sx={{
                            width: mediaWidth,
                            height: mediaHeight,
                            maxHeight: maxMediaHeight
                        }}
                        image={image ? image : imagePlaceholder}
                        alt=""
                    />
                    <Stack p={2} height="100%" width="100%">
                        <Stack justifyContent="space-between" height="100%">
                            <Stack gap={1}>
                                <Stack>
                                    {category && (
                                        <CoreTypography variant="body2">
                                            {toTitleCase(category)}
                                        </CoreTypography>
                                    )}
                                    <div ref={textRef}>
                                        <TruncatedCoreTypography
                                            variant="h4"
                                            component="h3"
                                            textLines={2}
                                        >
                                            {name}
                                        </TruncatedCoreTypography>
                                    </div>
                                </Stack>
                                <TruncatedCoreTypography
                                    variant="body2"
                                    textLines={nameMultiLine ? 2 : 3}
                                >
                                    {description}
                                </TruncatedCoreTypography>
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                gap={2}
                                width="100%"
                            >
                                <ChallengeDuration duration={duration} />
                                <Button
                                    endIcon={
                                        <LeafIcon
                                            icon={'arrow-right'}
                                            fontSize={'small'}
                                        />
                                    }
                                    variant="contained"
                                    color="secondary"
                                    onClick={navigate}
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        <FormattedMessage
                                            defaultMessage="Learn more"
                                            description="button text for learn more"
                                        />
                                    </CoreTypography>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeFeaturedCard)
