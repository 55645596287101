import { Apollo } from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface PaginationOptions<T, K, R extends Apollo.OperationVariables> {
    queryProps?: { [key: string]: string | number | null }
    defaultLimit?: number
    defaultOffset?: number
    skip?: boolean
    query: (
        baseOptions: Apollo.QueryHookOptions<K, R>
    ) => Apollo.QueryResult<K, R>
    dataTransform: (data: K) => T[]
}

export const usePagination = <T, K, R extends Apollo.OperationVariables>({
    queryProps,
    defaultOffset = 10,
    skip,
    query,
    dataTransform
}: PaginationOptions<T, K, R>) => {
    const [data, setData] = useState<T[]>([])
    const [queryData, setQueryData] = useState<K>()
    const [offset, setOffset] = useState(0)
    const [originalOffset, setOriginalOffset] = useState(0)
    const [loadingNext, setLoadingNext] = useState(false)
    const [error, setError] = useState(false)
    const [hasMore, setHasMore] = useState(true)
    const [loadNext, setLoadNext] = useState(true)
    const [blockQuery, setBlockQuery] = useState(false)
    const [minimumOffsetLoaded, setMinimumOffsetLoaded] = useState(0)
    const [maximumOffsetLoaded, setMaximumOffsetLoaded] = useState(0)

    const handleDataLoaded = useCallback(
        (data: K) => {
            setQueryData(data)
            const activities = dataTransform(data) ?? []
            setData((a) =>
                loadNext ? a.concat(activities) : activities.concat(a)
            )
            setHasMore(
                (hasMore) => hasMore && activities.length === defaultOffset
            )
            setLoadingNext(false)
        },
        [dataTransform, defaultOffset, loadNext]
    )

    const variables = useMemo<{ [key: string]: string | number | null }>(
        () => ({
            limit: defaultOffset,
            offset: offset,
            ...queryProps
        }),
        [defaultOffset, offset, queryProps]
    )

    const { fetchMore, loading } = query({
        variables: variables as R,
        skip: skip || blockQuery,
        fetchPolicy: 'network-only',
        onCompleted: (data) => handleDataLoaded(data),
        onError: () => {
            setError(true)
            setLoadingNext(false)
        }
    })

    const onSetOffset = useCallback((newOffset: number) => {
        setOffset(newOffset)
        setOriginalOffset(newOffset)
        setMaximumOffsetLoaded(newOffset)
        setMinimumOffsetLoaded(newOffset)
    }, [])

    const getNextOffset = useCallback(
        (loadMore: boolean, initialRefetch: boolean) => {
            if (!initialRefetch) {
                const nextOffset =
                    offset + (loadMore ? defaultOffset : -defaultOffset)
                setOffset(nextOffset)

                return nextOffset
            }

            onSetOffset(originalOffset)

            return originalOffset
        },
        [offset, originalOffset, onSetOffset, defaultOffset]
    )

    const handleLoadMore = useCallback(
        async (loadMore = true, initialRefetch = false) => {
            setBlockQuery(true)
            const nextOffset = getNextOffset(loadMore, initialRefetch)

            if (
                nextOffset > maximumOffsetLoaded ||
                nextOffset < minimumOffsetLoaded ||
                initialRefetch
            ) {
                setLoadNext(loadMore)
                setLoadingNext(true)
                setBlockQuery(false)

                try {
                    return await fetchMore({
                        variables: {
                            limit: defaultOffset,
                            offset: nextOffset,
                            ...queryProps
                        }
                    })
                } catch {
                    setLoadingNext(false)
                }
            }
        },
        [
            fetchMore,
            getNextOffset,
            maximumOffsetLoaded,
            minimumOffsetLoaded,
            defaultOffset,
            queryProps
        ]
    )

    const onRefetch = useCallback(() => {
        setData([])
        setQueryData(undefined)
        setOffset(0)
        setLoadingNext(false)
        setError(false)
        setHasMore(true)
        setLoadNext(true)
        setBlockQuery(false)
        setMinimumOffsetLoaded(0)
        setMaximumOffsetLoaded(0)
        return handleLoadMore(true, true)
    }, [handleLoadMore])

    useEffect(() => {
        if (offset > maximumOffsetLoaded) {
            setMaximumOffsetLoaded(offset)
        }

        if (offset < minimumOffsetLoaded) {
            setMinimumOffsetLoaded(offset)
        }
    }, [offset, maximumOffsetLoaded, minimumOffsetLoaded])

    return {
        data,
        queryData,
        loading,
        error,
        loadingNext,
        hasMore,
        offset,
        maximumOffsetLoaded,
        setOffset: onSetOffset,
        handleLoadMore,
        refetch: onRefetch
    }
}

export default usePagination
