import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import IconStat from '../Stats/IconStat/IconStat'

const messages = defineMessages({
    challengeLength: {
        defaultMessage: `{duration} day Challenge`,
        description: 'total number of days in the challenge'
    }
})

export type ChallengeDurationProps = {
    duration: number
}

const ChallengeDuration: React.FC<ChallengeDurationProps> = ({ duration }) => {
    const { formatMessage } = useIntl()

    return (
        <IconStat
            variant="body2"
            color="text.secondary"
            iconColor="text.secondary"
            spacing={1}
            stat={formatMessage(messages.challengeLength, {
                duration
            })}
            icon={'calendar'}
        />
    )
}

export default memo(ChallengeDuration)
