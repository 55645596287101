import { createContext } from 'react'
import {
    Story,
    StoryType,
    UnifiedChallenge,
    UnifiedChallengeParticipation
} from '../../../graphql/generated/autogenerated'
import { GQLNull } from '../../utils/Nulls'

export interface challengeUserChallengeValue {
    loading: boolean
    displayName?: string | GQLNull
    challenge?: UnifiedChallenge
    userChallenge?: UnifiedChallengeParticipation
    allUserChallenges: UnifiedChallengeParticipation[]
    availableInteraction?: StoryType
    stories: Story[]
    refetch?: () => Promise<any>
    error: boolean
}

export const defaultContextValue: challengeUserChallengeValue = {
    loading: false,
    displayName: undefined,
    challenge: undefined,
    userChallenge: undefined,
    allUserChallenges: [],
    availableInteraction: undefined,
    stories: [],
    refetch: undefined,
    error: false
}

export default createContext(defaultContextValue)
