export type CHALLENGE_VARIANT_TYPE =
    (typeof CHALLENGE_VARIANT)[keyof typeof CHALLENGE_VARIANT]
export const CHALLENGE_VARIANT = {
    JOURNEY: 'JOURNEY',
    BIOTYPE: 'BIOTYPE',
    PRIZE: 'PRIZE',
    COMPANY: 'COMPANY',
    GROUP: 'GROUP',
    PERSONAL: 'PERSONAL'
} as const

export type MeasurementsEnum = (typeof Measurements)[keyof typeof Measurements]
export const Measurements = {
    ounce: 'ounce',
    milliliter: 'milliliter'
} as const

export const CHALLENGE_SIGNUP_MAX_STEPS = 3

export type SIGNUP_STEPS_TYPE = (typeof SIGNUP_STEPS)[keyof typeof SIGNUP_STEPS]
export const SIGNUP_STEPS = {
    DETAILS: 'details',
    CONFIRM: 'confirm',
    TERMS: 'terms',
    PARTICIPATION_METHOD: 'participationMethod',
    ACTIVITY: 'activity',
    HYDRATION: 'hydration',
    SLEEP: 'sleep',
    JOIN_TEAM: 'joinTeam',
    CREATE_TEAM: 'createTeam',
    PRIVACY_INFO: 'privacyInfo'
} as const

export type SIGNUP_VARIANT_TYPE =
    (typeof SIGNUP_VARIANT)[keyof typeof SIGNUP_VARIANT]
export const SIGNUP_VARIANT = {
    SOLO: 'solo',
    CREATE_TEAM: 'createTeam',
    JOIN_TEAM: 'joinTeam',
    INVITE_TEAM: 'inviteTeam'
} as const

export const CHALLENGE_THEME = {
    STANDARD: 'STANDARD',
    MOVEMENT: 'MOVEMENT',
    HYDRATION: 'HYDRATION',
    SLEEP: 'SLEEP',
    RESET: 'RESET'
} as const

export type MEASUREMENT_UNIT_TYPE =
    (typeof MEASUREMENT_UNIT)[keyof typeof MEASUREMENT_UNIT]
export const MEASUREMENT_UNIT = {
    Ml: 'ML',
    Oz: 'OZ'
} as const

export type MEASUREMENTS_TYPE = (typeof MEASUREMENTS)[keyof typeof MEASUREMENTS]
export const MEASUREMENTS = {
    OUNCE: 'ounce',
    MILLILITER: 'milliliter'
} as const

export type CHALLENGE_TYPE_TYPE =
    (typeof CHALLENGE_TYPE)[keyof typeof CHALLENGE_TYPE]
export const CHALLENGE_TYPE = {
    GROUP: 'GROUP',
    COMPANY: 'COMPANY'
} as const

export const CHALLENGES_ROUTES = {
    OLD_CHALLENGES: '/challenges',
    CHALLENGE: '/challenges/v2/:challengeId/:socialGroupId?',
    CHALLENGES: '/challenges/v2',
    MY_CHALLENGES: '/challenges/v2/my-challenges',
    COMPLETED_CHALLENGES: '/challenges/v2/completed',
    DISCOVER_CHALLENGES: '/challenges/v2/discover'
} as const

export const FEATURE_FLAGS = {
    HAS_CHALLENGES_V2_ACCESS: 'HasChallengesV2Access',
    ACCENTURE_CHALLENGE_TEXT: 'AccentureChallengeText'
} as const
