import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'
import {
    ChallengeUserDailyGoal,
    Exact,
    GetDailyGoalCompletionQuery,
    UnifiedChallengeParticipation,
    UnifiedMicrostepActivities,
    useGetDailyGoalCompletionLazyQuery
} from '../../graphql/generated/autogenerated'
import { ChallengeType } from '../../shared'
import { ActiveUnifiedChallengeParticipation } from '../useGetChallengesHomeData/useGetChallengesHomeData'

export type DailyGoalCompletionData = {
    error: ApolloError | undefined
    loading: boolean
    refetch: () => Promise<
        Apollo.ApolloQueryResult<GetDailyGoalCompletionQuery>
    >
    fetch: (
        challengeId: string
    ) => Promise<
        Apollo.QueryResult<
            GetDailyGoalCompletionQuery,
            Exact<{ challengeId: string }>
        >
    >
    getDailyGoalCompletion: (
        activeChallenges: UnifiedChallengeParticipation[]
    ) => Promise<ActiveUnifiedChallengeParticipation[]>
}

const countGoalsForToday = (activities: UnifiedMicrostepActivities[] = []) => {
    const todaysDate = new Date().toISOString().split('T')[0]
    return activities?.reduce(
        (count, item) =>
            count +
            item.checkIns.filter((date) => date.startsWith(todaysDate)).length,
        0
    )
}

export const useGetDailyGoalCompletionData = () => {
    const [fetchData, { loading, error, refetch }] =
        useGetDailyGoalCompletionLazyQuery()

    const fetch = useCallback(
        (challengeId: string) => {
            return fetchData({
                variables: {
                    challengeId: challengeId
                }
            })
        },
        [fetchData]
    )

    const getDailyGoalCompletion = useCallback(
        async (activeChallenges: UnifiedChallengeParticipation[]) => {
            return await Promise.all(
                activeChallenges.map(async (ac) => {
                    // If it's a company or group challenge, fetch the goals
                    if (
                        ac.challenge.type === ChallengeType.company ||
                        ac.challenge.type === ChallengeType.group
                    ) {
                        const response = await fetch(ac.challenge.id)
                        const goals =
                            response?.data?.unifiedChallenges
                                ?.communityChallengeUserDailyGoals
                        return {
                            ...ac,
                            goalsTotal: goals?.length || 0,
                            goalsCompleted:
                                goals?.filter((goal) => goal.completed)
                                    .length || 0
                        }
                    }
                    // If it's a personal challenge, count the goals based on microstep activities
                    const activities =
                        ac?.participation?.[0]?.microstepActivities
                    return {
                        ...ac,
                        goalsTotal: activities?.length || 0,
                        goalsCompleted: countGoalsForToday(activities ?? [])
                    }
                })
            )
        },
        [fetch]
    )

    return useMemo<DailyGoalCompletionData>(() => {
        return {
            error,
            fetch,
            loading,
            refetch,
            getDailyGoalCompletion
        }
    }, [error, loading, refetch, fetch, getDailyGoalCompletion])
}

export default useGetDailyGoalCompletionData
