import { Stack } from '@mui/material'
import React, { memo } from 'react'

export interface PageProps {
    children: React.ReactNode
}

const Page: React.FC<PageProps> = ({ children }) => (
    <Stack py={3} data-testid="page">
        {children}
    </Stack>
)

export default memo(Page)
