import { Stack } from '@mui/material'
import {
    CoreTypography,
    ErrorScreen,
    ErrorScreenVariant,
    PageLayout
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { withErrorBoundary } from '../../components/elements/ErrorBoundaryWrapper'
import { BackActionHeader } from '../../components/elements/Headers'
import Page from '../../components/elements/Page/Page'
import TitleHeader from '../../components/elements/TitleHeader/TitleHeader'
import ChallengeFilter, {
    ChallengeFilterSelection
} from '../../components/features/HomeChallenges/ChallengeFilter/ChallengeFilter'
import ChallengeGrid, {
    ChallengeCardType
} from '../../components/features/HomeChallenges/ChallengeGrid/ChallengeGrid'
import { ActiveUnifiedChallengeParticipation } from '../../hooks/useGetChallengesHomeData/useGetChallengesHomeData'
import useGetPastChallenges from '../../hooks/useGetPastChallenges/useGetPastChallenges'
import { ROUTES } from '../../routes'

const PastChallenges: React.FC = () => {
    const history = useHistory()
    const { pastChallenges, loading, error, filterBySelection } =
        useGetPastChallenges()

    const [filteredPastChallenges, setFilteredPastChallenges] =
        useState<ActiveUnifiedChallengeParticipation[]>()

    useEffect(() => {
        if (!loading && !filteredPastChallenges) {
            setFilteredPastChallenges(pastChallenges)
        }
    }, [loading, filteredPastChallenges, pastChallenges])

    const onFilter = useCallback(
        (topic: string | undefined) => {
            setFilteredPastChallenges(filterBySelection(pastChallenges, topic))
        },
        [pastChallenges, filterBySelection, setFilteredPastChallenges]
    )

    return (
        <PageLayout maxWidth="lg">
            <Page>
                <Stack gap={4}>
                    <Stack gap={2}>
                        <BackActionHeader
                            onBack={() => history.push(ROUTES.HOME)}
                        />
                        <TitleHeader pastChallenges={true} />
                    </Stack>
                    {error ? (
                        <ErrorScreen variant={ErrorScreenVariant.ServerError} />
                    ) : loading || pastChallenges?.length > 0 ? (
                        <ChallengeGrid
                            skeletonCount={9}
                            loading={loading}
                            challenges={filteredPastChallenges ?? []}
                            type={ChallengeCardType.PAST}
                            showEmptyState={true}
                            filter={
                                <ChallengeFilter
                                    loading={loading}
                                    onChange={(
                                        selection: ChallengeFilterSelection
                                    ) => onFilter(selection.topic)}
                                />
                            }
                        />
                    ) : (
                        <CoreTypography variant="h5" color="text.secondary">
                            <FormattedMessage
                                defaultMessage="You have not completed any challenges yet"
                                description="text that will be displayed when there are no past challenges"
                            />
                        </CoreTypography>
                    )}
                </Stack>
            </Page>
        </PageLayout>
    )
}

export default withErrorBoundary(
    'PastChallenges',
    'Page',
    React.memo(PastChallenges)
)
