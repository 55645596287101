import { ComponentType, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
    StoryType,
    UnifiedChallenge,
    UnifiedChallengeParticipation,
    useGetChallengeUserChallengeQuery
} from '../../../graphql/generated/autogenerated'
import ChallengeUserChallengeProviderContext, {
    challengeUserChallengeValue
} from './challengeUserChallengeContext'
import { ChallengeType } from '../../enums/challengeType'
import { stripBOM } from '../../../shared/utils/bom'

export default function withChallengeUserChallengeProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const { challengeId } = useParams<{
            challengeId: string
        }>()

        const { data, error, loading, refetch } =
            useGetChallengeUserChallengeQuery({
                variables: {
                    challengeId: stripBOM(challengeId),
                    inProgress: true
                }
            })

        const {
            displayName,
            challenge,
            userChallenge,
            allUserChallenges,
            availableInteraction,
            stories
        } = useMemo(() => {
            const challenge = data?.unifiedChallenges
                ?.unifiedChallenge as UnifiedChallenge

            return {
                displayName: data?.socialGroups?.displayName?.displayName,
                challenge,
                userChallenge:
                    data?.unifiedChallenges?.unifiedUserChallenges?.find(
                        (unifiedUserChallenge) =>
                            unifiedUserChallenge?.challenge?.id ===
                            challenge?.id
                    ) as UnifiedChallengeParticipation,
                allUserChallenges:
                    data?.unifiedChallenges.unifiedUserChallenges.filter(
                        (userChallenge) =>
                            userChallenge?.challenge?.challenge_type !==
                            ChallengeType.group
                    ) as UnifiedChallengeParticipation[],
                availableInteraction: data?.unifiedChallenges
                    ?.availableInteractions?.[0] as StoryType,
                stories: data?.unifiedChallenges?.getStories ?? []
            }
        }, [data])

        const state = useMemo<challengeUserChallengeValue>(
            () => ({
                loading,
                displayName: displayName,
                challenge,
                userChallenge,
                allUserChallenges,
                availableInteraction,
                stories,
                error: Boolean(error),
                refetch
            }),
            [
                loading,
                displayName,
                challenge,
                userChallenge,
                allUserChallenges,
                availableInteraction,
                stories,
                error,
                refetch
            ]
        )

        return (
            <ChallengeUserChallengeProviderContext.Provider value={state}>
                <Component {...props} />
            </ChallengeUserChallengeProviderContext.Provider>
        )
    }
}
