import { Button, Stack, TypographyProps, buttonClasses } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { IconName } from '@fortawesome/fontawesome-common-types'

interface IconStatProps
    extends TypographyProps<
        React.ElementType,
        { component?: React.ElementType }
    > {
    stat: any
    color?: string
    iconColor?: string
    spacing?: number
    variant?: any
    icon?: IconName
    onClick?: () => void
}

const IconStat: React.FC<IconStatProps> = ({
    stat,
    color,
    iconColor = 'accent.main',
    spacing = 1.5,
    variant = 'body1',
    icon,
    onClick,
    ...props
}) => {
    const iconComponent = useMemo(() => {
        if (icon) {
            return (
                <LeafIcon
                    icon={icon}
                    fontSize={'small'}
                    sx={{
                        color: iconColor
                    }}
                />
            )
        }
    }, [icon, iconColor])

    const typography = useMemo(
        () => (
            <CoreTypography variant={variant} color={color} {...props}>
                {stat}
            </CoreTypography>
        ),
        [variant, color, stat, props]
    )

    //override the button icon adornment size
    const sx = {
        [`& .${buttonClasses.startIcon} > *:nth-of-type(1)`]: {
            fontSize: 24
        }
    }

    return onClick ? (
        <Button
            variant="text"
            onClick={onClick}
            startIcon={iconComponent}
            size="small"
            sx={{
                mt: -0.5,
                ...sx
            }}
        >
            {typography}
        </Button>
    ) : (
        <Stack
            direction="row"
            spacing={spacing}
            data-testid="stat-description"
            alignItems="center"
        >
            {iconComponent}
            {typography}
        </Stack>
    )
}

export default memo(IconStat)
