export const getInitials = (text: string) => {
    return text
        ?.split(' ')
        ?.reduce((initials, n) => {
            const char = n.charAt(0)

            return RegExp(/^[^a-zA-Z0-9]+$/).test(char)
                ? ''
                : initials + char.toUpperCase()
        }, '')
        .substring(0, 2)
}
