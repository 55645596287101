import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    ChallengeTemplate,
    GetRecommendedChallengesQuery,
    useGetRecommendedChallengesQuery
} from '../../graphql/generated/autogenerated'
import { GQLNull, GQLNullValue } from '../../shared/utils/Nulls'

interface ExtendedChallengeTemplate extends ChallengeTemplate {
    challenge_type?: string
}

export interface ChallengeRecommendationData {
    recommendedChallenges: ExtendedChallengeTemplate[] | GQLNull
    loading: boolean
    error: ApolloError | undefined
    refetch: () => Promise<
        Apollo.ApolloQueryResult<GetRecommendedChallengesQuery>
    >
}

export const useGetChallengeRecommendation = () => {
    const { data, loading, error, refetch } = useGetRecommendedChallengesQuery({
        variables: { numberOfRecommendations: 8 }
    })

    const recommendedChallenges = useMemo(
        () =>
            data?.unifiedChallenges?.getRecommendedChallenges?.map(
                (challenge) => ({
                    ...challenge,
                    challenge_type: challenge.templateScope
                })
            ) ?? GQLNullValue,
        [data]
    )

    return useMemo<ChallengeRecommendationData>(
        () => ({
            recommendedChallenges,
            loading,
            error,
            refetch
        }),
        [recommendedChallenges, loading, error, refetch]
    )
}

export default useGetChallengeRecommendation
