import { useContext } from 'react'
import ChallengeLeaderboardProviderContext, {
    TotalDailyGoalsCompletedProviderValue
} from './totalDailyGoalsCompletedContext'

export const useTotalDailyGoalsCompletedProviderContext =
    (): TotalDailyGoalsCompletedProviderValue => {
        const context = useContext(ChallengeLeaderboardProviderContext)

        if (context === undefined) {
            throw new Error(
                'useChallengeLeaderboardContext must be used within a ChallengeLeaderboardContext'
            )
        }

        return context
    }
