import { useMemo } from 'react'
import { ChallengeThemes } from '../../enums/challengeThemes'
import { UnifiedChallenge } from '../../graphql/generated/autogenerated'

export const useChallengeTheme = (challenge?: UnifiedChallenge) => {
    return useMemo(
        () => ({
            deviceEnabled: challenge?.theme === ChallengeThemes.MOVEMENT,
            hydrationEnabled: challenge?.theme === ChallengeThemes.HYDRATION,
            sleepEnabled: challenge?.theme === ChallengeThemes.SLEEP
        }),
        [challenge]
    )
}

export default useChallengeTheme
