import { UnifiedChallenge } from '@thriveglobal/thrive-web-core/dist/graphql/generated/autogenerated'
import sample from 'lodash/sample'
import { UnifiedChallengeParticipation } from '../../../../graphql/generated/autogenerated'
import isSameDay from 'date-fns/isSameDay'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'

export type SpotlightChallenges = {
    unifiedChallenges: UnifiedChallenge[]
    unifiedUserChallenges: UnifiedChallengeParticipation[]
}

const shouldFeatureChallenge = (challenge: UnifiedChallenge, today: Date) => {
    const signUpDate = parseISO(challenge.companyChallengeSignupDay)
    const challengeSignUpStarted = isAfter(today, signUpDate)
    return challenge.isFeatured && challengeSignUpStarted
}

export function selectChallengeSpotlight(
    spotlightChallenges: SpotlightChallenges,
    today: Date
) {
    // Featured challenge that we will return
    let featuredChallenge: any = undefined

    // List of all challenges
    const challenges =
        (spotlightChallenges?.unifiedChallenges as UnifiedChallenge[]) ?? []

    // List of all active user challenges
    const activeUserChallenges =
        (spotlightChallenges?.unifiedUserChallenges as UnifiedChallengeParticipation[]) ??
        []

    // List of all active company challenges
    const activeCompanyChallenges = activeUserChallenges.filter(
        (ac) => ac?.challenge?.challenge_type === 'COMPANY'
    )

    if (activeUserChallenges?.length > 0) {
        // If there are active user challenges, pick one of these
        if (activeCompanyChallenges?.length > 0) {
            // If we have any challenges that have yet to be completed we show them first
            // Then if there are active company challenges, pick one of these
            const uncompletedCompanyChallenge = activeCompanyChallenges?.find(
                (challenge) => !challenge?.participation?.[0]?.completedOn
            )
            featuredChallenge = uncompletedCompanyChallenge
                ? uncompletedCompanyChallenge
                : (sample(
                      activeCompanyChallenges
                  ) as UnifiedChallengeParticipation)
        } else {
            // If we have any challenges that have yet to be completed we show them first
            // Then if there are active challenges, pick one of these
            const uncompletedUserChallenge = activeUserChallenges?.find(
                (challenge) => !challenge?.participation?.[0]?.completedOn
            )
            featuredChallenge = uncompletedUserChallenge
                ? uncompletedUserChallenge
                : (sample(
                      activeUserChallenges
                  ) as UnifiedChallengeParticipation)
        }
    } else if (challenges?.length > 0) {
        // If there are no active user challenges, pick a featured challenge
        const fc = sample(
            challenges.filter((uc) => shouldFeatureChallenge(uc, today))
        )
        if (fc) {
            featuredChallenge = {
                challenge: fc
            } as UnifiedChallengeParticipation
        }
    }

    return featuredChallenge as UnifiedChallengeParticipation
}
