import { useMemo } from 'react'
import {
    IdentityQuery,
    SocialGroup,
    SocialGroupQuery
} from '../../graphql/generated/autogenerated'

export const useSocialGroupDataFormatter = (
    challengeId: string,
    socialGroups?: SocialGroupQuery,
    identity?: IdentityQuery
) => {
    return useMemo(() => {
        const groups = socialGroups?.socialGroupsForUser?.filter(
            (socialGroup) => socialGroup.challengeId === challengeId
        )
        const teamSocialGroup = groups?.find(
            (socialGroup) =>
                !socialGroup.isMainCommunitySocialGroup &&
                !socialGroup.isSoloChallenge
        ) as SocialGroup

        return {
            mainCommunitySocialGroup: groups?.find(
                (socialGroup) => socialGroup.isMainCommunitySocialGroup
            ) as SocialGroup,
            socialGroup: teamSocialGroup
                ? teamSocialGroup
                : (groups?.find(
                      (socialGroup) => !socialGroup.isMainCommunitySocialGroup
                  ) as SocialGroup),
            displayName: socialGroups?.displayName?.displayName,
            companyName: identity?.me?.company?.name
        }
    }, [challengeId, socialGroups, identity])
}

export default useSocialGroupDataFormatter
