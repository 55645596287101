import { Avatar, Stack } from '@mui/material'
import { LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ChallengeType } from '../../../shared/enums/challengeType'

const messages = defineMessages({
    iconAriaLabel: {
        defaultMessage: '{type} Challenge type',
        description: 'description of icon'
    }
})

export type ChallengeTypeIconProps = {
    challengeType: ChallengeType
    color?: 'secondary' | 'info'
}

const ChallengeTypeIcon: React.FC<ChallengeTypeIconProps> = ({
    challengeType,
    color = 'info'
}) => {
    const { palette } = useTheme()
    const intl = useIntl()
    const icon = useMemo(() => {
        switch (challengeType) {
            case ChallengeType.company:
                return 'buildings'
            case ChallengeType.group:
                return 'users'
            case ChallengeType.biotype:
            case ChallengeType.journey:
            case ChallengeType.prize:
                return 'user'
            default:
                break
        }
    }, [challengeType])

    return (
        <>
            {icon && (
                <Avatar
                    alt={intl.formatMessage(messages.iconAriaLabel, {
                        type: challengeType.toLocaleLowerCase()
                    })}
                    sx={{
                        background: palette[color]?.main,
                        color: palette[color]?.contrastText,
                        height: 32,
                        width: 32
                    }}
                >
                    <Stack p={1.25}>
                        <LeafIcon
                            icon={icon}
                            fontSize="small"
                            iconStyle="solid"
                        />
                    </Stack>
                </Avatar>
            )}
        </>
    )
}

export default memo(ChallengeTypeIcon)
