import { Box, Button, Card, Stack } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    withTruncatedText
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import useNavigateToChallenge from '../../../../../hooks/useNavigateToChallenge/useNavigateToChallenge'
import { ChallengeType } from '../../../../../shared/enums/challengeType'
import ChallengeTypeHeader from '../../../../elements/ChallengeTypeHeader/ChallengeTypeHeader'
import Image from '../../../../elements/Image/Image'

const placeholderImg =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/8ed15946-fa59-4be0-fc47-158979628100/cms'

const messages = defineMessages({
    daysLeft: {
        defaultMessage:
            '{remainingDays, plural, one {# day left} other {# days left}}',
        description: 'description for days left'
    },
    finished: {
        defaultMessage: 'Finished!',
        description: 'description for finished challenge'
    },
    greatJob: {
        defaultMessage:
            'Great job! We’ve put together a summary of all you accomplished.',
        description: 'description for when challenge is finished'
    },
    completedDailyGoals: {
        defaultMessage: 'Completed Daily Goals',
        description: 'description for completed daily goals'
    },
    totalDailyGoals: {
        defaultMessage: '{goalsCompleted, number} of {goalsTotal, number}',
        description: 'total daily goals completed'
    },
    buttonSummary: {
        defaultMessage: 'View Challenge summary',
        description: 'button text for learn more'
    },
    buttonView: {
        defaultMessage: 'View',
        description: 'button text for learn more'
    },
    buttonKeepGoing: {
        defaultMessage: 'Keep going',
        description: 'button text for learn more'
    },
    upcoming: {
        defaultMessage: 'Upcoming',
        description: 'description of a challenge that will start soon'
    }
})

const TruncatedCoreTypography = withTruncatedText(CoreTypography)
export interface ChallengeActiveCardProps {
    id: string
    name: string
    challengeType: ChallengeType
    remainingDays: number
    goalsCompleted: number
    goalsTotal: number
    completed: boolean
    past?: boolean
    challengeStarted?: boolean
}

const ChallengeActiveCard: React.FC<ChallengeActiveCardProps> = ({
    id,
    name,
    challengeType,
    remainingDays,
    goalsCompleted,
    goalsTotal,
    completed,
    past,
    challengeStarted
}) => {
    const { navigate } = useNavigateToChallenge({
        challengeId: id,
        challengeName: name,
        challengeType,
        isActive: true,
        isPast: past
    })
    const { formatMessage, formatNumber } = useIntl()

    const completedDailyGoals = useMemo(
        () => goalsCompleted === goalsTotal,
        [goalsTotal, goalsCompleted]
    )

    const { buttonText, currentTimeStateText } = useMemo(() => {
        if (!challengeStarted) {
            return {
                buttonText: messages.buttonView,
                currentTimeStateText: formatMessage(messages.upcoming)
            }
        } else if (completed) {
            return {
                buttonText: messages.buttonSummary,
                currentTimeStateText: formatMessage(messages.finished)
            }
        }

        return {
            buttonText: completedDailyGoals
                ? messages.buttonView
                : messages.buttonKeepGoing,
            currentTimeStateText: formatMessage(messages.daysLeft, {
                remainingDays: formatNumber(remainingDays)
            })
        }
    }, [
        challengeStarted,
        completed,
        completedDailyGoals,
        formatMessage,
        formatNumber,
        remainingDays
    ])

    return (
        <Card>
            <Stack minHeight={273} position="relative">
                {!challengeStarted && (
                    <Stack position="absolute" bottom={0} left={0}>
                        <Image src={placeholderImg} height={118} width={179} />
                    </Stack>
                )}
                <ChallengeTypeHeader challengeType={challengeType} />
                <Stack p={2} gap={1} flex={1} justifyContent="space-between">
                    <Stack gap={1}>
                        <CoreTypography variant="body2" color="text.secondary">
                            {currentTimeStateText}
                        </CoreTypography>
                        <TruncatedCoreTypography
                            variant="h4"
                            textLines={2}
                            color="text.secondary"
                        >
                            {name}
                        </TruncatedCoreTypography>
                        {completed && (
                            <CoreTypography
                                variant="body2"
                                color="text.primary"
                                width="85%"
                            >
                                {formatMessage(messages.greatJob)}
                            </CoreTypography>
                        )}
                    </Stack>
                    <Stack gap={1}>
                        <Stack>
                            {!completed && challengeStarted && (
                                <>
                                    <CoreTypography
                                        variant="overline"
                                        color="primary"
                                    >
                                        {formatMessage(
                                            messages.completedDailyGoals
                                        )}
                                    </CoreTypography>
                                    <CoreTypography
                                        customVariant="display3"
                                        color="primary"
                                    >
                                        {formatMessage(
                                            messages.totalDailyGoals,
                                            {
                                                goalsCompleted:
                                                    formatNumber(
                                                        goalsCompleted
                                                    ),
                                                goalsTotal:
                                                    formatNumber(goalsTotal)
                                            }
                                        )}
                                    </CoreTypography>
                                </>
                            )}
                        </Stack>
                        <Stack alignItems="flex-end">
                            <Box>
                                <Button
                                    endIcon={
                                        !completed && (
                                            <LeafIcon
                                                icon={'arrow-right'}
                                                fontSize={'small'}
                                            />
                                        )
                                    }
                                    variant="contained"
                                    color="secondary"
                                    onClick={navigate}
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        {formatMessage(buttonText)}
                                    </CoreTypography>
                                </Button>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeActiveCard)
