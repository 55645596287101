import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import {
    GetCoreJourneysQuery,
    Journey,
    useGetCoreJourneysQuery
} from '../../graphql/generated/autogenerated'

export interface CoreJourneysData {
    journeys: Journey[]
    error: ApolloError | undefined
    loading: boolean
    refetch: () => Promise<Apollo.ApolloQueryResult<GetCoreJourneysQuery>>
}

export const useGetCoreJourneysData = () => {
    const { data, loading, error, refetch } = useGetCoreJourneysQuery()

    return useMemo<CoreJourneysData>(() => {
        return {
            journeys: (data?.journeys?.getCoreJourneys as Journey[]) || [],
            error,
            loading,
            refetch
        }
    }, [data?.journeys?.getCoreJourneys, error, loading, refetch])
}

export default useGetCoreJourneysData
