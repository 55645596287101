import { Stack } from '@mui/material'
import { PageLayout, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Page from '../../components/layout/Page'
import {
    BoundaryName,
    BoundaryType,
    withErrorBoundary
} from '../../decorators/withErrorBoundary/withErrorBoundary'
import useGetChallengeLanding, {
    ChallengeViewType
} from '../../hooks/useGetChallengeLanding/useGetChallengeLanding'
import { ReactNullValue } from '../../utils/nulls'
import ChallengeActive from './Active'
import ChallengeIntro from './Intro/ChallengeIntro'
import ChallengeRecap from './Recap/ChallengeRecap'

const Challenge = () => {
    const { gradient } = useTheme()
    const { challengeId } = useParams<{ challengeId: string }>()
    const {
        viewType,
        loading,
        isTemplate,
        challenge,
        refetchUnifiedChallengeAvailability
    } = useGetChallengeLanding(challengeId)

    const currentView = useMemo(() => {
        if (loading) return ReactNullValue

        switch (viewType) {
            case ChallengeViewType.INTRO:
                return (
                    <ChallengeIntro
                        isTemplate={isTemplate}
                        refetchUnifiedChallengeAvailability={
                            refetchUnifiedChallengeAvailability
                        }
                    />
                )
            case ChallengeViewType.ACTIVE:
                return (
                    <ChallengeActive
                        fetchAvailabilityLoading={loading}
                        refetchUnifiedChallengeAvailability={
                            refetchUnifiedChallengeAvailability
                        }
                    />
                )
            case ChallengeViewType.RECAP:
                return (
                    <ChallengeRecap
                        challengeId={challengeId}
                        theme={challenge?.theme || ''}
                    />
                )
            default:
                return ReactNullValue
        }
    }, [viewType, loading, isTemplate, challengeId, challenge?.theme])

    return (
        <Stack
            height="100%"
            minHeight="inherit"
            sx={{ background: gradient.main }}
        >
            <Stack>{currentView}</Stack>
        </Stack>
    )
}

export default withErrorBoundary(
    BoundaryName.CHALLENGE,
    BoundaryType.PAGE,
    Challenge
)
