import { useAppSelector } from '@thriveglobal/thrive-web-core'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { useMemo } from 'react'

export const useCurrentDateTime = () => {
    const { timezone } = useAppSelector((state) => state.settings)
    return useMemo(() => utcToZonedTime(new Date(), timezone), [timezone])
}

export default useCurrentDateTime
