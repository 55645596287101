import { createContext } from 'react'

export interface TotalDailyGoalsCompletedProviderValue {
    loading: boolean
    error: boolean
    totalGoalsCompleted?: number
    setChallengeId: (challengeId: string) => void
    refetch: () => Promise<any>
}

export const defaultContextValue: TotalDailyGoalsCompletedProviderValue = {
    loading: false,
    error: false,
    totalGoalsCompleted: 0,
    setChallengeId: () => null,
    refetch: () => Promise.resolve()
}

export default createContext(defaultContextValue)
