import { createContext } from 'react'
import { Post } from '../../../../../graphql/generated/autogenerated'

export interface SocialActivityProviderValue {
    loading: boolean
    error: boolean
    loadingNext: boolean
    hasMore: boolean
    activityFeed: Post[]
    displayName: string
    setSocialGroupId?: (socialGroupId: string) => void
    loadMore: (next: boolean) => Promise<unknown> | void
    refetch: () => Promise<unknown>
    fetchMore: (fetchMoreOptions: unknown) => Promise<unknown>
}

const defaultContextValue: SocialActivityProviderValue = {
    loading: false,
    error: false,
    loadingNext: false,
    hasMore: false,
    activityFeed: [],
    displayName: '',
    setSocialGroupId: () => ({}),
    loadMore: () => Promise.resolve(null),
    refetch: () => Promise.resolve(null),
    fetchMore: () => Promise.resolve(null)
}

export default createContext(defaultContextValue)
