import { Button, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../routes'
import { ChallengeType } from '../../../../enums/challengeType'
import { useCompanyChallengeStates } from '../../../../hooks/useCompanyChallengeStates/useCompanyChallengeStates'
import NotStartedStats from '../ChallengeCardStats/NotStartedStats'
import SignUpStats from '../ChallengeCardStats/SignUpStats'
import StartedStats from '../ChallengeCardStats/StartedStats'

export type CompanyChallengeCardDetailsProps = {
    challenge: UnifiedChallenge
    isActive?: boolean
    isVertical?: boolean
}

const messages = defineMessages({
    daysLeft: {
        defaultMessage: `{remainingDays, plural, one {# day left} other {# days left}}`,
        description:
            'description of the remaining days left before completing the challenge'
    },
    keepGoing: {
        defaultMessage: 'Keep going',
        description: 'button text for viewing the active company challenge'
    },
    seeMore: {
        defaultMessage: 'See more',
        description: 'button text for viewing the active company challenge'
    },
    signUp: {
        defaultMessage: 'Sign up',
        description: 'button text for viewing the active company challenge'
    },
    viewResults: {
        defaultMessage: 'View results',
        description: 'button text for viewing the active company challenge'
    }
})

const CompanyChallengeCardDetails: React.FC<
    CompanyChallengeCardDetailsProps
> = ({ challenge, isActive, isVertical }) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const history = useHistory()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const calculateChallengeStates = useCompanyChallengeStates()

    const { challengeStarted, challengeExpired, remainingDays } = useMemo(
        () => calculateChallengeStates(challenge),
        [calculateChallengeStates, challenge]
    )

    const onNavigateToChallenge = useCallback(() => {
        history.push(
            generatePath(
                challenge?.challenge_type === ChallengeType.company
                    ? ROUTES.COMPANY_CHALLENGE_HOME
                    : ROUTES.GROUP_CHALLENGE_HOME,
                {
                    challengeId: challenge?.id
                }
            )
        )
    }, [history, challenge])

    const buttonText = useMemo(() => {
        if (challengeExpired) {
            return messages.viewResults
        } else if (isActive && challengeStarted) {
            return messages.keepGoing
        } else if (isActive && !challengeStarted) {
            return messages.seeMore
        }

        return messages.signUp
    }, [challengeExpired, challengeStarted, isActive])

    const stats = useMemo(() => {
        if (challengeExpired) {
            return
        } else {
            if (isActive) {
                if (challengeStarted) {
                    return <StartedStats challenge={challenge} isVertical />
                } else {
                    return <NotStartedStats challenge={challenge} />
                }
            }
            return <SignUpStats challenge={challenge} />
        }
    }, [challengeExpired, challengeStarted, challenge, isActive])

    return (
        <Stack
            gap={isMobile || isVertical ? 3 : 4}
            justifyContent="space-between"
            height="100%"
        >
            {stats && <Stack>{stats}</Stack>}
            <Stack direction="column" width="100%" gap={1}>
                {challengeStarted && !challengeExpired && (
                    <CoreTypography variant="body1">
                        {formatMessage(messages.daysLeft, {
                            remainingDays
                        })}
                    </CoreTypography>
                )}
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onNavigateToChallenge}
                    endIcon={
                        <LeafIcon icon={'arrow-right'} fontSize={'small'} />
                    }
                >
                    <CoreTypography customVariant="buttonNormal">
                        {formatMessage(buttonText)}
                    </CoreTypography>
                </Button>
            </Stack>
        </Stack>
    )
}

export default memo(CompanyChallengeCardDetails)
