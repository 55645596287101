import { useContext } from 'react'
import CompanyChallengeProviderContext, {
    CompanyChallengeProviderValue
} from './companyChallengeContext'

export const useCompanyChallengeProviderContext =
    (): CompanyChallengeProviderValue => {
        const context = useContext(CompanyChallengeProviderContext)

        return context
    }
