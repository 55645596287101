import { Stack } from '@mui/material'
import { LeafIcon, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import IconStat from '../IconStat/IconStat'

export type ActivityCompletedIconStatProps = {
    stat: any
    activityGoal: number
    color?: string
    completedColor?: string
}

const ActivityCompletedIconStat: React.FC<ActivityCompletedIconStatProps> = ({
    stat,
    activityGoal,
    color,
    completedColor
}) => {
    const theme = useTheme()
    const { formatNumber } = useIntl()

    const { activityCompleted, activityColor } = useMemo(() => {
        const activityCompleted = stat >= activityGoal
        return {
            activityCompleted,
            activityColor: activityCompleted
                ? completedColor ?? theme.palette.accent.main
                : color ?? theme.palette.grey[700]
        }
    }, [stat, activityGoal, completedColor, color, theme])

    return (
        <Stack direction="row" spacing={0.5} marginLeft={1}>
            <IconStat
                spacing={0.5}
                variant="overline"
                icon={'trophy'}
                stat={formatNumber(activityGoal)}
                color={activityColor}
                iconColor={activityColor}
            />
            {activityCompleted && (
                <LeafIcon
                    icon={'check'}
                    color={'accent'}
                    data-testid={'check-icon'}
                />
            )}
        </Stack>
    )
}

export default memo(ActivityCompletedIconStat)
