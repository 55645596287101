import { Box, Card, CardActionArea, Icon } from '@mui/material'
import React from 'react'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

export interface ChevronCardProps {
    onNavigate: () => void
    'aria-label'?: string
    children: React.ReactNode
}

const ChevronCard: React.FC<ChevronCardProps> = ({
    onNavigate,
    'aria-label': ariaLabel,
    children
}) => {
    return (
        <Card aria-label={ariaLabel} elevation={8}>
            <CardActionArea onClick={onNavigate}>
                <Box
                    data-testid="chevron-card"
                    display="flex"
                    justifyContent="space-between"
                >
                    {children}
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            m: 2,
                            alignItems: 'center'
                        }}
                    >
                        <LeafIcon icon={'chevron-right'} color={'primary'} />
                    </Box>
                </Box>
            </CardActionArea>
        </Card>
    )
}

export default ChevronCard
