export enum PostTypes {
    RESET_WATCHED = 'RESET_WATCHED',
    PERSONALIZED_RESET = 'PERSONALIZED_RESET',
    MICROSTEP_CHECKIN = 'MICROSTEP_CHECKIN',
    MICROSTEP_MULTIPLE_CHECKIN = 'MICROSTEP_MULTIPLE_CHECKIN',
    CHALLENGE_JOINED = 'CHALLENGE_JOINED',
    SOCIAL_GROUP_JOINED = 'SOCIAL_GROUP_JOINED',
    SOCIAL_GROUP_CREATED = 'SOCIAL_GROUP_CREATED',
    CHALLENGE_STREAK = 'CHALLENGE_STREAK',
    CHALLENGE_COMPLETION = 'CHALLENGE_COMPLETION',
    MULTIPLE_RESET_WATCHED = 'MULTIPLE_RESET_WATCHED',
    ACHIEVEMENT_LEVEL_UP = 'ACHIEVEMENT_LEVEL_UP',
    MULTIPLE_SOCIAL_GROUP_CREATED = 'MULTIPLE_SOCIAL_GROUP_CREATED',
    MULTIPLE_SOCIAL_GROUP_JOINED = 'MULTIPLE_SOCIAL_GROUP_JOINED',
    MULTIPLE_CHALLENGE_COMPLETION = 'MULTIPLE_CHALLENGE_COMPLETION',
    MULTIPLE_CHALLENGE_STREAK = 'MULTIPLE_CHALLENGE_STREAK',
    MULTIPLE_CHALLENGE_JOINED = 'MULTIPLE_CHALLENGE_JOINED',
    MULTIPLE_PERSONALIZED_RESET = 'MULTIPLE_PERSONALIZED_RESET',
    MULTIPLE_ACHIEVEMENT_LEVEL_UP = 'MULTIPLE_ACHIEVEMENT_LEVEL_UP',
    STEPS_DAILY_ACTION_COMPLETED = 'STEPS_DAILY_ACTION_COMPLETED',
    COMMUNITY_DAY_COMPLETED = 'COMMUNITY_DAY_COMPLETED',
    MULTIPLE_COMMUNITY_DAY_COMPLETED = 'MULTIPLE_COMMUNITY_DAY_COMPLETED',
    DAILY_ACTION_COMPLETED = 'DAILY_ACTION_COMPLETED',
    MULTIPLE_DAILY_ACTION_COMPLETED = 'MULTIPLE_DAILY_ACTION_COMPLETED'
}
