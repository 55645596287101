import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useGetTotalDailyGoalsCompletedQuery } from '../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../withCompanyChallengeProvider'
import TotalDailyGoalsCompletedContext, {
    TotalDailyGoalsCompletedProviderValue
} from './totalDailyGoalsCompletedContext'

export default function withTotalDailyGoalsCompletedProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [challengeId, setChallengeId] = useState('')

        const { loading: challengeLoading, challenge } =
            useCompanyChallengeProviderContext()

        const { loading, data, error, refetch } =
            useGetTotalDailyGoalsCompletedQuery({
                variables: {
                    challengeId: challengeId
                },
                skip: challengeLoading || !challengeId
            })

        useEffect(() => {
            if (challenge) {
                setChallengeId(challenge?.id)
            }
        }, [challenge])

        const state = useMemo<TotalDailyGoalsCompletedProviderValue>(
            () => ({
                loading,
                totalGoalsCompleted:
                    data?.unifiedChallenges
                        ?.communityChallengeTotalDailyGoalsCompleted,
                error: Boolean(error),
                setChallengeId,
                refetch
            }),
            [loading, data, error, refetch]
        )

        return (
            <TotalDailyGoalsCompletedContext.Provider value={state}>
                <Component {...props} />
            </TotalDailyGoalsCompletedContext.Provider>
        )
    }
}
