import {
    GroupChallengeType,
    UnifiedChallenge
} from '../graphql/generated/autogenerated'
import { ChallengeType } from '../shared'

/**
 * Retrieves the challenge type from a UnifiedChallenge object.
 */
export const getChallengeType = (challenge: UnifiedChallenge) => {
    // Get the challenge type from the active challenge
    let challengeType = challenge.challenge_type as ChallengeType

    // If the challenge is a group challenge and the group challenge type is personal,
    // change the challenge type to personal
    if (challengeType === ChallengeType.group) {
        if (challenge.groupChallengeType === GroupChallengeType.Personal) {
            challengeType = ChallengeType.personal
        }
    }

    return challengeType
}
