import {
    ErrorBoundary,
    FallbackProps,
    FullAppFallback,
    type ErrorBoundaryProps
} from '@thriveglobal/thrive-web-errors'
import React from 'react'
import { MFEFallback } from '../../fallback/MFEFallback'

export const withErrorBoundary = <P extends object>(
    boundaryName: ErrorBoundaryProps['boundaryName'],
    boundaryType: ErrorBoundaryProps['boundaryType'],
    Component: React.FunctionComponent<P>,
    fallbackComponent?: React.ComponentType<FallbackProps>
) => {
    return (props: P) => (
        <ErrorBoundary
            boundaryName={boundaryName}
            boundaryType={boundaryType}
            packageName={process.env.APP_NAME as string}
            packageOwner={process.env.PROJECT_OWNER as string}
            packageVersion={process.env.APP_VERSION as string}
            fallbackComponent={
                fallbackComponent ?? boundaryType === 'Page'
                    ? FullAppFallback
                    : MFEFallback
            }
        >
            <Component {...props} />
        </ErrorBoundary>
    )
}
