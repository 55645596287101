import orderBy from 'lodash/orderBy'
import { ComponentType, useEffect, useMemo, useState } from 'react'
import {
    ChallengeUserDailyGoal,
    GoalType,
    StepsGoalDetail,
    useCommunityChallengeUserDailyGoalsQuery
} from '../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../withCompanyChallengeProvider'
import CompanyDailyGoalsProviderContext, {
    CompanyDailyGoalsValue
} from './companyDailyGoalsProviderContext'

export default function withCompanyDailyGoalsProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [challengeId, setChallengeId] = useState('')

        // defaults canFirePostJoinQueries to true outside of the company challenge pages
        const { challenge, canFirePostJoinQueries } =
            useCompanyChallengeProviderContext()

        const { loading, data, error, refetch } =
            useCommunityChallengeUserDailyGoalsQuery({
                variables: { challengeId },
                skip: !challengeId || !canFirePostJoinQueries
            })

        useEffect(() => {
            if (challenge) {
                setChallengeId(challenge?.id)
            }
        }, [challenge])

        const { activityGoal, goals, totalUserPlants, plant } = useMemo(
            () => ({
                activityGoal: (
                    data?.unifiedChallenges?.communityChallengeUserDailyGoals?.find(
                        (goal) => goal.challengeGoal.goalType == GoalType.Steps
                    )?.goalEntityDetail as StepsGoalDetail
                )?.steps,
                goals: orderBy(
                    data?.unifiedChallenges
                        ?.communityChallengeUserDailyGoals as ChallengeUserDailyGoal[],
                    ['challengeGoal.position'],
                    ['asc']
                ),
                totalUserPlants:
                    data?.retail?.getUserCommunityChallengePlants?.length,
                plant: data?.retail?.getUserCommunityChallengePlants?.reduce(
                    (a: any, b) => (a?.updatedAt > b?.updatedAt ? a : b),
                    null
                )
            }),
            [data]
        )

        const state = useMemo<CompanyDailyGoalsValue>(
            () => ({
                loading,
                activityGoal,
                goals,
                totalUserPlants: Number(totalUserPlants),
                plant,
                error: Boolean(error),
                setChallengeId,
                refetch
            }),
            [
                loading,
                activityGoal,
                goals,
                totalUserPlants,
                plant,
                error,
                setChallengeId,
                refetch
            ]
        )

        return (
            <CompanyDailyGoalsProviderContext.Provider value={state}>
                <Component {...props} />
            </CompanyDailyGoalsProviderContext.Provider>
        )
    }
}
