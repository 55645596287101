import { Box, Stack } from '@mui/material'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'

export interface ChallengeResultsTitleProps {
    hasCheckedInToday: boolean
    completedChallenge: boolean
    hideMainTitle?: boolean
    hideSubTitle?: boolean
    action?: React.ReactNode
}

const messages = defineMessages({
    checkInToday: {
        defaultMessage: 'Check in today',
        description: 'subtitle description on check in progress'
    },
    todayIsComplete: {
        defaultMessage: 'Today is complete',
        description: 'subtitle description on check in progress'
    },
    yourProgress: {
        defaultMessage: 'Your progress',
        description: 'title description on check in progress'
    },
    yourResults: {
        defaultMessage: 'Your results',
        description: 'title description on check in results'
    }
})

const ChallengeResultsTitle: React.FC<ChallengeResultsTitleProps> = ({
    hasCheckedInToday,
    completedChallenge,
    hideMainTitle,
    hideSubTitle,
    action
}) => {
    const theme = useTheme()
    const intl = useIntl()

    return (
        <Stack
            mb={1.5}
            data-testid="challenge-results-title"
            direction="row"
            justifyContent="space-between"
            gap={1}
            alignItems="center"
        >
            <Stack>
                {!hideMainTitle && (
                    <CoreTypography variant="h2" mb={2}>
                        {completedChallenge
                            ? intl.formatMessage(messages.yourResults)
                            : intl.formatMessage(messages.yourProgress)}
                    </CoreTypography>
                )}

                {!completedChallenge && !hideSubTitle && (
                    <Box data-testid="challenge-results-sub-title">
                        {hasCheckedInToday ? (
                            <Stack direction="row">
                                <LeafIcon
                                    icon={'circle-check'}
                                    color={'accent'}
                                    sx={{
                                        mr: theme.spacing(1)
                                    }}
                                />
                                <CoreTypography
                                    variant="h4"
                                    component="p"
                                    color="accent.main"
                                >
                                    {intl.formatMessage(
                                        messages.todayIsComplete
                                    )}
                                </CoreTypography>
                            </Stack>
                        ) : (
                            <CoreTypography variant="h4" component="p">
                                {intl.formatMessage(messages.checkInToday)}
                            </CoreTypography>
                        )}
                    </Box>
                )}
            </Stack>
            {action}
        </Stack>
    )
}

export default ChallengeResultsTitle
