import { Box, Grid } from '@mui/material'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import React, { useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { CloseModalIcon } from '../../elements'

export interface CelebrationScreenProps {
    headerBackground?: string
    celebration?: React.ReactNode
    title: string
    description?: React.ReactNode
    primaryActionText?: string
    secondaryActionText?: string
    onPrimaryAction?: () => Promise<any>
    onSecondaryAction?: () => Promise<any>
    handleOnClose: () => void
}

const messages = defineMessages({
    closeCelebrationAria: {
        defaultMessage: 'Close {celebrationName}',
        description: 'aria label for a button that will close the celebration'
    }
})

const CelebrationScreen: React.FC<CelebrationScreenProps> = ({
    headerBackground,
    celebration,
    title,
    description,
    primaryActionText,
    secondaryActionText,
    onPrimaryAction,
    onSecondaryAction,
    handleOnClose
}) => {
    const intl = useIntl()
    const [primaryActionLoading, setPrimaryActionLoading] = useState(false)
    const [secondaryActionLoading, setSecondaryActionLoading] = useState(false)

    const onActionClick = async (
        onAction: () => Promise<any>,
        loadingAction: (loading: boolean) => void
    ) => {
        loadingAction(true)

        await onAction().then((resolve) => {
            loadingAction(false)
            resolve?.()
        })
    }

    return (
        <Grid container>
            <Grid
                item
                xs={12}
                sx={{
                    position: 'relative',
                    overflow: 'hidden'
                }}
            >
                <Grid
                    container
                    sx={{ background: headerBackground }}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            sx={{
                                position: 'relative',
                                zIndex: 100
                            }}
                        >
                            <CloseModalIcon
                                onClick={handleOnClose}
                                ariaLabel={intl.formatMessage(
                                    messages.closeCelebrationAria,
                                    {
                                        celebrationName: title
                                    }
                                )}
                            />
                        </Box>
                        {celebration}
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                        >
                            <CoreTypography
                                variant="h3"
                                sx={{
                                    mt: 4,
                                    mx: 4,
                                    textAlign: 'center'
                                }}
                            >
                                {title}
                            </CoreTypography>
                            {!!description && (
                                <Box mt={2} mx={4}>
                                    {description}
                                </Box>
                            )}
                            {onPrimaryAction && (
                                <LoadingButton
                                    loading={primaryActionLoading}
                                    disabled={
                                        primaryActionLoading ||
                                        secondaryActionLoading
                                    }
                                    fixWidth={true}
                                    variant="contained"
                                    onClick={() =>
                                        onActionClick(
                                            onPrimaryAction,
                                            setPrimaryActionLoading
                                        )
                                    }
                                    sx={{
                                        my: 3
                                    }}
                                    data-testid="primary-action-button"
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        {primaryActionText}
                                    </CoreTypography>
                                </LoadingButton>
                            )}
                            {onSecondaryAction && (
                                <LoadingButton
                                    loading={secondaryActionLoading}
                                    disabled={
                                        primaryActionLoading ||
                                        secondaryActionLoading
                                    }
                                    fixWidth={true}
                                    variant="text"
                                    onClick={() =>
                                        onActionClick(
                                            onSecondaryAction,
                                            setSecondaryActionLoading
                                        )
                                    }
                                    sx={{
                                        mb: 4
                                    }}
                                    data-testid="secondary-action-button"
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        {secondaryActionText}
                                    </CoreTypography>
                                </LoadingButton>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CelebrationScreen
