import { Divider, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UnifiedChallenge } from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeStates } from '../../../../hooks/useCompanyChallengeStates/useCompanyChallengeStates'

export type NotStartedStatsProps = {
    challenge: UnifiedChallenge
}

const messages = defineMessages({
    daysUntilStart: {
        defaultMessage: `<desc>Get ready to grow! The challenge starts in: </desc><stat>{elapsedTime, plural, one {# day} other {# days}}</stat>`,
        description:
            'description of the remaining days left before the company challenge starts'
    }
})

const NotStartedStats: React.FC<NotStartedStatsProps> = ({ challenge }) => {
    const { formatMessage } = useIntl()
    const calculateChallengeStates = useCompanyChallengeStates()

    const { elapsedTime } = useMemo(
        () => calculateChallengeStates(challenge),
        [calculateChallengeStates, challenge]
    )

    return (
        <Stack gap={2}>
            <Divider />
            {formatMessage(messages.daysUntilStart, {
                elapsedTime: Math.abs(elapsedTime),
                desc: (chunks: React.ReactNode[]) => (
                    <CoreTypography variant="overline" color="text.primary">
                        {chunks}
                    </CoreTypography>
                ),
                stat: (chunks: React.ReactNode[]) => (
                    <CoreTypography variant="h3">{chunks}</CoreTypography>
                )
            })}
        </Stack>
    )
}

export default memo(NotStartedStats)
