import { ComponentType, useCallback, useEffect, useMemo, useState } from 'react'
import {
    UnifiedChallenge,
    useCommunityChallengeUserStepsLazyQuery
} from '../../../graphql/generated/autogenerated'
import useChallengeTheme from '../../useChallengeTheme/useChallengeTheme'
import { useCompanyChallengeProviderContext } from '../../withCompanyChallengeProvider'
import ChallengeUserStepsProviderContext, {
    ChallengeUserStepsProviderValue
} from './challengeUserStepsContext'

export default function withChallengeUserStepsProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [challenge, setChallenge] = useState<UnifiedChallenge>()
        const [loading, setLoading] = useState(true)
        const [todaySteps, setTodaySteps] = useState<number>(0)
        const [totalSteps, setTotalSteps] = useState<number>(0)

        const { deviceEnabled } = useChallengeTheme(challenge)

        const { challenge: companyChallenge, canFirePostJoinQueries } =
            useCompanyChallengeProviderContext()

        const [
            fetchCommunityChallengeUserSteps,
            { data: userStepData, error, refetch: refetchUserSteps }
        ] = useCommunityChallengeUserStepsLazyQuery({
            variables: { challengeId: challenge?.id }
        })

        useEffect(() => {
            if (companyChallenge) {
                setChallenge(companyChallenge)
            }
        }, [companyChallenge])

        const setSteps = useCallback((data: any) => {
            const steps =
                data?.data?.unifiedChallenges?.communityChallengeUserSteps

            if (steps) {
                setTodaySteps(steps.todaySteps)
                setTotalSteps(steps.totalSteps)
            }
        }, [])

        useEffect(() => {
            const steps =
                userStepData?.unifiedChallenges?.communityChallengeUserSteps
            if (steps) {
                setTodaySteps(steps.todaySteps)
                setTotalSteps(steps.totalSteps)
            }
        }, [userStepData])

        useEffect(() => {
            // only fetch activity data when device is enabled
            if (challenge && deviceEnabled && canFirePostJoinQueries) {
                setLoading(true)
                fetchCommunityChallengeUserSteps()
                    .then((data) => {
                        setSteps(data)
                    })
                    .finally(() => setLoading(false))
            }
        }, [
            challenge,
            deviceEnabled,
            canFirePostJoinQueries,
            setSteps,
            fetchCommunityChallengeUserSteps
        ])

        const refetch = useCallback(
            (loading?: boolean) => {
                setLoading(Boolean(loading))

                return refetchUserSteps()
                    .then((data) => {
                        setSteps(data)
                    })
                    .finally(() => setLoading(false))
            },
            [setSteps, refetchUserSteps]
        )

        const state = useMemo<ChallengeUserStepsProviderValue>(
            () => ({
                loading,
                error: Boolean(error),
                todaySteps: todaySteps,
                totalSteps: totalSteps,
                setChallenge,
                refetch
            }),
            [todaySteps, totalSteps, error, loading, setChallenge, refetch]
        )

        return (
            <ChallengeUserStepsProviderContext.Provider value={state}>
                <Component {...props} />
            </ChallengeUserStepsProviderContext.Provider>
        )
    }
}
