import { Box, Stack } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { UnifiedChallengeParticipation } from '../../../../../graphql/generated/autogenerated'
import {
    addDays,
    diffDays,
    format,
    parseDate
} from '@thriveglobal/thrive-web-core'
import {
    ConfettiExplosion,
    useTheme,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    checkedIn: {
        defaultMessage: `checked in on day {dayNumber}`,
        description:
            'shows if the user checked in on that day and had a streak E.G checked in on day 5'
    },
    streak: {
        defaultMessage: `check in streak on day {dayNumber}`,
        description:
            'shows if the user checked in on that day E.G check in streak on day 5'
    },
    noCheckIn: {
        defaultMessage: `no check in on day {dayNumber}`,
        description:
            'shows if the user did not check in on that day day E.G no check in on day 5'
    }
})

export interface ProgressBarProps {
    challengeParticipation: UnifiedChallengeParticipation
    uniqueCheckInDates: Date[]
    completedChallenge: boolean
    checkInTriggered: boolean
    currentStreak: number
}

export type BarType = {
    dataTestId: string
    color: string
    message: MessageDescriptor
}

export type BarConfiguration = {
    height: string
    width: string
}

export type BarDay = {
    type: barTypes
    showArrow: boolean
    showConfetti?: boolean
}

export enum barTypes {
    checkedIn = 'checkedIn',
    streak = 'streak',
    pending = 'pending'
}

const ProgressBar: React.FC<ProgressBarProps> = ({
    challengeParticipation,
    uniqueCheckInDates,
    completedChallenge,
    checkInTriggered,
    currentStreak
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const [showConfetti, setShowConfetti] = useState(false)
    const PROGRESS_INDICATOR_WIDTH = '24px'

    const barData: {
        [key in barTypes]: BarType
    } = {
        [barTypes.checkedIn]: {
            dataTestId: 'checkedin-bar',
            color: theme.palette.accent.main,
            message: messages.checkedIn
        },
        [barTypes.streak]: {
            dataTestId: 'streak-bar',
            color: theme.palette.error.main,
            message: messages.streak
        },
        [barTypes.pending]: {
            dataTestId: 'pending-bar',
            color: theme.palette.grey[500],
            message: messages.noCheckIn
        }
    }

    // depending on the duration of the challenge the progressbar bars shape will change
    const longChallenge = useMemo(
        () => challengeParticipation?.challenge?.duration > 7,
        [challengeParticipation]
    )

    const barConfiguration = useMemo<BarConfiguration>(
        () => ({
            height: longChallenge ? theme.spacing(4) : theme.spacing(1),
            width: longChallenge ? theme.spacing(3) : theme.spacing(10)
        }),
        [longChallenge, theme]
    )

    const shouldShowArrow = useCallback(
        (i: any, daysSinceStart: any, isLastItem: any) => {
            return (
                (i === daysSinceStart && !completedChallenge) ||
                (completedChallenge && isLastItem)
            )
        },
        [completedChallenge]
    )

    const bars = useMemo<BarDay[]>(() => {
        const arr: any[] = []
        const join = challengeParticipation?.participation[0]?.join
        const days = []

        if (join) {
            // TODO: currently join date is returning a datetime, but ideally it is a
            // YYYY-MM-DD formatted date. Once this has been updated we should remove
            // the additional hack of separating the datetime string here before parsing
            const joinDate = parseDate(join.split('T')[0])
            const daysSinceStart = diffDays(
                parseDate(format(new Date())),
                joinDate
            )

            for (
                let i = 0;
                i < challengeParticipation?.challenge?.duration;
                i++
            ) {
                const newDay = format(addDays(joinDate, i))
                days.push(newDay)
            }

            days.map((d, i) => {
                const showArrow = shouldShowArrow(
                    i,
                    daysSinceStart,
                    i === days.length
                )
                if (
                    uniqueCheckInDates.some(
                        (uniqueCheckInDate) => d == uniqueCheckInDate.toString()
                    )
                ) {
                    if (
                        i >= daysSinceStart - currentStreak &&
                        currentStreak !== 0
                    ) {
                        arr.push({
                            type: barTypes.streak,
                            showArrow: showArrow,
                            showConfetti: showConfetti && showArrow
                        })
                    } else {
                        arr.push({
                            type: barTypes.checkedIn,
                            showArrow: showArrow
                        })
                    }
                } else {
                    arr.push({
                        type: barTypes.pending,
                        showArrow: showArrow
                    })
                }
            })
        }

        return arr
    }, [
        challengeParticipation,
        uniqueCheckInDates,
        currentStreak,
        showConfetti,
        shouldShowArrow
    ])

    useEffect(() => {
        setShowConfetti(checkInTriggered)
    }, [checkInTriggered])

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            data-testid="progress-bar-items"
        >
            {bars?.map((bar, index: number) => (
                <Stack
                    key={index}
                    aria-label={formatMessage(barData[bar.type].message, {
                        dayNumber: index + 1
                    })}
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexGrow: 1,
                        maxWidth: barConfiguration.width,
                        minWidth: theme.spacing(0.5),
                        pt: theme.spacing(2),
                        border: '1px transparent solid'
                    }}
                    data-testid="bar-item"
                >
                    {bar.showArrow && (
                        <LeafIcon
                            icon={'caret-down'}
                            iconStyle={'solid'}
                            color={'primary'}
                            sx={{
                                position: 'absolute',
                                top: '-8px',
                                left: `calc( -1 * ${PROGRESS_INDICATOR_WIDTH} / 2)`,
                                right: `calc( -1 * ${PROGRESS_INDICATOR_WIDTH} / 2 + .5px)`,
                                margin: 'auto',
                                width: { PROGRESS_INDICATOR_WIDTH }
                            }}
                        />
                    )}
                    <Box
                        sx={{
                            position: 'absolute',
                            right: '50%'
                        }}
                    >
                        <ConfettiExplosion
                            show={Boolean(showConfetti && bar.showConfetti)}
                            size="small"
                            onComplete={() => {
                                setShowConfetti(false)
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            px: theme.spacing(0.5)
                        }}
                    >
                        <Box
                            data-testid={barData[bar.type].dataTestId}
                            sx={{
                                minWidth: theme.spacing(0.25),
                                height: barConfiguration.height,
                                backgroundColor: barData[bar.type].color,
                                borderRadius: theme.spacing(1)
                            }}
                        />
                    </Box>
                </Stack>
            ))}
        </Stack>
    )
}

export default ProgressBar
