import { Box } from '@mui/material'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import LinkArrowRight from '../../../../components/elements/LinkArrowRight/LinkArrowRight'
import { ROUTES } from '../../../../routes'

const messages = defineMessages({
    viewPastChallenge: {
        defaultMessage: 'View your past Challenges',
        description: 'link to view users past challenges'
    }
})

const ViewPastChallenges: React.FC = () => {
    const { formatMessage } = useIntl()

    return (
        <Box sx={{ whiteSpace: 'nowrap' }}>
            <LinkArrowRight
                linkText={formatMessage(messages.viewPastChallenge)}
                pathName={ROUTES.PAST_CHALLENGES}
            />
        </Box>
    )
}

export default memo(ViewPastChallenges)
