import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import ConditionalSkeleton from '../../ConditionalSkeleton/ConditionalSkeleton'

export type StatTitleProps = {
    stat: string | number
    title: string
    loading?: boolean
}

const StatTitle: React.FC<StatTitleProps> = ({ stat, title, loading }) => (
    <Stack gap={0.5} data-testid="stat-description" width="100%">
        <ConditionalSkeleton showSkeleton={!!loading} variant="rounded">
            <CoreTypography variant="overline">{title}</CoreTypography>
        </ConditionalSkeleton>
        <ConditionalSkeleton showSkeleton={!!loading} variant="rounded">
            <CoreTypography customVariant="display3">
                {stat ? stat : 0}
            </CoreTypography>
        </ConditionalSkeleton>
    </Stack>
)

export default memo(StatTitle)
