import { Apollo } from '@thriveglobal/thrive-web-core'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    IdentityQuery,
    SocialGroupQuery,
    UnifiedChallenge,
    UnifiedChallengeParticipation,
    UnifiedChallengesQuery,
    useGetTutorialWatchedStatusQuery,
    useSetTutorialWatchedStatusMutation
} from '../../graphql/generated/autogenerated'
import { ChallengeType } from '../../shared/enums/challengeType'
import { GQLNullValue } from '../../shared/utils/Nulls'
import { stripBOM } from '../../shared/utils/bom'
import { useParseStorySubmissionData } from '../storySubmission/useParseStorySubmissionData'
import { CompanyChallengeProviderValue } from './companyChallengeContext'
import useChallengeDataFormatter from './useChallengeDataFormatter'
import useSocialGroupDataFormatter from './useSocialGroupDataFormatter'

export function useCompanyChallengeProviderData(
    challengeSocialGroupQuery: (
        variables: Apollo.QueryHookOptions
    ) => Apollo.QueryResult<any, any>,
    userChallengeTransform: (
        unifiedChallenges: UnifiedChallengesQuery
    ) => UnifiedChallengeParticipation[],
    inProgress?: boolean
) {
    const { challengeId } = useParams<{
        challengeId: string
    }>()

    const [source, setSource] = useState<string>()
    const [setTutorialWatched] = useSetTutorialWatchedStatusMutation({
        variables: {
            status: true
        }
    })

    const { data: tutorialData, loading: tutorialLoading } =
        useGetTutorialWatchedStatusQuery()

    const { data, loading, error, refetch } = challengeSocialGroupQuery({
        variables: {
            challengeId: challengeId ? stripBOM(challengeId) : challengeId,
            inProgress
        },
        fetchPolicy: 'no-cache'
    })

    const { mainCommunitySocialGroup, socialGroup, displayName, companyName } =
        useSocialGroupDataFormatter(
            stripBOM(challengeId),
            data?.socialGroups as SocialGroupQuery,
            data?.identity as IdentityQuery
        )

    const {
        challenge,
        participation,
        deviceEnabled,
        hydrationEnabled,
        sleepEnabled,
        startDate,
        endDate,
        day,
        challengeStarted,
        elapsedTime,
        challengeExpired,
        canFirePostJoinQueries,
        remainingDays
    } = useChallengeDataFormatter(
        challengeId ? stripBOM(challengeId) : challengeId,
        loading,
        data?.unifiedChallenges?.unifiedChallenge as UnifiedChallenge,
        userChallengeTransform(
            data?.unifiedChallenges
        ) as UnifiedChallengeParticipation[],
        socialGroup
    )

    const soloChallenge = useMemo(
        () =>
            challenge?.challenge_type === ChallengeType.group &&
            (!mainCommunitySocialGroup?.memberCount ||
                mainCommunitySocialGroup?.memberCount === 1),
        [challenge, mainCommunitySocialGroup?.memberCount]
    )

    const storyData = useParseStorySubmissionData({
        availableInteraction:
            data?.unifiedChallenges?.availableInteractions?.[0],
        participationId: participation?.id,
        stories: data?.unifiedChallenges?.getStories
    })

    const showAvgLeaderboard = useMemo(
        () =>
            challenge?.maxTeamSize === GQLNullValue ||
            challenge?.maxTeamSize === undefined,
        [challenge?.maxTeamSize]
    )

    return useMemo<CompanyChallengeProviderValue>(
        () => ({
            loading: loading,
            mainCommunitySocialGroup,
            socialGroup,
            displayName: displayName,
            companyName,
            challenge,
            deviceEnabled,
            hydrationEnabled,
            sleepEnabled,
            participation,
            startDate,
            endDate,
            day,
            challengeStarted,
            elapsedTime,
            challengeExpired,
            soloChallenge,
            remainingDays,
            challengeCompleted: !!participation?.completedOn,
            tutorialWatched:
                tutorialLoading ||
                tutorialData?.unifiedChallenges?.getTutorialWatchedStatus,
            canFirePostJoinQueries,
            error: Boolean(error),
            source,
            storyData,
            refetch,
            setSource,
            setTutorialWatched,
            showAvgLeaderboard
        }),
        [
            mainCommunitySocialGroup,
            challengeExpired,
            challengeStarted,
            tutorialLoading,
            participation,
            tutorialData,
            elapsedTime,
            socialGroup,
            displayName,
            companyName,
            challenge,
            deviceEnabled,
            remainingDays,
            hydrationEnabled,
            sleepEnabled,
            startDate,
            loading,
            endDate,
            day,
            source,
            canFirePostJoinQueries,
            error,
            storyData,
            soloChallenge,
            refetch,
            setSource,
            setTutorialWatched,
            showAvgLeaderboard
        ]
    )
}

export default useCompanyChallengeProviderData
