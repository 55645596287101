import { QuestionData } from '../hooks/storySubmission/withStorySubmissionProvider/storySubmissionContext'

export * from './challenge'

export const COMPANY_CHALLENGE_REMAINS_ACTIVE = 10

export const mergeObjects = <T>(destinationObject: T, mergeingObject: T): T => {
    return { ...destinationObject, ...mergeingObject }
}

export function dateStringToTimezoneMidnight(date: string | Date) {
    const newDate = new Date()
    newDate.setTime(
        new Date(date).getTime() +
            new Date(date).getTimezoneOffset() * 60 * 1000
    )

    return newDate
}

export function dateStringToTimezoneMidnightOffset(date: string | Date) {
    const newDate = new Date()
    newDate.setTime(
        new Date(date).getTime() +
            new Date(date).getTimezoneOffset() * 60 * 1000 +
            12 * 60 * 1000
    )

    return newDate
}

export function getDaysFromDateToDate(join: string, to: Date = new Date()) {
    const joinDate = new Date(join)
    const msPerDay = 24 * 60 * 60 * 1000 // Number of milliseconds per day.
    let daysSinceStart =
        (new Date(to).getTime() - joinDate.getTime()) / msPerDay
    daysSinceStart = Math.round(daysSinceStart)

    return daysSinceStart
}

export const sortByordering = (a: QuestionData, b: QuestionData): number => {
    const aOrdering = a.ordering ? a.ordering : -1
    const bordering = b.ordering ? b.ordering : -1

    if (aOrdering > bordering) {
        return 1
    }

    if (aOrdering < bordering) {
        return -1
    }

    return 0
}

export const getInitials = (text: string) => {
    return text?.split(' ')?.reduce((initials, n) => {
        const char = n.charAt(0)

        return RegExp(/^[^a-zA-Z0-9]+$/).test(char)
            ? ''
            : initials + char.toUpperCase()
    }, '')
}

export const getIsInIframe = () => window.self !== window.top

export const getDurationInMinutes = (durationInSeconds: number) =>
    durationInSeconds > 60 ? Math.floor(durationInSeconds / 60) : 1
