import { Card, Skeleton, Stack } from '@mui/material'
import { memo } from 'react'

const ChallengeActiveCardSkeleton: React.FC = () => {
    return (
        <Card>
            <Stack height={273}>
                <Stack
                    gap={1}
                    direction="row"
                    px={2}
                    py={1.125}
                    borderBottom={1}
                    borderColor="divider"
                >
                    <Skeleton variant="rounded" height={20} width={20} />
                    <Skeleton width="50%" height={20} />
                </Stack>
                <Stack p={2} gap={1} flex={1} justifyContent="space-between">
                    <Stack gap={1}>
                        <Skeleton width="25%" height={20} />
                        <Skeleton width="100%" height={24} />
                    </Stack>
                    <Stack gap={1}>
                        <Stack>
                            <Skeleton width={182} height={18} />
                            <Skeleton width={73} height={65} />
                        </Stack>
                        <Stack alignItems="flex-end">
                            <Skeleton
                                variant="rounded"
                                width={139}
                                height={44}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    )
}

export default memo(ChallengeActiveCardSkeleton)
