import { Box } from '@mui/material'
import React, { ReactNode } from 'react'

export type DoubleActionHeaderProps = {
    leftAction?: ReactNode | ReactNode[]
    rightAction?: ReactNode | ReactNode[]
}

const DoubleActionHeader: React.FC<DoubleActionHeaderProps> = ({
    leftAction,
    rightAction
}) => {
    return (
        <Box display="flex" justifyContent="space-between">
            <Box>{leftAction}</Box>
            <Box>{rightAction}</Box>
        </Box>
    )
}

export default React.memo(DoubleActionHeader)
