import { useMemo } from 'react'
import {
    SocialGroup,
    UnifiedChallenge,
    UnifiedChallengeParticipation
} from '../../graphql/generated/autogenerated'
import { useCompanyChallengeStates } from '../../shared/hooks/useCompanyChallengeStates/useCompanyChallengeStates'
import useChallengeTheme from '../useChallengeTheme/useChallengeTheme'

export interface ChallengeData {
    startDate: Date | undefined
    endDate: Date | undefined
    day: number
    challengeStarted: boolean
    elapsedTime: number
    challengeExpired: boolean | undefined
    canFirePostJoinQueries: boolean
    remainingDays: number
}

export const useChallengeDataFormatter = (
    challengeId: string,
    loading: boolean,
    unifiedChallenge?: UnifiedChallenge,
    userChallenges?: UnifiedChallengeParticipation[],
    socialGroup?: SocialGroup
) => {
    const calculateChallengeStates = useCompanyChallengeStates()

    const { challenge, participation } = useMemo(() => {
        const userChallenge = userChallenges?.find(
            (userChallenge) => userChallenge?.challenge?.id === challengeId
        ) as UnifiedChallengeParticipation

        const challenge = userChallenge?.challenge
            ? userChallenge?.challenge
            : unifiedChallenge

        return {
            challenge: challenge as UnifiedChallenge,
            participation: userChallenge?.participation?.[0]
        }
    }, [unifiedChallenge, userChallenges, challengeId])

    const { deviceEnabled, hydrationEnabled, sleepEnabled } =
        useChallengeTheme(challenge)

    const {
        startDate,
        endDate,
        day,
        challengeStarted,
        elapsedTime,
        challengeExpired,
        canFirePostJoinQueries,
        remainingDays
    } = useMemo<ChallengeData>(() => {
        const props = calculateChallengeStates(challenge)
        return {
            canFirePostJoinQueries:
                !!challenge &&
                !!socialGroup &&
                props.challengeStarted &&
                !loading,
            ...props
        }
    }, [calculateChallengeStates, challenge, socialGroup, loading])

    return {
        day,
        endDate,
        startDate,
        challenge,
        elapsedTime,
        remainingDays,
        participation,
        deviceEnabled,
        userChallenges,
        hydrationEnabled,
        sleepEnabled,
        challengeStarted,
        challengeExpired,
        canFirePostJoinQueries
    }
}

export default useChallengeDataFormatter
