import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { useChallengeTypeIcon } from '../../../hooks/useChallengeTypeIcon/useChallengeTypeIcon'
import { ChallengeType } from '../../../shared/enums/challengeType'

export type ChallengeTypeHeaderProps = {
    challengeType: ChallengeType
}

const ChallengeTypeHeader: React.FC<ChallengeTypeHeaderProps> = ({
    challengeType
}) => {
    const { icon, title } = useChallengeTypeIcon(challengeType)

    return (
        <Stack
            gap={1}
            px={2}
            py={1.125}
            direction="row"
            borderBottom={1}
            borderColor="divider"
        >
            <Stack height={20} width={20}>
                {icon}
            </Stack>
            <CoreTypography variant="overline" color="text.secondary">
                {title}
            </CoreTypography>
        </Stack>
    )
}

export default memo(ChallengeTypeHeader)
