import {
    Switch,
    useHistory,
    useLocation,
    Redirect,
    Route
} from 'react-router-dom'
import {
    CoreTab,
    CoreTypography,
    PageLayout
} from '@thriveglobal/thrive-web-leafkit'
import { Box, Tabs } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { CHALLENGES_ROUTES } from '../../constants'

import MyChallengesTab from './MyChallenges'
import CompletedChallengesTab from './Completed'
import DiscoverChallengesTab from './Discover'

const Challenges = () => {
    const history = useHistory()
    const location = useLocation()

    const handleTabChange = (tab: string) => {
        history.push(tab)
    }

    return (
        <PageLayout>
            <Box sx={{ py: 4 }}>
                <CoreTypography variant="h1">
                    <FormattedMessage
                        defaultMessage="Challenges"
                        description="Challenges page title"
                    />
                </CoreTypography>
                <Tabs
                    sx={{ mt: 2 }}
                    onChange={(_, value) => handleTabChange(value)}
                    value={location.pathname}
                >
                    <CoreTab
                        label={
                            <FormattedMessage
                                defaultMessage="Discover"
                                description="Discover challenges tab label"
                            />
                        }
                        value={CHALLENGES_ROUTES.DISCOVER_CHALLENGES}
                    />
                    <CoreTab
                        label={
                            <FormattedMessage
                                defaultMessage="My Challenges"
                                description="My challenges tab label"
                            />
                        }
                        value={CHALLENGES_ROUTES.MY_CHALLENGES}
                    />
                    <CoreTab
                        label={
                            <FormattedMessage
                                defaultMessage="Completed"
                                description="Completed challenges tab label"
                            />
                        }
                        value={CHALLENGES_ROUTES.COMPLETED_CHALLENGES}
                    />
                </Tabs>
                <Switch>
                    <Route
                        exact
                        path={CHALLENGES_ROUTES.MY_CHALLENGES}
                        component={MyChallengesTab}
                    />
                    <Route
                        exact
                        path={CHALLENGES_ROUTES.COMPLETED_CHALLENGES}
                        component={CompletedChallengesTab}
                    />
                    <Route
                        exact
                        path={CHALLENGES_ROUTES.DISCOVER_CHALLENGES}
                        component={DiscoverChallengesTab}
                    />
                    <Redirect
                        exact
                        from={CHALLENGES_ROUTES.CHALLENGES}
                        to={CHALLENGES_ROUTES.DISCOVER_CHALLENGES}
                    />
                </Switch>
            </Box>
        </PageLayout>
    )
}

export default Challenges
