import { useMemo } from 'react'
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    years: {
        defaultMessage:
            '{timeSince, plural, one {last year} other {# years ago}}',
        description: 'time since activity was posted in years'
    },
    months: {
        defaultMessage:
            '{timeSince, plural, one {last month} other {# months ago}}',
        description: 'time since activity was posted in months'
    },
    weeks: {
        defaultMessage:
            '{timeSince, plural, one {last week} other {# weeks ago}}',
        description: 'time since activity was posted in weeks'
    },
    days: {
        defaultMessage:
            '{timeSince, plural, one {yesterday} other {# days ago}}',
        description: 'time since activity was posted in days'
    },
    hours: {
        defaultMessage:
            '{timeSince, plural, one {# hour ago} other {# hours ago}}',
        description: 'time since activity was posted in hours'
    },
    minutes: {
        defaultMessage:
            '{timeSince, plural, one {# minute ago} other {# minutes ago}}',
        description: 'time since activity was posted in minutes'
    },
    seconds: {
        defaultMessage:
            '{timeSince, plural, =0 {now} one {now} other {# seconds ago}}',
        description: 'time since activity was posted in seconds'
    }
})

export const useGetTimeSince = (date: Date) => {
    const intl = useIntl()

    return useMemo(() => {
        const divisions = [
            { amount: 60, name: 'seconds' },
            { amount: 60, name: 'minutes' },
            { amount: 24, name: 'hours' },
            { amount: 7, name: 'days' },
            { amount: 4.34524, name: 'weeks' },
            { amount: 12, name: 'months' },
            { amount: Number.POSITIVE_INFINITY, name: 'years' }
        ]
        let duration = ((date as any) - Date.now()) / 1000

        for (let i = 0; i <= divisions.length; i++) {
            const division = divisions[i]
            if (Math.abs(duration) < division.amount) {
                return intl.formatMessage(
                    (messages as { [key: string]: MessageDescriptor })[
                        division.name
                    ],
                    {
                        timeSince: Math.abs(Math.round(duration))
                    }
                )
            }
            duration /= division.amount
        }
    }, [date, intl])
}

export default useGetTimeSince
