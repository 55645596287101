import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { UnifiedChallenge } from '../../graphql/generated/autogenerated'
import { ChallengeType } from '../../shared/enums/challengeType'

const messages = defineMessages({
    prizeChallenge: {
        defaultMessage: 'prize challenge',
        description: 'prize challenge title'
    },
    multiJourney: {
        defaultMessage: 'multi-journey',
        description: 'multiple journey title'
    }
})

export const useGetCategory = (challenge?: UnifiedChallenge) => {
    const { locale, formatMessage } = useIntl()

    return useMemo(() => {
        if (challenge?.journeys && challenge.journeys.length > 1) {
            return formatMessage(messages.multiJourney)?.toLocaleUpperCase(
                locale
            )
        } else if (challenge?.journeys && challenge.journeys.length > 0) {
            return challenge.journeys[0].shortName?.toLocaleUpperCase(locale)
        }

        switch (challenge?.type?.toUpperCase()) {
            case ChallengeType.company:
            case ChallengeType.group:
            case ChallengeType.journey: {
                return challenge?.category?.shortName
                    ? challenge?.category?.shortName?.toLocaleUpperCase(locale)
                    : challenge?.category?.name?.toLocaleUpperCase(locale)
            }
            case ChallengeType.biotype: {
                return challenge?.biotype?.name?.toLocaleUpperCase(locale)
            }
            case ChallengeType.prize: {
                return formatMessage(
                    messages.prizeChallenge
                )?.toLocaleUpperCase(locale)
            }
        }
    }, [challenge, locale, formatMessage])
}

export default useGetCategory
