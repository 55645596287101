import { Stack } from '@mui/material'
import { getCheckInInformation } from '@thriveglobal/thrive-web-core'
import React, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { StatTitle } from '../../../../../components/elements/Stats'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../../../graphql/generated/autogenerated'
import CompletedStats from '../ChallengeCardStats/CompletedStats'

export type ChallengeCardPastDetailsProps = {
    participation?: UnifiedChallengeActivity
    challenge: UnifiedChallenge
    isCompanyChallenge?: Boolean
    isGroupChallenge?: boolean
}

const messages = defineMessages({
    totalActive: {
        defaultMessage: 'total active days',
        description:
            'description text for the total number of days the user has been active in a challenge, the number is shown above the text'
    },
    totalCheckIns: {
        defaultMessage: 'total check-ins',
        description:
            'description text for the total number of microstep check-ins the user has completed in a challenge, the number is shown above the text'
    },
    challengeAriaLabel: {
        defaultMessage: 'previously completed {challengeName}',
        description: 'description text for navigating to completed challenge'
    }
})

const ChallengeCardPastDetails: React.FC<ChallengeCardPastDetailsProps> = ({
    participation,
    challenge,
    isCompanyChallenge,
    isGroupChallenge
}) => {
    const { formatMessage } = useIntl()
    const { uniqueCheckInDates, totalCheckinsCount } = useMemo(
        () => getCheckInInformation(participation?.microstepActivities ?? []),
        [participation]
    )

    return isCompanyChallenge || isGroupChallenge ? (
        <CompletedStats challenge={challenge} />
    ) : (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
            <StatTitle
                stat={uniqueCheckInDates.length}
                title={formatMessage(messages.totalActive)}
            />
            <StatTitle
                stat={totalCheckinsCount}
                title={formatMessage(messages.totalCheckIns)}
            />
        </Stack>
    )
}

export default React.memo(ChallengeCardPastDetails)
