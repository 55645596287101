import { Button, LinearProgress, Stack } from '@mui/material'
import {
    getCheckInInformation,
    getIsChallengeComplete
} from '@thriveglobal/thrive-web-core'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import { memo, useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { generatePath, useHistory } from 'react-router-dom'
import { ChallengeType } from '../../../..'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity,
    UnifiedStreak
} from '../../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../../routes'
import { getDaysFromDateToDate } from '../../../../../utils'

const messages = defineMessages({
    challengeDuration: {
        defaultMessage: `{duration, plural, one {# Day} other {# Days}}`,
        description: 'total duration of the challenge'
    },
    totalParticipants: {
        defaultMessage: `{totalParticipants, plural, one {# Participant} other {# Participants}}`,
        description: 'total number of participants in this challenge'
    },
    completedChallenge: {
        defaultMessage: 'completed challenge',
        description: 'Navigate to Completed Challenge Button'
    },
    currentChallenge: {
        defaultMessage: 'current challenge',
        description: 'Navigate to Active Challenge Button'
    },
    currenStreak: {
        defaultMessage: 'day current streak',
        description: 'info showing a users current check in streak'
    },
    highestStreak: {
        defaultMessage: 'highest streak',
        description: 'info showing a users highest check in streak'
    },
    totalCheckIns: {
        defaultMessage: 'Total Check-ins',
        description: 'total check-ins text'
    },
    daysToGo: {
        defaultMessage: `{remainingDays, plural, one {# day left} other {# days left}}`,
        description:
            'description of the remaining days left before completing the challenge'
    },
    seeResult: {
        defaultMessage: 'See the result',
        description: 'button text when navigating to view a completed challenge'
    },
    keepGoing: {
        defaultMessage: `Keep going`,
        description:
            'button text when navigating to a currently active challenge'
    },
    prizeChallengeActiveText: {
        defaultMessage: `You're on a roll! Keep up your Microsteps to make better choices and submit your story at the end of the challenge for a chance to win cash prizes.`,
        description:
            'description text that explains a user is eligible for a prize on completing this challenge'
    },
    prizeChallengeCompleteText: {
        defaultMessage: `You did it! Making better choices every day can really add up. Submit your story for a chance to win cash prizes.`,
        description:
            'description text that explains a user should submit a story on the challenge they just completed'
    },
    challengeProgressAria: {
        defaultMessage: `Progress of {challengeName}`,
        description: 'label describing context of the challenge progress bar'
    },
    keepGoingOnChallenge: {
        defaultMessage: `keep going on {challengeName}`,
        description: 'aria label for the challenge'
    },
    seeResultsForChallenge: {
        defaultMessage: `see results for {challengeName}`,
        description: 'aria label for the challenge'
    }
})

export function getIsActiveStreak(streak: UnifiedStreak) {
    return streak?.currentStreak >= 2
}

export function getActiveStreak(streak: UnifiedStreak, activeStreak = false) {
    if (streak) {
        return activeStreak ? streak.currentStreak : streak.maxStreak
    }

    return 0
}

export interface ChallengeCardDetailsProps {
    challenge: UnifiedChallenge
    participation?: UnifiedChallengeActivity
    isActive?: boolean
    isTemplate?: boolean
}

const ChallengeCardDetails: React.FC<ChallengeCardDetailsProps> = ({
    challenge,
    participation,
    isActive,
    isTemplate
}) => {
    const { formatMessage } = useIntl()
    const history = useHistory()

    const { totalCheckinsCount, hasCheckedInToday } = useMemo(
        () => getCheckInInformation(participation?.microstepActivities ?? []),
        [participation]
    )

    const challengeCompleted = useMemo(
        () =>
            getIsChallengeComplete(
                participation as UnifiedChallengeActivity,
                challenge?.microsteps
            ),
        [participation, challenge]
    )

    const logTrackingEvent = useCallback(() => {
        Avo.challengeSelected({
            activityType: 'challenge_selected',
            challengeId: challenge?.id,
            challengeTheme: challenge?.theme,
            challengeType: challenge?.challenge_type,
            dayNumber: null,
            featureType: 'challenge',
            tabName: 'Home',
            teamId: null,
            teamType: null
        })
    }, [challenge])

    const onNavigate = useCallback(() => {
        if (challenge) {
            logTrackingEvent()

            history.push(
                generatePath(
                    challenge.challenge_type === ChallengeType.company
                        ? ROUTES.COMPANY_CHALLENGE_HOME
                        : challenge.challenge_type === ChallengeType.group
                        ? ROUTES.GROUP_CHALLENGE_HOME
                        : ROUTES.CHALLENGE,
                    {
                        challengeId: challenge?.id
                    }
                )
            )
        }
    }, [history, logTrackingEvent, challenge])

    const { daysSinceStart, percentageDone, activeStreak } = useMemo(() => {
        const daysSince = getDaysFromDateToDate(participation?.join)
        const streak = participation?.streak
        return {
            daysSinceStart: daysSince,
            percentageDone: Math.min(
                ((daysSince + 1) * 100) / challenge?.duration,
                100
            ),
            activeStreak: getIsActiveStreak(streak as UnifiedStreak)
        }
    }, [participation, challenge?.duration])

    // Check if user has checked in today.
    const remainingDays = useMemo(() => {
        let days = challenge?.duration - daysSinceStart
        if (hasCheckedInToday) {
            days -= 1
        }
        return days < 0 ? 0 : days
    }, [challenge, daysSinceStart, hasCheckedInToday])

    return (
        <Stack gap={2}>
            {isActive && (
                <Stack gap={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        gap={2}
                    >
                        <Stack gap={1}>
                            <CoreTypography customVariant="formBody">
                                {getActiveStreak(
                                    participation?.streak as UnifiedStreak,
                                    activeStreak
                                )}
                            </CoreTypography>
                            <CoreTypography
                                variant="h6"
                                uppercase
                                maxWidth={80}
                            >
                                {activeStreak
                                    ? formatMessage(messages.currenStreak)
                                    : formatMessage(messages.highestStreak)}
                            </CoreTypography>
                        </Stack>
                        <Stack gap={1}>
                            <CoreTypography
                                variant="h6"
                                uppercase
                                maxWidth={80}
                                customVariant="formBody"
                            >
                                {totalCheckinsCount}
                            </CoreTypography>
                            <CoreTypography
                                variant="h6"
                                component="p"
                                uppercase
                                maxWidth={80}
                            >
                                {formatMessage(messages.totalCheckIns)}
                            </CoreTypography>
                        </Stack>
                    </Stack>
                    <Stack width="100%">
                        <LinearProgress
                            color="accent"
                            variant="determinate"
                            value={percentageDone}
                            aria-label={formatMessage(
                                messages.challengeProgressAria,
                                {
                                    challengeName: challenge.name
                                }
                            )}
                        />
                    </Stack>
                </Stack>
            )}
            {isActive ? (
                <Stack width="100%" direction="column" gap={1}>
                    <CoreTypography variant="body2" color="primary.main">
                        {formatMessage(messages.daysToGo, {
                            remainingDays: remainingDays
                        })}
                    </CoreTypography>
                    <Button
                        variant="contained"
                        endIcon={
                            <LeafIcon icon={'arrow-right'} fontSize={'small'} />
                        }
                        data-testid="keep-going-button"
                        onClick={onNavigate}
                        aria-label={formatMessage(
                            challengeCompleted
                                ? messages.seeResultsForChallenge
                                : messages.keepGoingOnChallenge,
                            {
                                challengeName: challenge.name
                            }
                        )}
                    >
                        <CoreTypography
                            customVariant="buttonNormal"
                            sx={{
                                textAlign: 'center',
                                whiteSpace: 'nowrap'
                            }}
                        >
                            {challengeCompleted
                                ? formatMessage(messages.seeResult)
                                : formatMessage(messages.keepGoing)}
                        </CoreTypography>
                    </Button>
                </Stack>
            ) : (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <CoreTypography variant="body2" color="text.disabled">
                        {formatMessage(messages.challengeDuration, {
                            duration: challenge?.duration
                        })}
                    </CoreTypography>
                    {challenge?.totalParticipants && (
                        <CoreTypography variant="body2" color="text.disabled">
                            {formatMessage(messages.totalParticipants, {
                                totalParticipants: challenge?.totalParticipants
                            })}
                        </CoreTypography>
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default memo(ChallengeCardDetails)
