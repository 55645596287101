import { Box, Icon, Stack } from '@mui/material'
import { BurstNotification, IconButton } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    InvalidAction,
    InvalidInput,
    PostReaction,
    PostReactionCreationSucceeded,
    ReactToPostMutation,
    UndoReactToPostMutation,
    useReactToPostMutation,
    useUndoReactToPostMutation
} from '../../../../../graphql/generated/autogenerated'
import { ReactionTypes } from '../../enums/reactionTypes'
import ReactionCountButton from '../ReactionCountButton/ReactionCountButton'
import { FetchResult } from '@apollo/client'

export type ReactionButtonProps = {
    postId: string
    reactionName: string
    activityName: string
    userId: string
    userDisplayName: string
    reactions: PostReaction[]
    postTypeId: ReactionTypes
    icon: any
    borderIcon: any
    onReact?: (undo: boolean) => void
    onViewReactions?: (
        postId: string,
        userId: string,
        totalReactions: number
    ) => void
}

const messages = defineMessages({
    reaction: {
        defaultMessage: 'react with {reactionName} for {activityName}',
        description:
            'reaction button text for a specific activity, e.g. react with love for "Ben completed a challenge"'
    },
    undoReaction: {
        defaultMessage: 'undo react with {reactionName} for {activityName}',
        description:
            'reaction button text to undo a specific activity, e.g. undo react with love for "Ben completed a challenge"'
    }
})

const ReactionButton: React.FC<ReactionButtonProps> = ({
    postId,
    reactionName,
    activityName,
    userId,
    userDisplayName,
    reactions,
    postTypeId,
    icon,
    borderIcon,
    onReact,
    onViewReactions
}) => {
    const { formatMessage } = useIntl()
    const [hasReacted, setHasReacted] = useState(false)
    const [showBurst, setShowBurst] = useState(false)
    const [reactionId, setReactionId] = useState()
    const [reactionCount, setReactionCount] = useState(0)
    const [currentReactions, setCurrentReactions] =
        useState<PostReaction[]>(reactions)

    const [reactToPostMutation, { loading: reactLoading }] =
        useReactToPostMutation({
            variables: {
                postReactionInput: {
                    postId,
                    postTypeId
                }
            }
        })

    const [undoReactToPostMutation, { loading: undoReactLoading }] =
        useUndoReactToPostMutation({
            variables: {
                reactionId
            }
        })

    useEffect(() => {
        const userReaction = reactions?.find((l) => l.userId === userId)
        setReactionCount(reactions?.length)
        setHasReacted(!!userReaction)
        setReactionId(userReaction?.id)
    }, [reactions, userId])

    const onMutationComplete = useCallback(
        <T extends FetchResult>(
            mutation: () => Promise<any>,
            getResponse: (result: T) => any,
            setReactions: (result: PostReaction[]) => any,
            isReacting: boolean
        ) => {
            setReactionCount((h) => h + (isReacting ? 1 : -1))
            setHasReacted(isReacting)
            setShowBurst(isReacting)
            setCurrentReactions((s) => setReactions(s))

            mutation().then((result) => {
                const response = getResponse(result)
                if (
                    (response as InvalidInput)?.invalidFields?.length > 0 ||
                    !!(response as InvalidAction)?.message
                ) {
                    setReactionCount((h) => h + (isReacting ? -1 : 1))
                    setHasReacted(!isReacting)
                } else {
                    setReactionId(
                        isReacting
                            ? (response as PostReactionCreationSucceeded)
                                  ?.postReactionId
                            : null
                    )
                    onReact?.(!isReacting)
                }
            })
        },
        [onReact]
    )

    const onReactionActivity = useCallback(() => {
        if (!reactLoading && !undoReactLoading) {
            if (!hasReacted) {
                onMutationComplete<FetchResult<ReactToPostMutation>>(
                    reactToPostMutation,
                    (result) => result?.data?.socialGroups?.reactToPost,
                    (s) =>
                        s.concat([
                            {
                                userDisplayName,
                                userId
                            } as PostReaction
                        ]),
                    true
                )
            } else {
                onMutationComplete<FetchResult<UndoReactToPostMutation>>(
                    undoReactToPostMutation,
                    (result) =>
                        result?.data?.socialGroups?.undoSocialGroupPostReaction,
                    (s) => s.filter((r) => r.userId !== userId),
                    false
                )
            }
        }
    }, [
        reactLoading,
        undoReactLoading,
        hasReacted,
        userDisplayName,
        userId,
        onMutationComplete,
        reactToPostMutation,
        undoReactToPostMutation
    ])

    const BurstWrapper = useMemo<any>(
        () => (showBurst ? BurstNotification : Box),
        [showBurst]
    )

    return (
        <Stack
            direction="row"
            data-testid="reaction-button"
            justifyContent="end"
        >
            <BurstWrapper
                {...(showBurst && {
                    show: true,
                    alwaysVisible: true,
                    skipAnimation: false,
                    size: 10
                })}
            >
                <IconButton
                    variant={hasReacted ? 'contained' : 'text'}
                    color={hasReacted ? 'secondary' : 'primary'}
                    disabled={!userDisplayName}
                    aria-label={formatMessage(
                        hasReacted ? messages.undoReaction : messages.reaction,
                        {
                            reactionName,
                            activityName
                        }
                    )}
                    onClick={onReactionActivity}
                    data-testid={
                        hasReacted
                            ? `${reactionName}-filled`
                            : `${reactionName}`
                    }
                >
                    {hasReacted ? icon : borderIcon}
                </IconButton>
            </BurstWrapper>
            <ReactionCountButton
                reactionCount={reactionCount}
                currentReactions={currentReactions}
                postId={postId}
                reactionName={reactionName}
                userId={userId}
                activityName={activityName}
                onViewReactions={(postId, userId, totalReactions) => {
                    onViewReactions?.(postId, userId, totalReactions)
                }}
            />
        </Stack>
    )
}

export default React.memo(ReactionButton)
