import { Card, Skeleton, Stack } from '@mui/material'
import React, { memo } from 'react'

const SocialActivityCardSkeleton: React.FC = () => (
    <Card elevation={1}>
        <Stack p={1}>
            <Stack direction="row" gap={1.5} p={0.75} width="100%">
                <Skeleton
                    variant="circular"
                    width={40}
                    height={40}
                    sx={{ minWidth: 40, minHeight: 40 }}
                />
                <Stack width="100%">
                    <Skeleton variant="text" width="20%" height={21} />
                    <Skeleton variant="text" width="80%" height={21} />
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                pl={0.75}
                height={44}
            >
                <Skeleton variant="text" width="33%" height={21} />
                <Skeleton
                    variant="circular"
                    width={28}
                    height={28}
                    sx={{ minWidth: 28, minHeight: 28, mr: 1 }}
                />
            </Stack>
        </Stack>
    </Card>
)

export default memo(SocialActivityCardSkeleton)
