import { Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import ViewPastChallenges from '../../../components/features/PastChallenges/ViewPastChallenges/ViewPastChallenges'
import { UnifiedChallenge } from '../../../graphql/generated/autogenerated'
import { useShowWalmartBetterChoicesChallenge } from '../../../shared/hooks'
import { GQLNull, GQLNullValue } from '../../../shared/utils/Nulls'

export type TitleHeaderProps = {
    challenge?: UnifiedChallenge | GQLNull
    pastChallenges?: boolean
}

const TitleHeader: React.FC<TitleHeaderProps> = ({
    challenge,
    pastChallenges
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const showWalmartPrizeText = useShowWalmartBetterChoicesChallenge(challenge)

    return (
        <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems={isMobile ? 'start' : 'center'}
            gap={1}
            data-testid="TitleHeader"
        >
            <Stack>
                {!challenge && (
                    <Stack>
                        {pastChallenges ? (
                            <CoreTypography
                                variant="h1"
                                data-testid="NormalHeader"
                            >
                                <FormattedMessage
                                    defaultMessage="Your Past Challenges"
                                    description="Past Challenges home page Header"
                                />
                            </CoreTypography>
                        ) : (
                            <Stack gap={2}>
                                <CoreTypography
                                    variant="h1"
                                    data-testid="NormalHeader"
                                >
                                    <FormattedMessage
                                        defaultMessage="Challenges"
                                        description="Challenges home page Header"
                                    />
                                </CoreTypography>
                                <CoreTypography
                                    variant="body1"
                                    data-testid="NormalDescription"
                                >
                                    <FormattedMessage
                                        defaultMessage="Join a Challenge and build good habits together."
                                        description="Challenges home page information text"
                                    />
                                    {showWalmartPrizeText && (
                                        <FormattedMessage
                                            defaultMessage="You can only participate in 1 challenge at a time."
                                            description="Challenges home page information text"
                                        />
                                    )}
                                </CoreTypography>
                            </Stack>
                        )}
                    </Stack>
                )}
                {!!challenge && (
                    <Stack gap={1}>
                        <CoreTypography
                            variant="h1"
                            data-testid="ActiveChallengeHeader"
                        >
                            <FormattedMessage
                                defaultMessage="Your Challenge"
                                description="Your Challenge home page Header"
                            />
                        </CoreTypography>
                        <CoreTypography
                            variant="body1"
                            color="text.primary"
                            data-testid="ActiveChallengeDescription"
                        >
                            <FormattedMessage
                                defaultMessage="Keep the momentum going. Pick up where you left off."
                                description="Challenges home page sub-Header"
                            />
                        </CoreTypography>
                    </Stack>
                )}
            </Stack>
            {!pastChallenges && <ViewPastChallenges />}
        </Stack>
    )
}

export default memo(TitleHeader)
