import { Stack } from '@mui/material'
import { ErrorBoundary } from '@thriveglobal/thrive-web-errors'
import { CoreTypography, LeafCarousel } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { ChallengeTemplate } from '../../../graphql/generated/autogenerated'
import useGetChallengeRecommendation from '../../../hooks/useGetChallengeRecommendation/useGetChallengeRecommendation'
import useGetIntention from '../../../hooks/useGetIntention/useGetIntention'
import { ReactNullValue } from '../../../shared/utils/Nulls'
import { defaultErrorBoundaryProps } from '../../../utils/defaultErrorBoundaryProps'
import ChallengeGrid, {
    ChallengeCardType
} from '../../features/HomeChallenges/ChallengeGrid/ChallengeGrid'

const messages = defineMessages({
    ariaLabel: {
        defaultMessage: 'Recommended Challenges',
        description: 'recommended challenges aria label'
    }
})

const chunkArray = (array: ChallengeTemplate[], size: number) => {
    const result = []
    for (let i = 0; i < array?.length; i += size) {
        result.push(array?.slice(i, i + size))
    }
    return result
}

export interface ChallengeRecommendationProps {
    parentLoading: boolean
}

const ChallengeRecommendation: React.FC<ChallengeRecommendationProps> = ({
    parentLoading
}) => {
    const { formatMessage } = useIntl()
    const { intention, loading: intentionLoading } = useGetIntention()
    const { recommendedChallenges, loading: recommendedChallengeLoading } =
        useGetChallengeRecommendation()

    const challengeChunks = useMemo(
        () => chunkArray(recommendedChallenges as ChallengeTemplate[], 2),
        [recommendedChallenges]
    )

    const loading = useMemo(
        () => intentionLoading || recommendedChallengeLoading || parentLoading,
        [intentionLoading, recommendedChallengeLoading, parentLoading]
    )

    if (loading || !intention) return ReactNullValue

    return (
        <Stack data-testid="challenge-recommendation">
            <LeafCarousel
                ariaLabel={formatMessage(messages.ariaLabel)}
                contentSize={1}
                title={
                    <Stack gap={1}>
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="Recommended Challenges based on your Intention"
                                description="recommended challenges based on your intention title"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage='We recommend tackling the following Challenges to help you with your Intention: "{intention}"'
                                description="recommended challenges based on your intention subtitle"
                                values={{ intention }}
                            />
                        </CoreTypography>
                    </Stack>
                }
            >
                {challengeChunks.map((chunk, index) => (
                    <Stack width="100%">
                        <ChallengeGrid
                            size="sm"
                            key={index}
                            loading={false}
                            skeletonCount={2}
                            challenges={chunk as ChallengeTemplate[]}
                            type={ChallengeCardType.STANDARD}
                        />
                    </Stack>
                ))}
            </LeafCarousel>
        </Stack>
    )
}

const ChallengeRecommendationWithErrorBoundary: React.FC<
    ChallengeRecommendationProps
> = ({ parentLoading }) => (
    <ErrorBoundary
        {...defaultErrorBoundaryProps}
        boundaryName="ChallengeRecommendation"
        boundaryType="Component"
    >
        <ChallengeRecommendation parentLoading={parentLoading} />
    </ErrorBoundary>
)

export default ChallengeRecommendationWithErrorBoundary
