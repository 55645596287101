import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import { useMemo } from 'react'
import { UnifiedChallenge } from '../../../graphql/generated/autogenerated'
import { GQLNull } from '../../utils/Nulls'

export const useShowWalmartBetterChoicesChallenge = (
    challenge: UnifiedChallenge | GQLNull
): boolean => {
    const showWalmartBetterChoicesChallenge = useIsFeatureEnabled(
        'showWalmartBetterChoicesChallenge',
        false,
        true
    ) as boolean

    const showWalmartPrizeText = useMemo(
        () => showWalmartBetterChoicesChallenge,
        [showWalmartBetterChoicesChallenge]
    )

    return showWalmartPrizeText
}

export default useShowWalmartBetterChoicesChallenge
