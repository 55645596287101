import { createContext } from 'react'
import { UnifiedChallenge } from '../../../graphql/generated/autogenerated'

export interface ChallengeUserStepsProviderValue {
    loading: boolean
    error: boolean
    todaySteps: number
    totalSteps: number
    setChallenge?: (challenge: UnifiedChallenge) => void
    refetch: (loading?: boolean) => Promise<any>
}

export const defaultContextValue: ChallengeUserStepsProviderValue = {
    loading: false,
    error: false,
    todaySteps: 0,
    totalSteps: 0,
    setChallenge: () => ({}),
    refetch: () => Promise.resolve(null)
}

const ChallengeUserStepsContext = createContext(defaultContextValue)

export default ChallengeUserStepsContext
