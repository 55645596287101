import addDays from 'date-fns/addDays'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import isAfter from 'date-fns/isAfter'
import parseISO from 'date-fns/parseISO'
import { useCallback } from 'react'
import {
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../../graphql/generated/autogenerated'
import useCurrentDateTime from '../../../hooks/useCurrentDateTime/useCurrentDateTime'
import { ChallengeType } from '../../enums'
import { GQLNullValue } from '../../../shared/utils/Nulls'

export interface ChallengeStates {
    day: number
    endDate: Date
    startDate: Date
    elapsedTime: number
    remainingDays: number
    challengeStarted: boolean
    challengeExpired: boolean
    challengeSignUpStarted: Date
}

export const useCompanyChallengeStates = () => {
    const currentDateTime = parseISO(useCurrentDateTime().toISOString())
    const calculateChallengeStates = useCallback(
        (
            challenge?: UnifiedChallenge,
            // Needed for personal challenge calculations, can remove when we move forward 100% with templates
            participation?: UnifiedChallengeActivity[]
        ) => {
            if (challenge && challenge?.dayOfChallenge !== GQLNullValue) {
                // This is the standard for calculating challenge states
                // This logic is used for challenges with the `dayOfChallenge` value
                // This ensures that we don't have to run tz calculations on these new entities
                const day = challenge.dayOfChallenge ?? 0
                const duration = Number(challenge.duration)
                const signUpDate = parseISO(challenge.companyChallengeSignupDay)
                const challengeSignUpStarted = isAfter(
                    currentDateTime,
                    signUpDate
                )
                const startDate = parseISO(challenge.companyChallengeStartDay)
                const endDate = addDays(startDate, duration - 1) // -1 since inclusive of startDate
                const challengeStarted = day > 0
                const challengeExpired = day > duration
                const elapsedTime = 1 - day
                const remainingDays = duration + 1 - day
                return {
                    day,
                    endDate,
                    startDate,
                    elapsedTime,
                    remainingDays,
                    challengeStarted,
                    challengeExpired,
                    challengeSignUpStarted
                }
            } else {
                // This is temporary logic for the company challenge and personal challenge and should be removed when we move forward with templates
                // The company challenge and personal challenge require a bit of logic to calculate the states
                // These challenges do not utilize `dayOfChallenge` and require FE date calculations
                // The dates provided need to be zoned to the thrive timezone, so we utilize the `useCurrentDateTime` hook
                const startDate = parseISO(
                    challenge?.challenge_type === ChallengeType.company
                        ? challenge?.companyChallengeStartDay
                        : participation?.[0]?.join
                )
                const endDate =
                    challenge && addDays(startDate, challenge?.duration)
                const challengeStarted = isAfter(currentDateTime, startDate)
                const challengeExpired =
                    endDate && isAfter(currentDateTime, endDate)
                const elapsedTime = differenceInCalendarDays(
                    currentDateTime,
                    startDate
                )
                const day = elapsedTime + 1
                const remainingDays = endDate
                    ? Math.abs(
                          differenceInCalendarDays(currentDateTime, endDate)
                      )
                    : 0

                return {
                    day,
                    endDate,
                    startDate,
                    elapsedTime,
                    remainingDays,
                    challengeStarted,
                    challengeExpired,
                    challengeSignUpStarted: !!startDate
                }
            }
        },
        [currentDateTime]
    )

    return calculateChallengeStates
}
export default useCompanyChallengeStates
