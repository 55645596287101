import { Card, Divider, Fade, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    Post,
    PostReaction
} from '../../../../../graphql/generated/autogenerated'
import { useGetTimeSince } from '../../../../../hooks/useGetTimeSince/useGetTimeSince'
import { ReactionTypes } from '../../enums/reactionTypes'
import { getInitials } from '../../util/getInitials'
import ReactionButton from '../ReactionButton/ReactionButton'
import SocialAvatar from '../SocialAvatar/SocialAvatar'

const messages = defineMessages({
    love: {
        defaultMessage: 'love',
        description:
            'description of the reaction button type on a social group post'
    }
})

export type SocialActivityCardProps = {
    userId: string
    activity: Post
    displayName: string
    activityMessage: JSX.Element
    isGroup?: boolean
    isContained?: boolean
    onReact?: (activity: Post, undo?: boolean) => void
    onViewReactions?: (
        postId: string,
        userId: string,
        totalReactions: number,
        activity: Post
    ) => void
}

const SocialActivityCard: React.FC<SocialActivityCardProps> = ({
    userId,
    activity,
    displayName,
    activityMessage,
    isGroup,
    isContained = true,
    onReact,
    onViewReactions
}) => {
    const { formatMessage } = useIntl()
    const timeSinceActivity = useGetTimeSince(new Date(activity.createdAt))

    const activityName = useMemo(
        () =>
            activity.createdBySocialGroup
                ? activity.createdBySocialGroup
                : activity.userDisplayName,
        [activity]
    )

    const displayNameInitials = useMemo(
        () => getInitials(String(activityName)),
        [activityName]
    )

    return (
        <Card elevation={isContained ? 1 : 0}>
            <Stack
                data-testid="social-activity-card"
                px={isContained ? 1 : 0}
                py={isContained ? 1 : 0.5}
            >
                <Fade in={!!activityMessage} mountOnEnter unmountOnExit>
                    <Stack pt={1}>
                        <Stack direction="row" gap={1.5}>
                            <SocialAvatar
                                initials={displayNameInitials}
                                imageUrl={
                                    activity?.profilePhotoUrl ?? undefined
                                }
                                isGroup={isGroup}
                            />
                            <Stack>
                                <CoreTypography variant="body1" color="primary">
                                    {activityName}
                                </CoreTypography>
                                <CoreTypography
                                    variant="body2"
                                    color="grey.700"
                                >
                                    {activityMessage}
                                </CoreTypography>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            pl={0.75}
                        >
                            <CoreTypography variant="body2" color="grey.700">
                                {timeSinceActivity}
                            </CoreTypography>
                            <ReactionButton
                                postId={activity.id}
                                reactionName={formatMessage(messages.love)}
                                activityName={String(activityName)}
                                userId={userId}
                                userDisplayName={displayName}
                                reactions={
                                    activity.reactions
                                        ?.celebrates as PostReaction[]
                                }
                                postTypeId={ReactionTypes.celebrate}
                                icon={
                                    <LeafIcon
                                        icon="hands-clapping"
                                        iconStyle="light"
                                        fontSize="medium"
                                    />
                                }
                                borderIcon={
                                    <LeafIcon
                                        icon="hands-clapping"
                                        iconStyle="light"
                                        fontSize="medium"
                                    />
                                }
                                onReact={(undo: boolean) =>
                                    onReact?.(activity, undo)
                                }
                                onViewReactions={(
                                    postId: string,
                                    userId: string,
                                    totalReactions: number
                                ) => {
                                    onViewReactions?.(
                                        postId,
                                        userId,
                                        totalReactions,
                                        activity
                                    )
                                }}
                            />
                        </Stack>
                    </Stack>
                </Fade>
            </Stack>
        </Card>
    )
}

export default memo(SocialActivityCard)
