import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Post,
    SocialGroupActivityFeedQuery,
    SocialGroupActivityFeedQueryVariables,
    useSocialGroupActivityFeedQuery
} from '../../../../../graphql/generated/autogenerated'
import { useCompanyChallengeProviderContext } from '../../../../../hooks/withCompanyChallengeProvider'
import usePage from '../../../../hooks/usePage/usePage'
import usePagination from '../../../../hooks/usePagination/usePagination'
import SocialActivityProviderContext, {
    SocialActivityProviderValue
} from './socialActivityContext'

export default function withSocialGroupActivityProvider<
    Props extends JSX.IntrinsicAttributes
>(Component: ComponentType<Props>) {
    return (props: Props): JSX.Element => {
        const [displayName, setDisplayName] = useState('')
        const [socialGroupId, setSocialGroupId] = useState<string>()
        const { socialGroupId: socialGroupIdParam } = useParams<{
            socialGroupId: string
        }>()
        const { canFirePostJoinQueries } = useCompanyChallengeProviderContext()

        useEffect(() => {
            if (!socialGroupId && socialGroupIdParam) {
                setSocialGroupId(socialGroupIdParam)
            }
        }, [socialGroupId, socialGroupIdParam])

        const {
            data,
            queryData,
            loading,
            error,
            loadingNext,
            hasMore,
            handleLoadMore,
            refetch
        } = usePagination<
            Post,
            SocialGroupActivityFeedQuery,
            SocialGroupActivityFeedQueryVariables
        >({
            defaultOffset: 30,
            query: useSocialGroupActivityFeedQuery,
            queryProps: {
                socialGroupId: socialGroupId ? socialGroupId : null,
                challengeId: null
            },
            skip: !canFirePostJoinQueries,
            dataTransform: (data) =>
                data?.socialGroups?.socialGroupActivityFeed as Post[]
        })

        const { pageValues, onNextPage } = usePage({
            rowsPerPage: 5,
            accumulate: true,
            onNavigate: handleLoadMore,
            values: data
        })

        useEffect(() => {
            if (queryData?.socialGroups?.displayName?.displayName) {
                setDisplayName(queryData.socialGroups.displayName.displayName)
            }
        }, [queryData])

        const state = useMemo<SocialActivityProviderValue>(
            () => ({
                displayName,
                activityFeed: pageValues as Post[],
                error: Boolean(error),
                loading,
                loadingNext,
                hasMore,
                setSocialGroupId,
                refetch,
                loadMore: onNextPage,
                fetchMore: handleLoadMore as any
            }),
            [
                error,
                loading,
                hasMore,
                loadingNext,
                displayName,
                pageValues,
                setSocialGroupId,
                refetch,
                onNextPage,
                handleLoadMore
            ]
        )

        return (
            <SocialActivityProviderContext.Provider value={state}>
                <Component {...props} />
            </SocialActivityProviderContext.Provider>
        )
    }
}
