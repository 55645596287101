import { Stack } from '@mui/material'
import React, { useMemo } from 'react'
import { UnifiedChallengeParticipation } from '../../../../graphql/generated/autogenerated'
import { format, getCheckInInformation } from '@thriveglobal/thrive-web-core'
import ChallengeResultsTitle from './ChallengeResultsTitle/ChallengeResultsTitle'
import ProgressBar from './ProgressBar/ProgressBar'
import ProgressDetails from './ProgressDetails/ProgressDetails'

export interface ChallengeResultsProps {
    challengeParticipation: UnifiedChallengeParticipation
    hideTitle?: boolean
    hideMainTitle?: boolean
    hideSubTitle?: boolean
    hideSubStats?: boolean
    hideMainStats?: boolean
    checkInTriggered?: boolean
    action?: React.ReactNode
}

const ChallengeResults: React.FC<ChallengeResultsProps> = ({
    challengeParticipation,
    hideTitle,
    hideMainTitle,
    hideSubTitle,
    hideSubStats,
    hideMainStats,
    checkInTriggered,
    action
}) => {
    const {
        uniqueCheckInDates,
        totalCheckinsCount,
        hasCheckedInToday,
        currentStreak,
        checkInTriggeredBeforeRefetch
    } = useMemo(() => {
        const {
            uniqueCheckInDates: uniqueCheckInDatesData,
            totalCheckinsCount: totalCheckinsCountData,
            hasCheckedInToday: hasCheckedInTodayData
        } = getCheckInInformation(
            challengeParticipation?.participation[0]?.microstepActivities ?? []
        )
        const currentStreak =
            challengeParticipation?.participation[0]?.streak?.currentStreak

        if (
            checkInTriggered &&
            !uniqueCheckInDatesData.some(
                (uniqueCheckInDate) =>
                    uniqueCheckInDate === format(new Date(), 'YYYY-MM-DD')
            )
        ) {
            return {
                uniqueCheckInDates: uniqueCheckInDatesData.concat([new Date()]),
                totalCheckinsCount: totalCheckinsCountData + 1,
                hasCheckedInToday: true,
                currentStreak: (currentStreak ?? 0) + 1,
                checkInTriggeredBeforeRefetch: true
            }
        }

        return {
            uniqueCheckInDates: uniqueCheckInDatesData,
            totalCheckinsCount: totalCheckinsCountData,
            hasCheckedInToday: hasCheckedInTodayData,
            currentStreak,
            checkInTriggeredBeforeRefetch: false
        }
    }, [challengeParticipation, checkInTriggered])

    const completedChallenge = useMemo(
        () => !!challengeParticipation?.participation[0]?.completedOn,
        [challengeParticipation]
    )

    return (
        <Stack
            sx={{ maxWidth: (theme) => theme.spacing(73) }}
            data-testid="challenge-results"
        >
            {!hideTitle && (
                <ChallengeResultsTitle
                    hasCheckedInToday={hasCheckedInToday}
                    completedChallenge={completedChallenge}
                    hideMainTitle={hideMainTitle}
                    hideSubTitle={hideSubTitle}
                    action={action}
                />
            )}
            <ProgressBar
                challengeParticipation={challengeParticipation}
                uniqueCheckInDates={uniqueCheckInDates}
                completedChallenge={completedChallenge}
                checkInTriggered={checkInTriggeredBeforeRefetch}
                currentStreak={currentStreak ?? 0}
            />
            <ProgressDetails
                challengeParticipation={challengeParticipation}
                totalCheckinsCount={totalCheckinsCount}
                activeDays={uniqueCheckInDates.length}
                completedChallenge={completedChallenge}
                checkedInToday={hasCheckedInToday}
                currentStreak={currentStreak ?? 0}
                hideSubStats={hideSubStats}
                hideMainStats={hideMainStats}
            />
        </Stack>
    )
}

export default ChallengeResults
