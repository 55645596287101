import { Box, Divider, Fade, Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import { memo, useCallback, useLayoutEffect, useRef } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Post } from '../../../../../graphql/generated/autogenerated'
import useQueryFocusableElement from '../../../../hooks/useQueryFocusableElements'
import { useSocialActivityContext } from '../../hooks/useSocialActivityProvider'
import useSocialGroupTranslations from '../../hooks/useSocialGroupTranslations/useSocialGroupTranslations'
import ClaimYourName from '../ClaimYourName/ClaimYourName'
import SocialActivityCardSkeleton from '../SocialActivityCard/Skeleton/SocialActivityCardSkeleton'
import SocialActivityCard from '../SocialActivityCard/SocialActivityCard'

const messages = defineMessages({
    loadMoreMembersAria: {
        defaultMessage: 'Load more community posts',
        description:
            'Label for a button that will load more posts on a community activity feed'
    }
})

export enum SocialActivityFeedType {
    COMPANY,
    SOCIAL_GROUP,
    CHALLENGE
}

export interface SocialActivityFeedPayload {
    challengeId?: string
    socialGroupId?: string
}

export interface SocialActivityFeedProps extends JSX.IntrinsicAttributes {
    hideLoadMore?: boolean
    isContained?: boolean
    hideJoinedTeamMessage?: boolean
    onReact?: (activity: Post, undo?: boolean) => void
    onViewReactions?: (
        postId: string,
        userId: string,
        totalReactions: number,
        activity?: Post
    ) => void
    onOpenCreateDisplayName?: () => void
    onCreateDisplayName?: (displayName: string) => void
}

const SocialActivityFeed: React.FC<SocialActivityFeedProps> = ({
    hideLoadMore,
    isContained = true,
    onReact,
    onViewReactions,
    onOpenCreateDisplayName,
    onCreateDisplayName,
    hideJoinedTeamMessage
}) => {
    const { userId } = useAppSelector((state) => state.user)
    const { generateActivityMessage } = useSocialGroupTranslations(
        hideJoinedTeamMessage
    )
    const { formatMessage } = useIntl()

    const queryNextFocusableElement = useQueryFocusableElement()
    const oldActivityFeedLengthRef = useRef<number>(0)
    const currentActivityFeedLengthRef = useRef<number>(0)
    const nextFocusableListItemRef = useRef<{
        index: undefined | number
        element: undefined | HTMLElement | unknown
    }>({ index: undefined, element: undefined })
    const isInitialPageLoadingRef = useRef(true)
    const hasMoreRef = useRef(true)

    const {
        activityFeed,
        displayName,
        loading: communityLoading,
        refetch: communityRefetch,
        loadMore,
        loadingNext
    } = useSocialActivityContext()

    const onLoadMore = useCallback(() => {
        hasMoreRef.current =
            oldActivityFeedLengthRef.current !==
            currentActivityFeedLengthRef.current
        oldActivityFeedLengthRef.current = currentActivityFeedLengthRef.current
        loadMore(true)?.then(() => {
            hasMoreRef.current =
                oldActivityFeedLengthRef.current !==
                currentActivityFeedLengthRef.current
        })
        isInitialPageLoadingRef.current = false
    }, [
        loadMore,
        isInitialPageLoadingRef,
        oldActivityFeedLengthRef,
        currentActivityFeedLengthRef
    ])

    useLayoutEffect(
        function focusOnFirstInteractiveElementOfNewPage() {
            if (
                !!nextFocusableListItemRef.current.element &&
                !!nextFocusableListItemRef.current.index &&
                !isInitialPageLoadingRef.current
            ) {
                const element = queryNextFocusableElement(
                    nextFocusableListItemRef.current.element as HTMLElement,
                    undefined
                )

                if (element) {
                    element.focus()
                }
            }
        },
        [activityFeed, queryNextFocusableElement]
    )

    return communityLoading && !loadingNext ? (
        <Fade in>
            <Stack gap={2}>
                {[...Array(5)].map((_, index: number) => (
                    <Box key={index}>
                        <SocialActivityCardSkeleton />
                    </Box>
                ))}
            </Stack>
        </Fade>
    ) : (
        <Fade in>
            <Stack gap={4}>
                {!displayName && (!communityLoading || loadingNext) && (
                    <Box px={1}>
                        <ClaimYourName
                            refetchCommunity={communityRefetch}
                            onOpenCreateDisplayName={() =>
                                onOpenCreateDisplayName?.()
                            }
                            onCreateDisplayName={(displayName) =>
                                onCreateDisplayName?.(displayName)
                            }
                        />
                    </Box>
                )}
                <Stack gap={isContained ? 2 : 0}>
                    {activityFeed.map((companyActivity, index: number) => {
                        const activityMessage =
                            generateActivityMessage(companyActivity)

                        // If the activity feed has changed, the next focusable element index will be the old activity feed length
                        if (
                            activityFeed.length !==
                            currentActivityFeedLengthRef.current
                        ) {
                            nextFocusableListItemRef.current.index =
                                currentActivityFeedLengthRef.current
                            currentActivityFeedLengthRef.current =
                                activityFeed.length
                        }

                        return (
                            <Box
                                key={index}
                                ref={(element) => {
                                    if (
                                        nextFocusableListItemRef.current
                                            .index === index
                                    ) {
                                        nextFocusableListItemRef.current.element =
                                            element
                                    }
                                }}
                            >
                                {activityMessage ? (
                                    <SocialActivityCard
                                        userId={String(userId)}
                                        activity={companyActivity}
                                        displayName={displayName}
                                        activityMessage={activityMessage}
                                        isGroup={
                                            !!companyActivity.createdBySocialGroup
                                        }
                                        onReact={onReact}
                                        isContained={isContained}
                                        onViewReactions={(
                                            postId,
                                            userId,
                                            totalReactions,
                                            activity
                                        ) =>
                                            onViewReactions?.(
                                                postId,
                                                userId,
                                                totalReactions,
                                                activity
                                            )
                                        }
                                    />
                                ) : (
                                    <></>
                                )}
                                {!isContained &&
                                    index < activityFeed?.length - 1 && (
                                        <Divider />
                                    )}
                            </Box>
                        )
                    })}
                </Stack>
                {!hideLoadMore && hasMoreRef.current && (
                    <Box display="flex" justifyContent="center">
                        <LoadingButton
                            loading={loadingNext}
                            disabled={loadingNext}
                            fixWidth={true}
                            variant="text"
                            size="medium"
                            data-testid="load-more-members-button"
                            onClick={onLoadMore}
                            aria-label={formatMessage(
                                messages.loadMoreMembersAria
                            )}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="Load more"
                                    description="Button text that will load more posts on a community activity feed"
                                />
                            </CoreTypography>
                        </LoadingButton>
                    </Box>
                )}
            </Stack>
        </Fade>
    )
}

export default memo(SocialActivityFeed)
