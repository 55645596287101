import React, { memo } from 'react'
import { UnifiedChallenge } from '../../../../graphql/generated/autogenerated'
import useGetSpotlightChallenge from '../../../../hooks/useGetSpotlightChallenge/useGetSpotlightChallenge'
import { ReactNullValue } from '../../../utils/Nulls'
import ChallengeCard from '../ChallengeCard/ChallengeCard'
import ChallengeCardSkeleton from '../ChallengeCard/ChallengeCardSkeleton'

const ChallengeSpotlight: React.FC = () => {
    const { spotlightChallenge, loading } = useGetSpotlightChallenge()

    if (loading) return <ChallengeCardSkeleton />
    if (!loading && !spotlightChallenge) return ReactNullValue

    return (
        <ChallengeCard
            challenge={spotlightChallenge?.challenge as UnifiedChallenge}
            participation={spotlightChallenge?.participation?.[0]}
            hideDescription={true}
            isVertical={true}
        />
    )
}

export default memo(ChallengeSpotlight)
