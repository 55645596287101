import {
    Button,
    ButtonProps,
    Menu,
    MenuItem,
    PopoverVirtualElement
} from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { MouseEvent, memo, useState } from 'react'

const menuStyle = {
    '.MuiMenu-list': { padding: 2, width: 194 }
}
export type ButtonMenuOption = {
    label: string
    values: string[]
}

export interface ButtonMenuProps {
    defaultTitle: string
    options: ButtonMenuOption[]
    onSelect: (options: ButtonMenuOption) => void
    variant?: ButtonProps['variant']
    color?: ButtonProps['color']
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({
    defaultTitle,
    options,
    onSelect,
    variant,
    color
}) => {
    const [selectedOption, setSelectedOption] = useState(defaultTitle)
    const [anchorEl, setAnchorEl] = useState<
        | Element
        | (() => Element)
        | PopoverVirtualElement
        | (() => PopoverVirtualElement)
        | undefined
    >(undefined)

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleOnSelect = (option: ButtonMenuOption) => {
        setSelectedOption(option.label)
        onSelect(option)
        setAnchorEl(undefined)
    }

    return (
        <>
            <Button
                variant={variant ?? 'contained'}
                color={color ?? 'secondary'}
                endIcon={<LeafIcon icon={'chevron-down'} fontSize={'small'} />}
                aria-controls="menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                sx={{ minHeight: 36, px: 1.5 }}
            >
                <CoreTypography variant="h6" component="p">
                    {selectedOption}
                </CoreTypography>
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(undefined)}
                sx={menuStyle}
            >
                <MenuItem
                    onClick={() =>
                        handleOnSelect({
                            label: defaultTitle,
                            values: []
                        })
                    }
                >
                    {defaultTitle}
                </MenuItem>
                {options.map((o, i) => (
                    <MenuItem key={i} onClick={() => handleOnSelect(o)}>
                        {o.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default memo(ButtonMenu)
