import { getCheckInInformation } from '@thriveglobal/thrive-web-core'
import addDays from 'date-fns/addDays'
import parseISO from 'date-fns/parseISO'
import { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'
import {
    ChallengeTemplate,
    UnifiedChallenge
} from '../../../../../graphql/generated/autogenerated'
import { useGetCategory } from '../../../../../hooks/useGetCategory/useGetCategory'
import { ActiveUnifiedChallengeParticipation } from '../../../../../hooks/useGetChallengesHomeData/useGetChallengesHomeData'
import { ChallengeType } from '../../../../../shared'
import { useCompanyChallengeStates } from '../../../../../shared/hooks/useCompanyChallengeStates/useCompanyChallengeStates'
import { ChallengeCardType } from '../../ChallengeGrid/ChallengeGrid'
import ChallengeActiveCard from '../ChallengeActiveCard/ChallengeActiveCard'
import ChallengeCard from '../ChallengeCard/ChallengeCard'
import ChallengeFeaturedCard from '../ChallengeFeaturedCard/ChallengeFeaturedCard'
import ChallengePastCard from '../ChallengePastCard/ChallengePastCard'
import { getChallengeType } from '../../../../../utils'
export interface ChallengeCardDynamicProps extends JSX.IntrinsicAttributes {
    challenge:
        | ActiveUnifiedChallengeParticipation
        | UnifiedChallenge
        | ChallengeTemplate
    size?: 'lg' | 'md' | 'sm' | 'xs'
    type?: ChallengeCardType
}

const ChallengeCardDynamic: React.FC<ChallengeCardDynamicProps> = ({
    challenge,
    size,
    type
}) => {
    const challengeData =
        type === ChallengeCardType.PAST
            ? ((challenge as ActiveUnifiedChallengeParticipation)
                  .challenge as UnifiedChallenge)
            : (challenge as UnifiedChallenge)

    const category = useGetCategory(challengeData)
    const calculateChallengeStates = useCompanyChallengeStates()
    const { formatDate } = useIntl()

    const component = useMemo(() => {
        switch (type) {
            case ChallengeCardType.ACTIVE: {
                const activeChallenge =
                    challenge as ActiveUnifiedChallengeParticipation
                const { challengeStarted, remainingDays } =
                    calculateChallengeStates(
                        activeChallenge?.challenge,
                        activeChallenge?.participation
                    )

                const challengeType = getChallengeType(
                    activeChallenge.challenge
                )

                return (
                    <ChallengeActiveCard
                        id={activeChallenge?.challenge?.id}
                        name={activeChallenge?.challenge?.name}
                        challengeType={challengeType}
                        challengeStarted={challengeStarted}
                        remainingDays={remainingDays < 0 ? 0 : remainingDays}
                        goalsCompleted={activeChallenge?.goalsCompleted}
                        goalsTotal={activeChallenge?.goalsTotal}
                        completed={
                            activeChallenge?.participation?.[0]?.completedOn ||
                            remainingDays <= 0
                        }
                        past={
                            !!activeChallenge?.participation?.[0]?.completedOn
                        }
                    />
                )
            }
            case ChallengeCardType.FEATURED: {
                const featuredChallenge = challenge as UnifiedChallenge
                return (
                    <ChallengeFeaturedCard
                        id={featuredChallenge?.id}
                        image={featuredChallenge?.header as string | undefined}
                        category={category}
                        name={featuredChallenge?.name}
                        description={String(featuredChallenge?.description)}
                        duration={featuredChallenge?.duration}
                        challengeType={
                            featuredChallenge?.challenge_type as ChallengeType
                        }
                        size={size}
                    />
                )
            }
            case ChallengeCardType.STANDARD: {
                const standardChallenge = challenge as UnifiedChallenge
                return (
                    <ChallengeCard
                        id={standardChallenge?.id}
                        image={standardChallenge?.header as string | undefined}
                        category={category}
                        name={standardChallenge?.name}
                        description={
                            standardChallenge?.description as string | undefined
                        }
                        duration={standardChallenge?.duration}
                        challengeType={
                            standardChallenge?.challenge_type as ChallengeType
                        }
                    />
                )
            }
            case ChallengeCardType.PAST: {
                const pastChallenge =
                    challenge as ActiveUnifiedChallengeParticipation
                const startDate = parseISO(
                    (pastChallenge?.challenge?.type === ChallengeType.company ||
                        pastChallenge?.challenge?.type ===
                            ChallengeType.group) &&
                        pastChallenge.challenge.companyChallengeStartDay
                        ? pastChallenge.challenge.companyChallengeStartDay
                        : pastChallenge.participation[0].join
                )
                const endDate = addDays(
                    startDate,
                    pastChallenge.challenge.duration
                )
                const formattedStartDate = formatDate(startDate, {
                    month: 'short',
                    day: '2-digit'
                })
                const formattedEndDate = formatDate(endDate, {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                })
                const dateRange = `${formattedStartDate} - ${formattedEndDate}`
                const { uniqueCheckInDates, totalCheckinsCount } =
                    getCheckInInformation(
                        pastChallenge.participation[0]?.microstepActivities ??
                            []
                    )
                return (
                    <ChallengePastCard
                        id={pastChallenge?.challenge?.id}
                        participationId={pastChallenge?.participation?.[0]?.id}
                        name={pastChallenge?.challenge?.name}
                        image={pastChallenge?.challenge?.header as string}
                        category={category}
                        challengeType={
                            pastChallenge?.challenge
                                ?.challenge_type as ChallengeType
                        }
                        dateRange={dateRange}
                        totalActiveDays={uniqueCheckInDates?.length}
                        totalCheckIns={totalCheckinsCount}
                    />
                )
            }
            default:
                break
        }
    }, [challenge, size, category, calculateChallengeStates, type, formatDate])

    return <>{component}</>
}

export default memo(ChallengeCardDynamic)
