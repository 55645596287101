import { Box, Button, Fade, Grid, Stack, useMediaQuery } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import cloneDeep from 'lodash/cloneDeep'
import { memo, useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
    ChallengeTemplate,
    UnifiedChallenge
} from '../../../../graphql/generated/autogenerated'
import { ActiveUnifiedChallengeParticipation } from '../../../../hooks/useGetChallengesHomeData/useGetChallengesHomeData'
import ConditionalSkeleton from '../../../elements/ConditionalSkeleton/ConditionalSkeleton'
import ChallengeActiveCardSkeleton from '../ChallengeCards/ChallengeActiveCard/ChallengeActiveCardSkeleton'
import ChallengeCardSkeleton from '../ChallengeCards/ChallengeCard/ChallengeCardSkeleton'
import ChallengeCardDynamic from '../ChallengeCards/ChallengeCardDynamic/ChallengeCardDynamic'
import ChallengeFeaturedCardSkeleton from '../ChallengeCards/ChallengeFeaturedCard/ChallengeFeaturedCardSkeleton'

export enum ChallengeCardType {
    ACTIVE,
    FEATURED,
    STANDARD,
    PAST
}

export interface ChallengeGridProps extends JSX.IntrinsicAttributes {
    loading: boolean
    challenges:
        | UnifiedChallenge[]
        | ActiveUnifiedChallengeParticipation[]
        | ChallengeTemplate[]
    title?: string
    filter?: JSX.Element
    skeletonCount?: number
    type?: ChallengeCardType
    size?: 'lg' | 'md' | 'sm' | 'xs'
    showEmptyState?: boolean
}

const sizeMap = {
    lg: { lg: 12, md: 12, sm: 12, xs: 12 },
    md: { lg: 8, md: 12, sm: 12, xs: 12 },
    sm: { lg: 6, md: 6, sm: 12, xs: 12 },
    xs: { lg: 4, md: 4, sm: 6, xs: 12 }
}

const ChallengeGrid: React.FC<ChallengeGridProps> = ({
    loading,
    challenges,
    title,
    filter,
    skeletonCount = 9,
    type = ChallengeCardType.STANDARD,
    size = 'xs',
    showEmptyState = false
}) => {
    const gridSizes = sizeMap[size]
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const rowSet = isMobile ? 6 : 9
    const [rowsShown, setRowsShown] = useState(rowSet)

    const viewMore = useCallback(() => {
        setRowsShown(rowsShown + rowSet)
    }, [rowsShown, rowSet])

    const Skeleton = useMemo(() => {
        switch (type) {
            case ChallengeCardType.ACTIVE:
                return <ChallengeActiveCardSkeleton />
            case ChallengeCardType.FEATURED:
                return <ChallengeFeaturedCardSkeleton size={size} />
            case ChallengeCardType.STANDARD:
                return <ChallengeCardSkeleton />
            case ChallengeCardType.PAST:
                return <ChallengeCardSkeleton />
            default:
                break
        }
    }, [type, size])

    if (!loading && challenges?.length === 0 && !showEmptyState) return <></>

    return (
        <Stack gap={2} data-testid="challenge-grid">
            {(challenges?.length > 0 || loading || showEmptyState) && (
                <>
                    {title && (
                        <ConditionalSkeleton showSkeleton={loading}>
                            <CoreTypography variant="h3" component="h2">
                                {title}
                            </CoreTypography>
                        </ConditionalSkeleton>
                    )}
                    {filter && <Stack py={1}>{filter}</Stack>}
                </>
            )}
            {!loading && challenges?.length === 0 && showEmptyState && (
                <Stack p={4}>
                    <CoreTypography variant="h3" color="text.disabled">
                        <FormattedMessage
                            defaultMessage="No Results"
                            description="description for no results"
                        />
                    </CoreTypography>
                </Stack>
            )}
            <Stack gap={3}>
                <Grid container>
                    {loading
                        ? Array.from(Array(skeletonCount)).map((_, index) => (
                              <Grid item key={index} {...gridSizes}>
                                  <Fade
                                      in={loading}
                                      timeout={1000}
                                      mountOnEnter
                                      unmountOnExit
                                  >
                                      <Box>{Skeleton}</Box>
                                  </Fade>
                              </Grid>
                          ))
                        : cloneDeep(challenges)
                              ?.splice(0, rowsShown)
                              ?.map(
                                  (
                                      challenge:
                                          | UnifiedChallenge
                                          | ActiveUnifiedChallengeParticipation
                                          | ChallengeTemplate,
                                      index: number
                                  ) => {
                                      return (
                                          <Grid item key={index} {...gridSizes}>
                                              <ChallengeCardDynamic
                                                  challenge={challenge}
                                                  size={size}
                                                  type={type}
                                              />
                                          </Grid>
                                      )
                                  }
                              )}
                </Grid>
                {challenges?.length > rowsShown && (
                    <Stack alignItems="center" justifyContent="center">
                        <Box>
                            <Button onClick={viewMore}>
                                <CoreTypography customVariant="buttonNormal">
                                    <FormattedMessage
                                        defaultMessage="View more ({amountRemaining})"
                                        description="text for view more button"
                                        values={{
                                            amountRemaining:
                                                challenges?.length - rowsShown
                                        }}
                                    />
                                </CoreTypography>
                            </Button>
                        </Box>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}

export default memo(ChallengeGrid)
