import { List, ListItem, Stack } from '@mui/material'
import React, { memo } from 'react'

export type BulletListProps = {
    list: React.ReactNode[]
    gap?: number
}

const bulletListStyle = {
    listStyleType: 'disc',
    pl: 4,
    py: 0
}

const bulletListItemStyle = {
    p: 0,
    display: 'list-item',
    whiteSpace: 'nowrap',
    '>*': {
        display: 'contents',
        whiteSpace: 'pre-wrap'
    }
}

const BulletList: React.FC<BulletListProps> = ({ list, gap = 1 }) => {
    return (
        <List sx={bulletListStyle}>
            <Stack gap={gap}>
                {list.map((c, i) => (
                    <ListItem key={i} sx={bulletListItemStyle}>
                        {c}
                    </ListItem>
                ))}
            </Stack>
        </List>
    )
}

export default memo(BulletList)
