import { Stack, TextField } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import BulletList from '../../../../../../components/elements/BulletList/BulletList'
import {
    InvalidInput,
    useUpdateDisplayNameMutation
} from '../../../../../../graphql/generated/autogenerated'

export type CreateDisplayNameProps = {
    onCreateDisplayName: (displayName: string) => void
    onFormValid: (valid: boolean) => void
    onLoading: (loading: boolean) => void
    submit: boolean
}

const messages = defineMessages({
    yourDisplayName: {
        defaultMessage: 'Your display name',
        description: 'placeholder text for the display name text field'
    },
    enterDisplayName: {
        defaultMessage: 'Enter a display name',
        description: 'error text for the display name text field'
    },
    displayNameTooLong: {
        defaultMessage: 'Display name must be 28 characters or less',
        description: 'error text for the display name text field'
    }
})

const CreateDisplayName: React.FC<CreateDisplayNameProps> = ({
    onCreateDisplayName,
    onFormValid,
    onLoading,
    submit
}) => {
    const { formatMessage, formatNumber } = useIntl()
    const [hasSubmitted, setHasSubmitted] = useState<boolean>(false)
    const { fullName } = useAppSelector((state) => state.user)
    const [displayName, setDisplayName] = React.useState<string>(
        String(fullName)
    )
    const [invalidText, setInvalidText] = React.useState('')

    React.useEffect(() => {
        let formValid = false
        if (displayName === '') {
            formValid = false
            setInvalidText(formatMessage(messages.enterDisplayName))
        } else if (displayName?.length > 28) {
            formValid = false
            setInvalidText(formatMessage(messages.displayNameTooLong))
        } else {
            formValid = true
            setInvalidText('')
        }
        onFormValid(formValid)
    }, [displayName, formatMessage, onFormValid])

    const [updateDisplayName] = useUpdateDisplayNameMutation({
        variables: {
            displayName: displayName
        }
    })

    const onSubmit = useCallback(async () => {
        onLoading(true)
        await updateDisplayName()
            .then((response) => {
                const updateDisplayNameResponse =
                    response?.data?.socialGroups?.updateDisplayName

                if (
                    (updateDisplayNameResponse as InvalidInput)?.invalidFields
                        ?.length > 0
                ) {
                    onLoading(false)
                    setInvalidText(
                        (updateDisplayNameResponse as InvalidInput)
                            .invalidFields[0].message
                    )
                } else {
                    onCreateDisplayName(displayName)
                }
            })
            .catch(() => {
                setHasSubmitted(false)
                onLoading(false)
            })
    }, [updateDisplayName, onCreateDisplayName, onLoading, displayName])

    React.useEffect(() => {
        if (!hasSubmitted && submit) {
            onSubmit()
            setHasSubmitted(true)
        }
    }, [hasSubmitted, submit, onSubmit])

    return (
        <Stack data-testid="create-display-name" width={672} maxWidth="100%">
            <Stack direction="column" mt={1}>
                <Stack gap={2}>
                    <Stack gap={1}>
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="Create a display name"
                                description="title for creating a display name"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Choose the name that will be displayed when sharing your progress updates. We recommend using a name your coworkers know."
                                description="description for creating a display name"
                            />
                        </CoreTypography>
                    </Stack>
                    <Stack width={343} maxWidth="100%" gap={1}>
                        <TextField
                            id="outlined-basic"
                            label={formatMessage(messages.yourDisplayName)}
                            value={displayName}
                            error={!!invalidText}
                            variant="outlined"
                            size="medium"
                            fullWidth={false}
                            onChange={(event) =>
                                setDisplayName(event.target.value)
                            }
                        />
                        {invalidText && (
                            <CoreTypography
                                variant="caption"
                                color="error.main"
                            >
                                {invalidText}
                            </CoreTypography>
                        )}
                        <CoreTypography variant="caption">
                            <FormattedMessage
                                defaultMessage="{ count } characters max."
                                description="description for maximum characters"
                                values={{ count: formatNumber(28) }}
                            />
                        </CoreTypography>
                    </Stack>
                    <Stack gap={3}>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="When you set your name, you'll start sharing updates with your team."
                                description="description for maximum characters"
                            />
                        </CoreTypography>
                        <Stack gap={1}>
                            <CoreTypography variant="body1">
                                <FormattedMessage
                                    defaultMessage="Your status will update when you:"
                                    description="description for maximum characters"
                                />
                            </CoreTypography>
                            <BulletList
                                list={[
                                    <CoreTypography variant="body1">
                                        <FormattedMessage
                                            defaultMessage="Start or complete a challenge"
                                            description="list item start or complete a challenge"
                                        />
                                    </CoreTypography>,
                                    <CoreTypography variant="body1">
                                        <FormattedMessage
                                            defaultMessage="Take a Microstep"
                                            description="list item take a microstep"
                                        />
                                    </CoreTypography>,
                                    <CoreTypography variant="body1">
                                        <FormattedMessage
                                            defaultMessage="Watch or create a Reset"
                                            description="list item watch or create a reset"
                                        />
                                    </CoreTypography>,
                                    <CoreTypography variant="body1">
                                        <FormattedMessage
                                            defaultMessage="Join a group"
                                            description="list item join a group"
                                        />
                                    </CoreTypography>,
                                    <CoreTypography variant="body1">
                                        <FormattedMessage
                                            defaultMessage="And more"
                                            description="list item and more"
                                        />
                                    </CoreTypography>
                                ]}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default CreateDisplayName
