import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import orderBy from 'lodash/orderBy'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    GetChallengesHomeQuery,
    UnifiedChallenge,
    UnifiedChallengeParticipation,
    useGetChallengesHomeQuery
} from '../../graphql/generated/autogenerated'
import { ChallengeType } from '../../shared/enums/challengeType'
import { useCompanyChallengeStates } from '../../shared/hooks'
import useGetDailyGoalCompletionData from '../useGetDailyGoalCompletion/useGetDailyGoalCompletion'

export interface ActiveUnifiedChallengeParticipation
    extends UnifiedChallengeParticipation {
    goalsCompleted: number
    goalsTotal: number
}

export const ChallengeFilterTopic = {
    BIOTYPE: 'biotype'
} as const

export type ChallengeFilterTopic =
    (typeof ChallengeFilterTopic)[keyof typeof ChallengeFilterTopic]

export interface ChallengesHomeData {
    activeChallenges: ActiveUnifiedChallengeParticipation[]
    featuredChallenges: UnifiedChallenge[]
    filterableChallenges: UnifiedChallenge[]
    error: ApolloError | undefined
    loading: boolean
    refetch: () => Promise<Apollo.ApolloQueryResult<GetChallengesHomeQuery>>
    filterBySelection: (
        challenges: UnifiedChallenge[],
        topic: string | undefined
    ) => UnifiedChallenge[]
}

export const useGetChallengesHomeData = () => {
    const calculateChallengeStates = useCompanyChallengeStates()
    const { getDailyGoalCompletion } = useGetDailyGoalCompletionData()
    const [dailyGoalCompletionFetched, setDailyGoalCompletionFetched] =
        useState<boolean>(false)
    const [activeChallenges, setActiveChallenges] = useState<
        ActiveUnifiedChallengeParticipation[]
    >([])

    const {
        data: challengesHomeData,
        loading: challengesHomeLoading,
        error: challengesHomeError,
        refetch: challengesHomeRefetch
    } = useGetChallengesHomeQuery({
        initialFetchPolicy: 'no-cache'
    })

    // Filter challenges based on the selected challenge types and topic
    // Group challenges should return with personal/biotype/prize challenges
    const filterBySelection = useCallback(
        (
            challenges: UnifiedChallenge[],
            topic: ChallengeFilterTopic | string | undefined
        ) => {
            return challenges.filter(
                (challenge) =>
                    topic === undefined ||
                    challenge?.journeys?.some(
                        (journey) => journey.shortName === topic
                    ) ||
                    (topic === ChallengeFilterTopic.BIOTYPE &&
                        !!challenge?.biotype)
            )
        },
        []
    )

    // Fetch daily goal completion data for company challenges
    useEffect(() => {
        if (dailyGoalCompletionFetched || challengesHomeLoading) return

        const activeChallengesData =
            (challengesHomeData?.unifiedChallenges
                ?.unifiedUserChallenges as UnifiedChallengeParticipation[]) ||
            []

        if (!activeChallengesData.length) {
            setDailyGoalCompletionFetched(true)
            return
        }

        getDailyGoalCompletion(activeChallengesData)
            .then((updatedChallenges: ActiveUnifiedChallengeParticipation[]) =>
                setActiveChallenges(updatedChallenges)
            )
            .finally(() => setDailyGoalCompletionFetched(true))
    }, [
        challengesHomeData,
        challengesHomeLoading,
        dailyGoalCompletionFetched,
        getDailyGoalCompletion
    ])

    const loading = useMemo(
        () => challengesHomeLoading || !dailyGoalCompletionFetched,
        [challengesHomeLoading, dailyGoalCompletionFetched]
    )

    const hasNoSignUpDateOrHasSignUpStarted = useCallback(
        (challenge: UnifiedChallenge) =>
            challenge.challenge_type !== ChallengeType.company ||
            calculateChallengeStates(challenge).challengeSignUpStarted,
        [calculateChallengeStates]
    )

    // Return data for the challenges home page
    return useMemo<ChallengesHomeData>(() => {
        const unifiedChallenges = challengesHomeData?.unifiedChallenges

        // Active Challenges
        const sortedActiveChallenges = orderBy(
            activeChallenges,
            [
                // Primary sorting: In Progress (0) vs Completed (1) vs Upcoming (2)
                (challenge: UnifiedChallengeParticipation) => {
                    const { day } = calculateChallengeStates(
                        challenge?.challenge,
                        challenge?.participation
                    )

                    if (day > 0 && day <= challenge.challenge.duration) return 0 // In Progress
                    if (
                        day === challenge.challenge.duration ||
                        (challenge.participation?.[0].completedOn && day >= 1)
                    )
                        return 1 // Completed
                    return 2 // Upcoming
                },
                // Secondary sorting: Company (0) vs Others (1)
                (challenge: UnifiedChallengeParticipation) =>
                    challenge.challenge.challenge_type === ChallengeType.company
                        ? 0
                        : 1,
                // Tertiary sorting: Days remaining
                (challenge: UnifiedChallengeParticipation) =>
                    challenge.challenge.duration -
                    calculateChallengeStates(
                        challenge?.challenge,
                        challenge?.participation
                    ).day,
                // Quaternary sorting: Name
                (challenge: UnifiedChallengeParticipation) =>
                    challenge.challenge.name
            ],
            ['asc', 'asc', 'asc', 'asc']
        ) as ActiveUnifiedChallengeParticipation[]

        // Template Challenges
        const templateChallenges =
            unifiedChallenges?.groupChallengeTemplates || []

        // Featured Challenges
        const featuredChallenges = unifiedChallenges?.unifiedChallenges?.filter(
            (challenge) =>
                challenge.isFeatured &&
                !activeChallenges.some(
                    (activeChallenge) =>
                        activeChallenge?.challenge?.id === challenge.id
                ) &&
                hasNoSignUpDateOrHasSignUpStarted(challenge as UnifiedChallenge)
        ) as UnifiedChallenge[]
        const sortedFeaturedChallenges = orderBy(
            featuredChallenges,
            ['name'],
            ['asc']
        )

        // Filterable Challenges
        const filterableChallenges = (
            unifiedChallenges?.unifiedChallenges || []
        )
            .concat(
                templateChallenges.map((templateChallenge) => ({
                    ...templateChallenge,
                    challenge_type: ChallengeType.group
                })) as unknown as UnifiedChallenge[]
            )
            .filter((c) => {
                return (
                    hasNoSignUpDateOrHasSignUpStarted(c as UnifiedChallenge) &&
                    !activeChallenges.some(
                        (_c) => _c?.challenge?.id === c.id
                    ) &&
                    !featuredChallenges.some((_c) => _c?.id === c.id)
                )
            }) as UnifiedChallenge[]
        const sortedFilterableChallenges = orderBy(
            filterableChallenges,
            ['challenge_type', 'category.name', 'name'],
            ['asc', 'asc', 'asc']
        )

        return {
            activeChallenges: sortedActiveChallenges,
            featuredChallenges: sortedFeaturedChallenges,
            filterableChallenges: sortedFilterableChallenges,
            filterBySelection,
            error: challengesHomeError,
            loading: loading,
            refetch: challengesHomeRefetch
        }
    }, [
        loading,
        activeChallenges,
        filterBySelection,
        challengesHomeData,
        challengesHomeError,
        challengesHomeRefetch,
        calculateChallengeStates,
        hasNoSignUpDateOrHasSignUpStarted
    ])
}

export default useGetChallengesHomeData
