import { createContext } from 'react'
import {
    SocialGroup,
    TemplateQuote,
    UnifiedChallenge,
    UnifiedChallengeActivity
} from '../../graphql/generated/autogenerated'
import { StoryData } from '../storySubmission/useParseStorySubmissionData'
import { GQLNull } from '../../shared/utils/Nulls'
import { ChallengeType } from '../../shared'

// participation and challenge will filter to whats provided in the url providers
// activeCompanyChallenge will return any active company challenge the user might have
export interface CompanyChallengeProviderValue {
    loading: boolean
    mainCommunitySocialGroup?: SocialGroup | undefined
    socialGroup?: SocialGroup | undefined
    displayName?: string | GQLNull
    companyName: string | undefined
    source: string | undefined
    challenge?: UnifiedChallenge
    challengeType?: ChallengeType
    participation?: UnifiedChallengeActivity | undefined
    elapsedTime?: number
    endDate?: Date | undefined
    startDate?: Date | undefined
    day?: number
    remainingDays?: number
    challengeStarted?: boolean
    challengeExpired?: boolean
    challengeCompleted?: boolean
    deviceEnabled: boolean
    hydrationEnabled: boolean
    sleepEnabled: boolean
    tutorialWatched?: boolean
    canFirePostJoinQueries?: boolean
    isTemplate?: boolean
    storyData?: StoryData
    soloChallenge?: boolean
    quotes?: TemplateQuote[]
    showAvgLeaderboard?: boolean
    refetch: () => Promise<any>
    setSource: (source: string) => void
    setTutorialWatched?: () => void
    error: boolean
}

export const defaultContextValue: CompanyChallengeProviderValue = {
    loading: false,
    mainCommunitySocialGroup: undefined,
    socialGroup: undefined,
    displayName: undefined,
    companyName: undefined,
    source: undefined,
    challenge: undefined,
    challengeType: undefined,
    participation: undefined,
    elapsedTime: 0,
    endDate: undefined,
    startDate: undefined,
    day: 0,
    remainingDays: 0,
    challengeStarted: false,
    challengeExpired: false,
    challengeCompleted: false,
    tutorialWatched: false,
    deviceEnabled: false,
    hydrationEnabled: false,
    sleepEnabled: false,
    canFirePostJoinQueries: true,
    isTemplate: false,
    soloChallenge: false,
    showAvgLeaderboard: false,
    refetch: () => Promise.resolve(),
    setSource: () => ({}),
    setTutorialWatched: () => ({}),
    error: false
}

export default createContext(defaultContextValue)
