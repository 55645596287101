import { Button, Divider, Stack, useMediaQuery } from '@mui/material'
import { useIsFeatureEnabled } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    ErrorScreen,
    ErrorScreenVariant,
    LeafIcon,
    PageLayout,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import ChallengeRecommendation from '../../components/elements/ChallengeRecommendation/ChallengeRecommendation'
import ChallengeTypeIcon from '../../components/elements/ChallengeTypeIcon/ChallengeTypeIcon'
import { withErrorBoundary } from '../../components/elements/ErrorBoundaryWrapper'
import Page from '../../components/elements/Page/Page'
import ViewChallengeRecap from '../../components/features/Challenge/ViewChallengeRecap/ViewChallengeRecap'
import ChallengeAlert from '../../components/features/HomeChallenges/ChallengeAlert/ChallengeAlert'
import ChallengeBanner from '../../components/features/HomeChallenges/ChallengeBanner/ChallengeBanner'
import ChallengeFilter, {
    ChallengeFilterSelection
} from '../../components/features/HomeChallenges/ChallengeFilter/ChallengeFilter'
import ChallengeGrid, {
    ChallengeCardType
} from '../../components/features/HomeChallenges/ChallengeGrid/ChallengeGrid'
import { UnifiedChallenge } from '../../graphql/generated/autogenerated'
import { useGetChallengesHomeData } from '../../hooks/useGetChallengesHomeData/useGetChallengesHomeData'
import { ROUTES } from '../../routes'
import { ChallengeType } from '../../shared/enums/challengeType'

const messages = defineMessages({
    challengesInProgress: {
        defaultMessage: 'Challenges in progress',
        description: 'description for the challenges in progress section'
    },
    featured: {
        defaultMessage: 'Featured',
        description: 'description for the featured challenges section'
    },
    findChallenge: {
        defaultMessage: 'Find a Challenge',
        description: 'description for the find a challenge section'
    },
    introduceGroupChallenges: {
        defaultMessage:
            'You can take on any of our Challenges on your own or with your coworkers. Select one below and invite your coworkers to join in on the fun and earn a plant together.',
        description: 'description for the introduce group challenges section'
    }
})

const Home: React.FC = () => {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const {
        error,
        loading,
        activeChallenges,
        featuredChallenges,
        filterableChallenges,
        filterBySelection
    } = useGetChallengesHomeData()

    const anchorRef = useRef(undefined)
    const scrollToAnchor = useCallback(() => {
        ;(anchorRef.current as any).scrollIntoView({ behavior: 'smooth' })
    }, [anchorRef])

    const [filteredChallenges, setFilteredChallenges] =
        useState<UnifiedChallenge[]>()
    const [activeRecap, setActiveRecap] = useState<UnifiedChallenge>()

    useEffect(() => {
        if (!loading && !filteredChallenges) {
            setFilteredChallenges(filterableChallenges)
        }
    }, [loading, filteredChallenges, filterableChallenges])

    const onFilter = useCallback(
        (topic: string | undefined) =>
            setFilteredChallenges(
                filterBySelection(filterableChallenges, topic)
            ),
        [filterableChallenges, filterBySelection, setFilteredChallenges]
    )

    const viewPastChallengesHandler = useCallback(() => {
        history.push(ROUTES.PAST_CHALLENGES)
    }, [history])

    const showChallengeBanner = useMemo(
        () => !loading && activeChallenges?.length === 0,
        [loading, activeChallenges?.length]
    )

    const activeChallengesExcludingFeaturedRecap = useMemo(
        () =>
            activeChallenges?.filter(
                (challenge) => challenge?.challenge?.id !== activeRecap?.id
            ),
        [activeChallenges, activeRecap]
    )

    const techOffsite2024 = useIsFeatureEnabled('techOffsite2024', false, true)

    if (error) return <ErrorScreen variant={ErrorScreenVariant.General} />

    return (
        <PageLayout maxWidth="lg">
            <Page>
                <Stack gap={isMobile ? 3 : 4}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={2}
                    >
                        <CoreTypography variant="h1">
                            <FormattedMessage
                                defaultMessage="Challenges"
                                description="description for the challenges page title"
                            />
                        </CoreTypography>
                        <Button
                            variant="text"
                            endIcon={
                                <LeafIcon
                                    icon={'arrow-right'}
                                    fontSize={'small'}
                                />
                            }
                            onClick={viewPastChallengesHandler}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    defaultMessage="View past Challenges"
                                    description="description for the view past challenges button"
                                />
                            </CoreTypography>
                        </Button>
                    </Stack>
                    <Stack gap={isMobile ? 3.625 : 5.625}>
                        {showChallengeBanner && (
                            <ChallengeBanner onCtaClick={scrollToAnchor} />
                        )}
                        <ViewChallengeRecap onActiveRecap={setActiveRecap} />
                        {/* TODO: Remove this when we choose to release to all users */}
                        {techOffsite2024 && (
                            <ChallengeRecommendation parentLoading={loading} />
                        )}
                        <ChallengeGrid
                            title={formatMessage(messages.challengesInProgress)}
                            skeletonCount={3}
                            loading={loading}
                            challenges={activeChallengesExcludingFeaturedRecap}
                            type={ChallengeCardType.ACTIVE}
                        />
                        <ChallengeGrid
                            title={formatMessage(messages.featured)}
                            skeletonCount={1}
                            loading={loading}
                            challenges={featuredChallenges}
                            type={ChallengeCardType.FEATURED}
                            size="md"
                        />
                        {(filterableChallenges?.length > 0 || loading) && (
                            <Stack gap={5.625}>
                                <Divider />
                                <Stack ref={anchorRef as any} gap={3}>
                                    {(filterableChallenges?.length > 3 ||
                                        loading) && (
                                        <ChallengeAlert
                                            loading={loading}
                                            icon={
                                                <ChallengeTypeIcon
                                                    color={'secondary'}
                                                    challengeType={
                                                        ChallengeType.group
                                                    }
                                                />
                                            }
                                            message={formatMessage(
                                                messages.introduceGroupChallenges
                                            )}
                                        />
                                    )}
                                    <ChallengeGrid
                                        title={formatMessage(
                                            messages.findChallenge
                                        )}
                                        skeletonCount={3}
                                        {...((filterableChallenges?.length >
                                            3 ||
                                            loading) && {
                                            filter: (
                                                <ChallengeFilter
                                                    loading={loading}
                                                    onChange={(
                                                        selection: ChallengeFilterSelection
                                                    ) =>
                                                        onFilter(
                                                            selection.topic
                                                        )
                                                    }
                                                />
                                            )
                                        })}
                                        loading={loading}
                                        challenges={filteredChallenges ?? []}
                                        type={ChallengeCardType.STANDARD}
                                        showEmptyState={true}
                                    />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Page>
        </PageLayout>
    )
}

export default withErrorBoundary('Home', 'Page', memo(Home))
