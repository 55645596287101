import { useCallback, useMemo, useState } from 'react'

export interface PageProps {
    anchorOnIndex?: any
    rowsPerPage?: number
    accumulate?: boolean
    onNavigate: (next: boolean) => Promise<any>
    values: unknown[]
}

export const usePage = ({
    anchorOnIndex,
    rowsPerPage = 10,
    accumulate,
    onNavigate,
    values
}: PageProps) => {
    const [page, setPage] = useState(
        anchorOnIndex >= 0 ? Math.floor(anchorOnIndex / rowsPerPage) : 0
    )

    const { pageValues, hasMore } = useMemo(() => {
        const pageValues = values.slice(
            accumulate ? 0 : page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        )

        return { pageValues, hasMore: pageValues?.length !== values?.length }
    }, [values, page, rowsPerPage, accumulate])

    const onNextPage = useCallback(
        (next: boolean) => {
            setPage((page) => (page > 0 || next ? page + (next ? 1 : -1) : 0))

            if (!hasMore) {
                return onNavigate(next)
            }

            return Promise.resolve(true)
        },
        [onNavigate, hasMore]
    )

    return {
        page,
        pageValues,
        onNextPage
    }
}

export default usePage
