import { useCallback, useEffect, useState } from 'react'

export const useCheckLineCount = (
    textRef: React.RefObject<HTMLDivElement>,
    singleLineTextHeight: number
) => {
    const [nameMultiLine, setNameMultiLine] = useState<boolean>(false)

    const checkLineCount = useCallback(() => {
        const current = textRef.current
        const height = current
            ? parseInt(window.getComputedStyle(current).height, 10)
            : 0
        setNameMultiLine(height > singleLineTextHeight)
    }, [singleLineTextHeight, textRef])

    useEffect(() => {
        checkLineCount()
        window.addEventListener('resize', checkLineCount)
        return () => {
            window.removeEventListener('resize', checkLineCount)
        }
    }, [checkLineCount])

    return { checkLineCount, nameMultiLine }
}

export default useCheckLineCount
