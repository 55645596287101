import { Box, Stack } from '@mui/material'
import { diffDays, format, parseDate } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useMemo } from 'react'
import { MessageDescriptor, defineMessages, useIntl } from 'react-intl'
import { UnifiedChallengeParticipation } from '../../../../../graphql/generated/autogenerated'

export interface ProgressDetailsProps {
    challengeParticipation: UnifiedChallengeParticipation
    totalCheckinsCount: number
    activeDays: number
    completedChallenge: boolean
    checkedInToday: boolean
    currentStreak: number
    hideSubStats?: boolean
    hideMainStats?: boolean
}

const messages = defineMessages({
    dayOf: {
        defaultMessage: `Day {currentDay} of {totalDuration}`,
        description:
            'shows what the current day within the duration of the challenge is e.g. day 5 of 7'
    },
    daysLeft: {
        defaultMessage: `{daysLeft, plural, one {# day} other {# days}} left`,
        description: 'shows total number of days left inside a challenge'
    },
    lastDay: {
        defaultMessage: `Last day`,
        description: 'test to show on the last day of the challenge'
    },
    totalCheckIns: {
        defaultMessage:
            '<stat>{totalCheckinsCount}</stat> <title>total check-ins</title>',
        description: 'description for the total count of check-ins'
    },
    totalCheckInsAria: {
        defaultMessage: '{totalCheckinsCount} total check-ins',
        description: 'description for the total count of check-ins'
    },
    activeDays: {
        defaultMessage: '<stat>{activeDays}</stat> <title>active days</title>',
        description:
            'description for the days the user has been active on the challenge'
    },
    activeDaysAria: {
        defaultMessage: '{activeDays} active days',
        description:
            'description for the days the user has been active on the challenge'
    },
    dayStreak: {
        defaultMessage: '<stat>{streak}</stat> <title>day streak</title>',
        description:
            'description for the current streak of days the user has been active'
    },
    dayStreakAria: {
        defaultMessage: '{streak} day streak',
        description:
            'description for the current streak of days the user has been active'
    },
    dayHighestStreak: {
        defaultMessage:
            '<stat>{streak}</stat> <title>day highest streak</title>',
        description:
            'description for the highest streak of active days the user has on a challenge'
    },
    dayHighestStreakAria: {
        defaultMessage: '{streak} day highest streak',
        description:
            'description for the highest streak of active days the user has on a challenge'
    }
})

const ProgressDetails: React.FC<ProgressDetailsProps> = ({
    challengeParticipation,
    totalCheckinsCount,
    activeDays,
    completedChallenge,
    checkedInToday,
    currentStreak,
    hideSubStats,
    hideMainStats
}) => {
    const { palette, spacing, breakpoints } = useTheme()
    const { formatMessage, formatNumber } = useIntl()
    const daysSinceStart = useMemo(() => {
        const joinDate = parseDate(
            challengeParticipation?.participation[0]?.join.split('T')[0]
        )
        return diffDays(parseDate(format(new Date())), joinDate)
    }, [challengeParticipation])
    const daysLeft = useMemo(
        () =>
            challengeParticipation?.challenge?.duration -
            (daysSinceStart + (checkedInToday ? 1 : 0)),
        [challengeParticipation, daysSinceStart, checkedInToday]
    )
    const dayOf = useMemo(() => {
        const dayOfCalc = daysSinceStart + 1
        return dayOfCalc > challengeParticipation?.challenge?.duration
            ? challengeParticipation?.challenge?.duration
            : dayOfCalc
    }, [challengeParticipation, daysSinceStart])

    const createStat = useCallback(
        (
            descriptor: MessageDescriptor,
            aria: MessageDescriptor,
            statValue: any,
            showFlame?: boolean,
            disabled?: boolean
        ) => (
            <Stack
                direction={'column'}
                spacing={2}
                data-testid="active-days"
                aria-label={formatMessage(aria, {
                    ...statValue
                })}
            >
                {formatMessage(descriptor, {
                    ...statValue,
                    stat: (chunks: React.ReactNode[]) => (
                        <Stack
                            aria-hidden="true"
                            direction="row"
                            sx={{
                                color: disabled
                                    ? palette.text.disabled
                                    : palette.primary.main
                            }}
                        >
                            <CoreTypography customVariant="stat3">
                                {chunks}
                            </CoreTypography>
                            {showFlame && (
                                <LeafIcon
                                    icon={'fire-flame-curved'}
                                    sx={{
                                        my: 'auto',
                                        ml: 0.5,
                                        color: disabled
                                            ? palette.text.disabled
                                            : palette.error.main
                                    }}
                                />
                            )}
                        </Stack>
                    ),
                    title: (chunks: React.ReactNode[]) => (
                        <CoreTypography
                            variant="overline"
                            aria-hidden="true"
                            uppercase
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                maxWidth: spacing(11),
                                [breakpoints.down('sm')]: {
                                    maxWidth: spacing(10)
                                },
                                color: disabled
                                    ? palette.text.disabled
                                    : palette.text.primary
                            }}
                        >
                            {chunks}
                        </CoreTypography>
                    )
                })}
            </Stack>
        ),
        [breakpoints, palette, spacing, formatMessage]
    )

    const stats = useMemo(() => {
        const streak = challengeParticipation?.participation[0]?.streak

        return [
            createStat(messages.totalCheckIns, messages.totalCheckInsAria, {
                totalCheckinsCount: totalCheckinsCount ?? 0
            }),
            createStat(messages.activeDays, messages.activeDaysAria, {
                activeDays: activeDays ?? 0
            }),
            createStat(
                completedChallenge
                    ? messages.dayHighestStreak
                    : messages.dayStreak,
                completedChallenge
                    ? messages.dayHighestStreakAria
                    : messages.dayStreakAria,
                {
                    streak:
                        (completedChallenge
                            ? streak?.maxStreak
                            : currentStreak) ?? 0
                },
                true,
                !challengeParticipation?.participation[0]?.streak ||
                    currentStreak === 0
            )
        ]
    }, [
        createStat,
        totalCheckinsCount,
        activeDays,
        completedChallenge,
        challengeParticipation,
        currentStreak
    ])

    return (
        <Stack data-testid="progress-bar-details">
            {!completedChallenge && !hideSubStats && (
                <Stack
                    spacing={0.5}
                    justifyContent="space-between"
                    sx={{
                        mt: 2,
                        pb: 2,
                        ...(!hideMainStats && {
                            borderBottom: `${spacing(0.125)} solid ${
                                palette.grey[300]
                            }`
                        })
                    }}
                    direction="row"
                    data-testid="progress-details"
                >
                    <CoreTypography variant="body1">
                        {formatMessage(messages.dayOf, {
                            currentDay: dayOf,
                            totalDuration:
                                challengeParticipation?.challenge?.duration
                        })}
                    </CoreTypography>
                    <CoreTypography>
                        {daysLeft <= 1
                            ? formatMessage(messages.lastDay)
                            : formatMessage(messages.daysLeft, {
                                  daysLeft
                              })}
                    </CoreTypography>
                </Stack>
            )}
            {!hideMainStats && (
                <Stack
                    direction="row"
                    sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        color: palette.primary.main,
                        mb: 2,
                        mt: 2
                    }}
                >
                    {stats?.map((stat, index: number) => (
                        <Box key={index}>{stat}</Box>
                    ))}
                </Stack>
            )}
        </Stack>
    )
}

export default ProgressDetails
