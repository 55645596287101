import { ApolloError } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import parseISO from 'date-fns/parseISO'
import orderBy from 'lodash/orderBy'
import { useCallback, useMemo } from 'react'
import {
    UserChallengesQuery,
    useUserChallengesQuery
} from '../../graphql/generated/autogenerated'
import {
    ActiveUnifiedChallengeParticipation,
    ChallengeFilterTopic
} from '../useGetChallengesHomeData/useGetChallengesHomeData'

const getPastChallenges = (
    data?: UserChallengesQuery
): ActiveUnifiedChallengeParticipation[] => {
    return orderBy(
        data?.unifiedChallenges?.unifiedUserChallenges.flatMap((challenge) =>
            challenge.participation.map((participation) => ({
                ...challenge,
                participation: [participation]
            }))
        ),
        (item) => parseISO(item.participation[0].completedOn),
        'desc'
    ) as ActiveUnifiedChallengeParticipation[]
}

export interface PastChallengesData {
    pastChallenges: ReturnType<typeof getPastChallenges>
    loading: boolean
    error: ApolloError | undefined
    refetch: () => Promise<Apollo.ApolloQueryResult<UserChallengesQuery>>
    filterBySelection: (
        challenges: ActiveUnifiedChallengeParticipation[],
        topic: string | undefined
    ) => ActiveUnifiedChallengeParticipation[]
}

export const useGetPastChallenges = () => {
    const { data, loading, error, refetch } = useUserChallengesQuery({
        variables: { inProgress: false }
    })
    // Filter challenges based on the selected challenge types and topic
    // Group challenges should return with personal/biotype/prize challenges
    const filterBySelection = useCallback(
        (
            challenges: ActiveUnifiedChallengeParticipation[],
            topic: ChallengeFilterTopic | string | undefined
        ) => {
            return challenges.filter(
                (challenge) =>
                    topic === undefined ||
                    challenge?.challenge.journeys?.some(
                        (journey) => journey.shortName === topic
                    ) ||
                    (topic === ChallengeFilterTopic.BIOTYPE &&
                        !!challenge?.challenge.biotype)
            )
        },
        []
    )

    const pastChallenges = useMemo(() => getPastChallenges(data), [data])

    return useMemo<PastChallengesData>(() => {
        return {
            pastChallenges,
            filterBySelection,
            loading: loading,
            error,
            refetch
        }
    }, [loading, error, refetch, pastChallenges, filterBySelection])
}

export default useGetPastChallenges
